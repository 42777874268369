import { useState, useEffect, useCallback, useMemo } from 'react'
import mapboxgl from 'mapbox-gl'
import { Map, useRouter } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import {
  groupLandAnalysisHistorySelector,
  searchHistorySelector,
} from '../../redux'
import useMapEvents from '../../../../hooks/useMapEvents'
import { addClusters } from './clusters.util'

import '../../../Estimate/Estimate.style'

interface Feature {
  type: 'Feature'
  geometry: {
    type: 'Point'
    coordinates: any
  }
  properties: {
    title?: string
    price?: string
  }
}

interface LandMapControllerProps {
  map: Map
  loaded?: boolean
}

interface Land {
  archived: boolean
}

const SOURCE = 'land-history'

const LandHistoryMapController: React.FC<LandMapControllerProps> = ({
  map,
  loaded,
}) => {
  const landHistory = useSelector(searchHistorySelector)
  const groupLandHistory = useSelector(groupLandAnalysisHistorySelector)
  const { query } = useRouter()
  const [popups, setPopups] = useState<mapboxgl.Popup[]>([])
  const isGrouping = query?.tab === 'grouping'
  const isHomePage = query?.tab === 'home' || isGrouping
  const isArchived = query?.archived === 'true'
  const isActived = query?.active === 'true'

  const selectedHistory = useMemo(
    () => (isGrouping ? groupLandHistory || [] : landHistory || []),
    [isGrouping, landHistory, groupLandHistory],
  )

  const history = useMemo(() => {
    if (!isActived && !isArchived) {
      return []
    }

    return selectedHistory.filter(
      (land: Land) =>
        (isActived && !land.archived) || (isArchived && land.archived),
    )
  }, [selectedHistory, isActived, isArchived])

  const features = useMapEvents(map, loaded, isHomePage, SOURCE)

  const createPopup = useCallback((feature: Feature) => {
    const { properties, geometry } = feature
    const { title } = properties
    const coordinates = geometry.coordinates.slice()

    const popupContent = `<p>${title}</p>`

    return new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    })
      .setLngLat(coordinates)
      .setHTML(popupContent)
  }, [])

  const handleClick = (e: any) => {
    const feature = e?.features?.[0]

    let land_id = feature?.properties?.land_id

    if (land_id && typeof land_id === 'string') {
      land_id = JSON.parse(land_id || '')
    }

    let url = '?tab=summary'

    if (land_id) {
      const landIds = Array.isArray(land_id) ? land_id.join(',') : land_id

      url += `&landId=${landIds}`
    }

    window.open(url, '_blank')
  }

  useEffect(() => {
    if (!map || !loaded || !isHomePage) return

    // Remove existing popups
    popups.forEach((popup) => popup.remove())

    // Create new popups
    const newPopups = features.map((feature) => createPopup(feature).addTo(map))

    setPopups(newPopups)

    return () => {
      newPopups.forEach((popup) => popup.remove())
    }
  }, [map, loaded, isHomePage, features, createPopup])

  useEffect(() => {
    if (!map || !loaded || !isHomePage || !history?.length) return

    addClusters(map, history)
    map.on('click', 'unclustered-point', handleClick)

    return () => {
      if (map.getSource(SOURCE)) {
        map.removeLayer('clusters')
        map.removeLayer('cluster-count')
        map.removeLayer('unclustered-point')
        map.removeSource(SOURCE)
      }
      map.off('click', 'unclustered-point', handleClick)
    }
  }, [map, loaded, isHomePage, history])

  if (!isHomePage || !map || !loaded) return null

  return null
}

export default LandHistoryMapController
