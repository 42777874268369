import { useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import ApartmentIcon from '@mui/icons-material/Apartment'

const FaoDetailInfomation = ({ transaction }: { transaction: any }) => {
  const { t } = useI18n()

  const data = [
    {
      label: t('fao.Numéro parcelle'),
      value: transaction.land_information,
      icon: (
        <ApartmentIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      label: t("fao.Zone d'affectation"),
      value:
        transaction.land_use_principal_type?.find((item: any) => !!item) || '',
      icon: (
        <ApartmentIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
  ]

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: '1.1rem',
          fontWeight: 500,
          textTransform: 'uppercase',
          mb: 1,
        }}
      >
        {t('common.Informations sur le bien')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          {data.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '4px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      color: '#969696',
                      fontSize: '0.9rem',
                    }}
                  >
                    {item.icon}
                    <Typography
                      fontWeight="500"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={9}>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      color: '#041725',
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default FaoDetailInfomation
