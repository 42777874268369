import { groupBy, useI18n, InfoBox } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'

import { landSelector } from '../redux'
import CollapseList from './CollapseList'

const RestrictionInfo = () => {
  const { t } = useI18n()
  const land: any = useSelector(landSelector)

  const { unAffectedRdppf } = land
  const { regionRdppf } = land

  const rdppfs = Object.values(groupBy('rdppf_type_id', land.rdppfs))

  const restrictionTypes = rdppfs
    ?.map((affection: any) => affection?.[0]?.name)
    .filter(Boolean)
    .map((v) => t(`land.restrictions.zone.${v}`))

  const data = [
    { label: t('land.affectedRestriction'), value: rdppfs?.length },
    { label: t('land.unAffectedRestriction'), value: unAffectedRdppf?.length },
    // {
    //   label: t('land.notAvailableRestriction'),
    //   value: (regionRdppf?.length || 0) - (rdppfs?.length || 0),
    // },
  ]

  const data2 = [
    {
      label: t('land.affectedRestrictionTypes'),
      value: (
        <CollapseList
          items={restrictionTypes}
          moreKey="common.seeMoreRestrictions"
        />
      ),
    },
  ]

  return (
    <InfoBox
      title={t('land.restrictions.title')}
      data={data}
      data2={data2}
      id="land-restirctions-info"
    />
  )
}

export default RestrictionInfo
