/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { mapBy } from '@popety_io/popety-io-lib'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import actionHistoryService from './actionHistoryService'

const initialState = {
  actionHistory: {},
  activityHistory: [],
  loadingReminder: false,
  reminderDataAdd: null,
  reminderDataUpdate: null,
  loadingActivityHistory: false,
}

export const getActionHistory = createAsyncThunk(
  'actionHistoryDashboard/getActionHistory',
  actionHistoryService.getActionHistory,
)

export const addNote = createAsyncThunk(
  'actionHistoryDashboard/addNote',
  actionHistoryService.addNote,
)

export const updateNote = createAsyncThunk(
  'actionHistoryDashboard/updateNote',
  actionHistoryService.updateNote,
)

export const removeNote = createAsyncThunk(
  'actionHistoryDashboard/removeNote',
  actionHistoryService.removeNote,
)

export const addReminder = createAsyncThunk(
  'actionHistoryDashboard/addReminder',
  actionHistoryService.addReminder,
)

export const updateReminder = createAsyncThunk(
  'actionHistoryDashboard/updateReminder',
  actionHistoryService.updateReminder,
)

export const removeReminder = createAsyncThunk(
  'actionHistoryDashboard/removeReminder',
  actionHistoryService.removeReminder,
)

export const getActivityHistoryLand = createAsyncThunk(
  'actionHistoryDashboard/getActivityHistoryLand',
  actionHistoryService.getActivityHistoryLand,
)

const actionHistoryDashboard = createSlice({
  name: 'actionHistoryDashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle async actions here, not in reducers
    builder
      .addCase(getActionHistory.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.actionHistory = {
          ...mapBy(
            'uid',
            data.map((r) => ({
              ...r,
              uid: `${r.action}_${r.note_id || r.id}`,
            })),
          ),
        }
      })
      .addCase(addNote.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        if (data) {
          const note = data[0]

          state.actionHistory[
            `${note.action || 'note'}_${note.note_id || note.id}`
          ] = note
        }
      })
      .addCase(removeNote.fulfilled, (state, action) => {
        const { noteId } = action.payload || {}

        if (noteId) {
          delete state.actionHistory[`note_${noteId}`]
        }
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        const value = action.payload || {}

        if (value) {
          state.actionHistory[`note_${value.note_id}`] = {
            ...state.actionHistory[`note_${value.note_id}`],
            ...value,
          }
        }
      })
      .addCase(addReminder.pending, (state) => {
        state.loadingReminder = true
      })
      .addCase(addReminder.rejected, (state) => {
        state.loadingReminder = false
      })
      .addCase(addReminder.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const input = action.meta.arg

        if (!data.land_id) {
          data.land_id = input.landId
        }

        if (data) {
          state.actionHistory[`${data.action || 'reminder'}_${data.id}`] = data
          state.reminderDataAdd = data
        }
        state.loadingReminder = false
      })
      .addCase(removeReminder.pending, (state) => {
        state.loadingReminder = true
      })
      .addCase(removeReminder.rejected, (state) => {
        state.loadingReminder = false
      })
      .addCase(removeReminder.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const input = action.meta.arg

        if (input?.id) {
          delete state.actionHistory[`reminder_${input.id}`]
        }

        if (data) {
          delete state.actionHistory[`reminder_${data.id}`]
        }
        state.loadingReminder = false
      })
      .addCase(updateReminder.pending, (state) => {
        state.loadingReminder = true
      })
      .addCase(updateReminder.rejected, (state) => {
        state.loadingReminder = false
      })
      .addCase(updateReminder.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const input = action.meta.arg

        if (data) {
          state.actionHistory[`reminder_${input.id}`] = {
            ...state.actionHistory[`reminder_${input.id}`],
            alarm_date: input?.dateAlarm,
            subject: input?.subject,
            id: data?.id,
            landId: input?.landId,
            last_name: data?.last_name,
            first_name: data?.first_name,
            update_timestamp: data?.update_timestamp,
          }
          state.reminderDataUpdate = data
        }
        state.loadingReminder = false
      })
      .addCase(getActivityHistoryLand.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.activityHistory = data.results
        state.countsHistory = data.counts
        state.countsHistoryTotal = data.total
        state.loadingActivityHistory = false
      })
      .addCase(getActivityHistoryLand.pending, (state) => {
        state.loadingActivityHistory = true
      })
      .addCase(getActivityHistoryLand.rejected, (state) => {
        state.loadingActivityHistory = false
      })
  },
})

export const actionHistoryDashboardReducer = {
  actionHistoryDashboard: actionHistoryDashboard.reducer,
}
