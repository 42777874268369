import { Fragment, memo } from 'react'

import { FaoMapConfig } from '../Fao/FaoAround'
import { GeoMapConfig } from '../Geo'
import { ListingMapConfig } from '../Listing'
import { LivabilityMapConfig } from '../Livability'
import { SummaryMapConfig } from '../Summary'
import { SunlightMapConfig } from '../Sunlight'
import { TransactionMapConfig } from '../Transaction'
import { UnderExploitedMapConfig } from '../UnderExploited'
import { RestrictionMapConfig } from '../Restriction'
import { DesignMapConfig } from '../Design'
import { NoiseMapConfig } from '../LandNoise'
import { LandHistoryMapConfig } from '../components/LandHistory'
import { LandEventHistoryMapConfig } from '../LandEventHistory'

type MapConfigType =
  | (Partial<typeof FaoMapConfig> & {
      Controller?: React.ElementType<any>
      style?: string
    })
  | {
      Click?: (data: any) => JSX.Element
      Hover?: (data: any) => JSX.Element
      Content?: () => JSX.Element
      Controller: ({ map, loaded }: { map: any; loaded?: boolean }) => any
      style?: string
    }

const MapConfigByPage: Record<string, MapConfigType> = {
  home: LandHistoryMapConfig,
  summary: SummaryMapConfig,
  transaction: TransactionMapConfig,
  fao: FaoMapConfig,
  listing: ListingMapConfig,
  livability: LivabilityMapConfig,
  geo: GeoMapConfig,
  sunlight: SunlightMapConfig,
  lupa: UnderExploitedMapConfig,
  'financial-plan': SummaryMapConfig,
  ownership: SummaryMapConfig,
  building: SummaryMapConfig,
  restriction: RestrictionMapConfig,
  'design-2': DesignMapConfig,
  noise: NoiseMapConfig,
  history: LandEventHistoryMapConfig,
}

/**
 * Map controllers have the full control of the map
 */
const MapControllers = memo((props: any) => (
  <>
    {Object.values(MapConfigByPage).map((comp, i) => {
      const { Controller } = comp

      if (!Controller) return <Fragment key={i} />

      return <Controller {...props} key={i} />
    })}
  </>
))

export { MapConfigByPage, MapControllers }
