import { useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const FaoDetailFooter = ({ fao }: { fao: any }) => {
  const { t } = useI18n()

  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: '#ffffff',
        paddingTop: 1,
        paddingBottom: 1,
        mt: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" gap={1}>
          <Button
            variant="contained"
            onClick={() => {
              window.open(fao.url_official, '_blank')
            }}
            // disabled={!!fao.url_official && !fao.ad_status}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              backgroundColor: '#ffffff',
              color: '#00ade6',
              '&:hover': { backgroundColor: '#fff' },
            }}
          >
            <OpenInNewIcon />
            {t('common.View Public Inquiries')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default FaoDetailFooter
