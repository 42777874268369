import { ApiClient } from '../../../services'

const client = new ApiClient()

const verify2faCode = async ({
  code,
  type,
}: {
  code: string
  type: string
}) => {
  try {
    const response = await client.post(`/auth/verify-2fa`, { code, type })
    const payload = response.data

    if (payload) {
      localStorage.setItem('jwt', payload.token)
      localStorage.setItem('user', JSON.stringify(payload.user))
      localStorage.removeItem('twoFAToken')
      localStorage.setItem('enabled2Fa', 'true')
    }

    return response
  } catch (error) {
    console.error(error)
  }
}

const resend2FaToSMS = () => {
  return client.get(`/auth/resend-2fa-sms`)
}

const confirmPhone = async (phone: string) => {
  try {
    const response = await client.post(`/auth/confirm-phone`, { phone })

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

const twoFactorService = { verify2faCode, confirmPhone, resend2FaToSMS }

export default twoFactorService
