import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fetchOnce } from '@popety_io/popety-io-lib'

import {
  getAllTagsName,
  getTagLands,
  listTagsSelector,
  refetchFlagSelector,
  tagsByLandIdSelector,
  uniqueTagsSelector,
  updateRefetchFlag,
} from '../../pages/Dashboard/redux'
import { getUserId } from '../../services'
import { sharedSelector } from '../../pages/Login'
import useClearUpEffect from '../../hooks/useClearUpEffect'

type getTagForLandProps = {
  landIds: any
  tagsbyId?: Record<string, any>
}

const handleSortListTags = (listTags: any[]) => {
  return listTags?.sort((prev: any, next: any) => {
    let sort = 0

    if (prev?.primary && !next?.primary) {
      sort = -1
    }
    if (!prev?.primary && next?.primary) {
      sort = 1
    }

    if (!prev?.primary && !next?.primary) {
      if (prev?.secondary && !next?.secondary) {
        sort = -1
      }
      if (!prev?.secondary && next?.secondary) {
        sort = 1
      }
    }

    return sort
  })
}

const getTagForLand = ({ landIds, tagsbyId = {} }: getTagForLandProps) => {
  const userId = getUserId()

  const sanitizedLandIds = Array.isArray(landIds)
    ? landIds
    : [landIds].filter(Boolean)

  let activeLandTags =
    sanitizedLandIds
      ?.map((id) => tagsbyId[id])
      .flat()
      .filter((tg) => tg?.active) || []

  const activeLandTagIds = activeLandTags?.map((tg) => Number(tg.tag_id))

  const myActiveLandTags = activeLandTags.filter(
    (tg) => String(tg.user_id) === userId,
  )

  const myActiveLandTagIds = myActiveLandTags?.map((tg) => tg.tag_id)

  const activeUniqueLandTags = activeLandTags.filter(
    (value, index, self) =>
      index === self.findIndex((tg) => tg.value === value.value),
  )

  const isTagOwner = (tag: any) => {
    return !!activeLandTags?.find(
      (tg) =>
        String(tg?.tag_id) === String(tag?.tag_id) &&
        (String(tg?.user_id) === userId || String(tg?.author) === userId),
    )
  }

  if (landIds && Array.isArray(landIds) && landIds?.length > 1) {
    activeLandTags = activeLandTags?.filter((item) => {
      return landIds?.every((land: any) => {
        return activeLandTags.some(
          (obj) => obj.tag_id === item.tag_id && obj.land_id === Number(land),
        )
      })
    })
  }

  return {
    isTagOwner,
    activeLandTagIds,
    tags: tagsbyId,
    myActiveLandTags,
    activeLandTags,
    sanitizedLandIds,
    activeUniqueLandTags,
    myActiveLandTagIds,
  }
}

let fetched: any

type useTagProps = {
  landIds: string | string[]
  fetch?: boolean
}

const useTag = ({ landIds, fetch = false }: useTagProps) => {
  const location = useLocation()
  const tagsbyId = useSelector(tagsByLandIdSelector)
  const uniqueTags = useSelector(uniqueTagsSelector)
  const listTags = useSelector(listTagsSelector)
  const shared = useSelector(sharedSelector)
  const needRefetch = useSelector(refetchFlagSelector)
  const res = getTagForLand({ landIds, tagsbyId })

  const userId = getUserId()
  const active = !!res.activeLandTags?.length

  const dispatch = useDispatch<any>()

  useClearUpEffect(() => {
    if (!fetch) {
      return
    }

    const session = userId + location.pathname

    if (session === fetched && !needRefetch) return

    dispatch(getTagLands({ isShared: shared?.shareTaggedLands }))
    fetchOnce('listTags', null, () => dispatch(getAllTagsName() as any))
    dispatch(updateRefetchFlag(false))
    fetched = session
  }, [userId, fetch])

  return {
    ...res,
    uniqueTags,
    active,
    listTags,
    shared,
    tagsbyId,
    handleSortListTags,
  }
}

export default useTag

export { getTagForLand }
