const listingsSelector = (state) => state.listingHistory.listings

const selectedListingsSelector = (state) =>
  state.listingHistory.selectedListings

const aggregationsSelector = (state) => state.listingHistory.aggregations

const aggregationLoadingSelector = (state) =>
  state.listingHistory.aggregationLoading

const aggregationDatesSelector = (state) =>
  state.listingHistory.aggregations.dates.dates

const listingLoadingSelector = (state) => state.listingHistory.loading

const listingBboxSelector = (state) => state.listingHistory.bbox

const addressesLoadingSelector = (state) =>
  state.listingHistory.addressesLoading

const noLayersSelector = (state) => state.listingHistory.noLayers

const listingSearchGeojsonSelector = (state) => state.listingHistory.features

const favoriteListingSelector = (state) => state.listingHistory.favoriteListing

const countListingSelector = (state) =>
  state.listingHistory.count || state.listingHistory.total

const propertyCategoryOptionSelector = (state) =>
  state.listingHistory.propertyCategoryOptions

const vendorTypeOptionSelector = (state) =>
  state.listingHistory.vendorTypeOptions

const propertyTypesSelector = (state) => state.listingHistory.propertyTypes

const propertyTypeInitStateSelector = (state) =>
  state.listingHistory.propertyTypeInitState

const aggregationsLocationsSelector = (state) =>
  state.listingHistory.aggregationsLocations

const aggregationsLocationsLoadingSelector = (state) =>
  state.listingHistory.aggregationLocationLoading

const propertyTypesLoadingSelector = (state) =>
  state.listingHistory.propertyTypesLoading

const listingsTileDetailsSelector = (state) =>
  state.listingHistory.listingsTileDetails

const listingsTileDetailLoadingsSelector = (state) =>
  state.listingHistory.listingsTileDetailsLoading

export {
  listingsSelector,
  aggregationsSelector,
  aggregationDatesSelector,
  listingLoadingSelector,
  listingBboxSelector,
  addressesLoadingSelector,
  noLayersSelector,
  listingSearchGeojsonSelector,
  favoriteListingSelector,
  countListingSelector,
  aggregationLoadingSelector,
  propertyCategoryOptionSelector,
  propertyTypesSelector,
  propertyTypeInitStateSelector,
  vendorTypeOptionSelector,
  selectedListingsSelector,
  aggregationsLocationsSelector,
  aggregationsLocationsLoadingSelector,
  propertyTypesLoadingSelector,
  listingsTileDetailsSelector,
  listingsTileDetailLoadingsSelector,
}
