import { createSelector } from '@reduxjs/toolkit'

const actionHistorySelector = (state) =>
  state.actionHistoryDashboard.actionHistory

const activitySelector = (state) => state.actionHistoryDashboard.activityHistory

const loadingActivitySelector = (state) =>
  state.actionHistoryDashboard.loadingActivityHistory

const countActivitySelector = (state) =>
  state.actionHistoryDashboard.countsHistoryTotal || 0

const actionHistoriesSelector = createSelector(actionHistorySelector, (h) =>
  Object.values(h).sort(
    (a, b) =>
      new Date(b.create_timestamp).getTime() -
      new Date(a.create_timestamp).getTime(),
  ),
)

const notesSelector = createSelector(actionHistoriesSelector, (h) =>
  h.filter((actionHistory) => actionHistory.action === 'note'),
)

const remindersSelector = createSelector(actionHistoriesSelector, (h) =>
  h.filter((actionHistory) => actionHistory.action === 'reminder'),
)

const actionsFavoritesSelector = createSelector(actionHistoriesSelector, (h) =>
  h.filter((actionHistory) => actionHistory.action === 'favorite'),
)

const countCategoriesSelector = (state) =>
  state.actionHistoryDashboard.countsHistory

const loadingReminderDataSelector = (state) =>
  state.actionHistoryDashboard.loadingReminder

const reminderDataAddSelector = (state) =>
  state.actionHistoryDashboard.reminderDataAdd

const reminderDataUpdateSelector = (state) =>
  state.actionHistoryDashboard.reminderDataUpdate

export {
  notesSelector,
  actionHistoriesSelector,
  remindersSelector,
  actionsFavoritesSelector,
  activitySelector,
  countActivitySelector,
  countCategoriesSelector,
  loadingReminderDataSelector,
  reminderDataAddSelector,
  reminderDataUpdateSelector,
  loadingActivitySelector,
}
