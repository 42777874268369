import { createSelector } from '@reduxjs/toolkit'

import { getRestrictionLayers } from '../../Restriction/Restriction.layers'
import { currentLivingAreaSelector } from '../building'
import { landUsePlansSelector } from '../underExploited'
import { selectedDesignSelector } from '../generativeDesign'
import { getAuthorizedLimitLayers } from './authorizedLimit.layers'
import {
  getLandLayers,
  getLandLayersOultined,
  getLandLayersOutlinedDasharray,
} from './land.layers'
import {
  getAuthorizedCos,
  getAuthorizedCus,
  getAuthorizedFloorNumberMax,
  getAuthorizedUnitNumberMax,
} from './landHelper'
import { getGeneratedLayers } from './summary.utils'

const landSelector = createSelector(
  (state) => state.summary.land,
  (land) => land || {},
)

const landIdSelector = (state) => state.summary.land?.land_id

const addressIdSelector = (state) => state.summary.land?.address_id

const landRegionSelector = (state) => state.summary.land?.region_name

const landLoadingSelector = (state) => state.summary.loading

const landGeocenterSelector = (state) => state.summary.land?.geo_center

const summaryMapSelectedLayersSelector = (state) => state.summary.selectedLayers

const landIdStringSelector = createSelector(landIdSelector, (landId) => {
  return landId ? `${landId}` : null
})

const groupingIdSelector = (state) => state.summary.land?.groupingId

const geoCenterSelector = (state) => state.summary.land?.geo_center

const landGeoCenterSelector = createSelector(
  geoCenterSelector,
  (geo_center) => [geo_center?.lon || 6.8315665, geo_center?.lat || 46.469118],
)

const rdppfsSelector = (state) => state.summary.land?.rdppfs

// restriction layers: TODO: move to another folder
const restrictionLayersSelector = createSelector(rdppfsSelector, (rdppfs) =>
  getRestrictionLayers(rdppfs),
)

// authorized limit layers: TODO: move to another folder
const authorizedLimitLayersSelector = createSelector(landSelector, (land) =>
  getAuthorizedLimitLayers(land.authorizedLimit),
)

// land layers
const landLayersSelector = createSelector(landSelector, (land) =>
  getLandLayers(land),
)

const landLayersOutlinedSelector = createSelector(landSelector, (land) =>
  getLandLayersOultined(land),
)

const landLayersOutlinedDasharraySelector = createSelector(
  landSelector,
  (land) => getLandLayersOutlinedDasharray(land),
)

const cityGeojsonSelector = (state) => state.summary.cityGeojson

const cityGeoCenterSelector = (state) => state.summary.cityGeoCenter

// coumputed info. TODO: to be moved elsewhere

const authorizedCosSelector = createSelector(
  landUsePlansSelector,
  landRegionSelector,
  getAuthorizedCos,
)

const authorizedCusSelector = createSelector(
  landUsePlansSelector,
  landRegionSelector,
  getAuthorizedCus,
)

const authorizedFloorNumberMaxSelector = createSelector(
  landUsePlansSelector,
  getAuthorizedFloorNumberMax,
)

const authorizedUnitNumberMaxSelector = createSelector(
  landUsePlansSelector,
  getAuthorizedUnitNumberMax,
)

const landAuthorizedInfoSelector = createSelector(
  landSelector,
  authorizedCosSelector,
  authorizedFloorNumberMaxSelector,
  authorizedUnitNumberMaxSelector,
  currentLivingAreaSelector,
  authorizedCusSelector,
  (
    land,
    authorizedCos,
    authorizedFloorNumberMax,
    authorizedUnitNumberMax,
    currentLivingArea,
    authorizedCus,
  ) => {
    const std = land?.std
    const landArea = land?.official_area || land?.area
    const area = std !== 0 ? std : landArea

    return {
      authorizedCos,
      authorizedFloorNumberMax,
      authorizedUnitNumberMax,
      landArea,
      area,
      std,
      currentLivingArea,
      authorizedCus,
    }
  },
)

const generatedBuildingSelector = createSelector(
  selectedDesignSelector,
  getGeneratedLayers,
)

const landAnalysisHistorySelector = (state) => state.summary.landAnalysisHistory

const loadLandAnalysisHistorySelector = (state) => state.summary.loadingHistory

const groupLandAnalysisHistorySelector = (state) =>
  state.summary.groupLandAnalysisHistory

const loadGroupLandAnalysisHistorySelector = (state) =>
  state.summary.loadingGroupLandHistory

export {
  landSelector,
  landIdSelector,
  landLoadingSelector,
  landIdStringSelector,
  landRegionSelector,
  groupingIdSelector,
  restrictionLayersSelector,
  authorizedLimitLayersSelector,
  landLayersSelector,
  landGeoCenterSelector,
  summaryMapSelectedLayersSelector,
  landGeocenterSelector,
  geoCenterSelector,
  //
  authorizedCosSelector,
  authorizedFloorNumberMaxSelector,
  authorizedUnitNumberMaxSelector,
  landAuthorizedInfoSelector,
  authorizedCusSelector,
  landLayersOutlinedSelector,
  cityGeojsonSelector,
  cityGeoCenterSelector,
  landLayersOutlinedDasharraySelector,
  generatedBuildingSelector,
  rdppfsSelector,
  addressIdSelector,
  landAnalysisHistorySelector,
  loadLandAnalysisHistorySelector,
  groupLandAnalysisHistorySelector,
  loadGroupLandAnalysisHistorySelector,
}
