import { useEffect, useState } from 'react'
import NoteIcon from '@mui/icons-material/StickyNote2'
import { useSelector, useDispatch } from 'react-redux'
import {
  Modal,
  fetchOnce,
  useMediaQuery,
  useI18n,
} from '@popety_io/popety-io-lib'

import { EditNote } from '@mui/icons-material'
import { getActionHistory, notesSelector } from '../../pages/Dashboard/redux'
import LandNote from './LandNote'
import { NoteBadge } from './LandNote.style'

export type LandNoteIconOpts = {
  landId: string | string[]
  color?: string
}

const useLandNote = ({ landId, color }: LandNoteIconOpts) => {
  const dispatch = useDispatch<any>()
  const [open, setOpen] = useState(false)
  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const handleOpen = (e: any) => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const notes = useSelector(notesSelector)

  const landIds = (Array.isArray(landId) ? landId : [landId]).map(Number)
  const count = notes.filter((n) => landIds.includes(+n.land_id)).length

  const { t } = useI18n()

  useEffect(() => {
    if (landId) {
      fetchOnce('getActionHistory', landId, () => {
        dispatch(getActionHistory(landId))
      })
    }
  }, [landId])

  const Icon = (
    <NoteBadge sx={{ ml: 0 }} badgeContent={count} color="primary">
      <NoteIcon
        color={count ? 'primary' : undefined}
        sx={{ color: color || undefined, opacity: count ? 1 : 0.3 }}
      />
    </NoteBadge>
  )

  const EditNoteIcon = (
    <NoteBadge sx={{ ml: 0 }} badgeContent={count} color="primary">
      <EditNote
        color={count ? 'primary' : undefined}
        sx={{ color: count ? '#00ade6' : '#969696', opacity: 1 }}
      />
    </NoteBadge>
  )

  const NoteModal = (
    <Modal
      open={open}
      onClose={handleClose}
      title={t('common.notes.title')}
      okText={t('common.close')}
      onOk={handleClose}
      maxWidth="xl"
      fullScreen={!onDesktop}
      sx={{ '& .MuiPaper-root': { width: '50%' } }}
    >
      <LandNote landId={landId} />
    </Modal>
  )

  const title = t('common.noteIcon')

  return {
    count,
    title,
    Icon,
    EditNoteIcon,
    onClick: handleOpen,
    Modal: NoteModal,
  }
}

export default useLandNote
