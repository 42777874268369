/* eslint-disable max-lines */
import * as turf from '@turf/turf'

export const getHistoryBounds = (history: any) => {
  const padding = 0.2 // Adjust this value according to your needs

  const bboxHistory = history
    ?.map((element: any) => {
      return (
        element?.default_value?.geo_center ||
        element?.value?.geoCenter ||
        element?.geo_center
      )
    })
    ?.filter(Boolean)
    ?.map((element: any) => [element?.lon, element?.lat])

  const featureColletion = bboxHistory?.length
    ? turf.featureCollection(
        bboxHistory?.map((element: any) => turf.point(element)) as any,
      )
    : null

  const envelope = featureColletion ? turf.envelope(featureColletion) : null

  const paddedBounds = envelope?.bbox?.map((element: any, index: number) => {
    return index < 2 ? element - padding : element + padding
  })

  return paddedBounds
}

type estimateHistoriesProps = {
  estimateHistories: any[]
  checks?: { active: boolean; archived: boolean }
  value?: string
  query?: any
}

export const filtersEstimateHistory = ({
  estimateHistories,
  value,
  query,
  checks,
}: estimateHistoriesProps) => {
  const { propertyCategory, rooms, area, years, price } = query
  let items = estimateHistories

  if (value) {
    items = estimateHistories?.filter((item) =>
      item?.value_edited?.title?.includes(value),
    )
  }

  if (propertyCategory) {
    items = items?.filter(
      (item) =>
        item?.value_edited?.type === propertyCategory.toLowerCase() ||
        item?.value?.type === propertyCategory.toLowerCase() ||
        item?.default_value?.type === propertyCategory.toLowerCase(),
    )
  }

  if (price) {
    const minPrice = price?.split(',')?.[0]
    const maxPrice = price?.split(',')?.[1]

    items = items?.filter((item: any) => {
      const value: any =
        item?.value_edited || item?.value || item?.default_value

      const price =
        value?.price ||
        ((item?.price?.valueRange?.lower || 0) +
          (item?.price?.valueRange?.upper || 0)) /
          2

      if (minPrice && maxPrice) {
        return (
          parseFloat(price) >= parseFloat(minPrice) &&
          parseFloat(price) <= parseFloat(maxPrice)
        )
      }

      if (minPrice) {
        return parseFloat(price) >= parseFloat(minPrice)
      }

      if (maxPrice) {
        return parseFloat(price) <= parseFloat(minPrice)
      }

      return true
    })
  }

  if (rooms) {
    const minRoom = rooms?.split(',')?.[0]
    const maxRoom = rooms?.split(',')?.[1]

    items = items?.filter((item) => {
      const rooms =
        item?.value_edited?.rooms ||
        item?.value?.rooms ||
        item?.default_value?.rooms

      if (minRoom && maxRoom) {
        return (
          parseFloat(rooms) >= parseFloat(minRoom) &&
          parseFloat(rooms) <= parseFloat(maxRoom)
        )
      }

      if (minRoom) {
        return parseFloat(rooms) >= parseFloat(minRoom)
      }

      if (maxRoom) {
        return parseFloat(rooms) <= parseFloat(maxRoom)
      }

      return true
    })
  }

  if (area) {
    const minArea = area?.split(',')?.[0]
    const maxArea = area?.split(',')?.[1]

    items = items?.filter((item) => {
      const area =
        item?.value_edited?.area ||
        item?.value?.area ||
        item?.default_value?.area

      if (minArea && maxArea) {
        return (
          parseFloat(area) >= parseFloat(minArea) &&
          parseFloat(area) <= parseFloat(maxArea)
        )
      }

      if (minArea) {
        return parseFloat(area) >= parseFloat(minArea)
      }

      if (maxArea) {
        return parseFloat(area) <= parseFloat(maxArea)
      }

      return true
    })
  }

  if (years) {
    const minYear = years?.split(',')?.[0]
    const maxYear = years?.split(',')?.[1]

    items = items?.filter((item) => {
      const year =
        item?.value_edited?.constructionYear ||
        item?.value?.construction_date ||
        item?.default_value?.construction_date

      if (minYear && maxYear) {
        return (
          parseInt(year, 10) >= parseInt(minYear, 10) &&
          parseInt(year, 10) <= parseInt(maxYear, 10)
        )
      }

      if (minYear) {
        return parseInt(year, 10) >= parseInt(minYear, 10)
      }

      if (maxYear) {
        return parseInt(year, 10) <= parseInt(maxYear, 10)
      }

      return true
    })
  }

  if (checks?.active && checks?.archived) {
    return items
  }
  if (!checks?.active && !checks?.archived) {
    return []
  }

  if (checks?.active) {
    items = items?.filter((item) => !item?.archive)
  }

  if (checks?.archived) {
    items = items?.filter((item) => item?.archive)
  }

  return items
}
