import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {
  SunlightIcon,
  SunriseIcon,
  SunsetIcon,
  SunTime,
  useI18n,
  format,
} from '@popety_io/popety-io-lib'

export type SunlightTimeBoxProps = {
  title?: string
  sunlightTime: Record<string, any>
  sunlightHours: number
}

const SunlightTimeBox = ({
  title = 'PPP',
  sunlightTime,
  sunlightHours,
}: SunlightTimeBoxProps) => {
  const { t } = useI18n()

  return (
    <Box>
      <Typography mb={2} variant="subtitle1">
        {title}
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        flexDirection={{ xs: 'column', sm: 'row' }}
        rowGap={{ xs: 1.5, sm: 0 }}
      >
        <SunTime
          icon={<SunriseIcon />}
          title={
            sunlightTime?.sunrise
              ? format(sunlightTime?.sunrise, 'p').replace(':', 'h')
              : '-'
          }
          subTitle={t('land.sun.sunrise')}
        />

        <SunTime
          icon={<SunlightIcon />}
          title={`${Math.floor(sunlightHours)}h`}
          subTitle={t('land.sun.Day duration')}
        />

        <SunTime
          icon={<SunsetIcon />}
          title={
            sunlightTime?.sunset
              ? format(sunlightTime?.sunset, 'p').replace(':', 'h')
              : '-'
          }
          subTitle={t('land.sun.sunset')}
        />
      </Box>
    </Box>
  )
}

export default SunlightTimeBox
