import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import { useI18n } from '@popety_io/popety-io-lib'
import {
  sunlightSummerHoursSelector,
  sunlightWinterHoursSelector,
} from '../redux/sunlight/sunlightSelector'
import { ContentTitleItemRoot } from '../../Estimate/components/EstimateNoise/components/NightAndDay/NightAndDay.style'

const SunlightEstimateSection = () => {
  const { t } = useI18n()
  const sunlightWinterHours = useSelector(sunlightWinterHoursSelector)
  const sunlightSummerHours = useSelector(sunlightSummerHoursSelector)

  const data = [
    {
      icon: (
        <WbSunnyIcon
          fontSize="large"
          sx={{
            color: '#FBD823',
          }}
        />
      ),
      title: t('common.ete'),
      hoursTile: t('estimate.Durée du jour maximum en été'),
      hours: sunlightSummerHours ? `${Math.floor(sunlightSummerHours)}h` : '-',
    },
    {
      icon: <AcUnitIcon fontSize="large" />,
      title: t('common.hiver'),
      hoursTile: t('estimate.Durée du jour maximum en hiver'),
      hours: sunlightWinterHours ? `${Math.floor(sunlightWinterHours)}h` : '-',
      typeIcon: 'svg',
    },
  ]

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      {data.map((item, key) => {
        return (
          <Box
            key={`noise_${key}`}
            sx={{
              display: 'flex',
              marginBottom: '10px',
              justifyContent: 'space-between',
              alignItems: 'start',
              fontWeight: 500,
              gap: 8,
              flexDirection: 'row',
            }}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" alignItems="center" gap={1}>
                {item.icon}
                <span style={{ textTransform: 'uppercase' }}>{item.title}</span>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ContentTitleItemRoot>
                <Typography variant="h4" fontWeight={500}>
                  {item.hours}
                </Typography>

                <Typography display="flex" alignItems="center">
                  {item.hoursTile}
                </Typography>
              </ContentTitleItemRoot>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export default SunlightEstimateSection
