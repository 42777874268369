import { Select, useRouter, useI18n } from '@popety_io/popety-io-lib'

const ListingFilters = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()

  const items = [{ key: 'all' }, { key: 'purchase' }, { key: 'rent' }].map(
    (r: any) => ({
      ...r,
      value: r.key,
      label: t(`common.dealType.${r.key}`),
    }),
  )

  const handleFiltersChange = (name: string) => (newValue: any) => {
    if (newValue?.value === 'all') {
      updateQuery({ [name]: undefined })
    } else {
      updateQuery({ [name]: newValue?.value || newValue })
    }
  }

  return (
    <>
      <Select
        options={items}
        placeholder={t('land.listing.dealType')}
        value={query.dealType}
        onChange={handleFiltersChange('dealType')}
        sx={{ width: '100%', maxWidth: { xs: '100%', lg: '100%' } }}
      />
    </>
  )
}

export default ListingFilters
