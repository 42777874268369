import ReportProblemRounded from '@mui/icons-material/ReportProblemRounded'
import Check from '@mui/icons-material/Check'
import InfoIcon from '@mui/icons-material/Info'

const headers = {
  unAffected: {
    id: 'unAffected',
    key: 'common.restriction.unAffected',
    icon: <Check />,
    isUnAffected: true,
  },
  affected: {
    id: 'affected',
    key: 'common.restriction.affected',
    icon: <ReportProblemRounded />,
  },
  noUnAffected: {
    id: 'noUnAffected',
    key: 'land.restriction.no unAffected',
    icon: <Check />,
    isUnAffected: true,
  },
  noAffected: {
    id: 'noAffected',
    key: 'land.restriction.no affected',
    icon: <ReportProblemRounded />,
  },
  notRegiondRdppf: {
    id: 'notRegiondRdppf',
    key: 'common.restriction.notRegiondRdppf',
    icon: <InfoIcon />,
  },
} as const

export { headers }

export type HeaderItemKey = keyof typeof headers
// prettier-ignore
export type HeaderItem = typeof headers[HeaderItemKey]

export interface RestrictionProps {
  rdppfs: any
  cadastreLink?: string | undefined
  regionRdppf?: any
}
