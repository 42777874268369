export const getYearFromPeriod = (period: string) => {
  // Get the year from a period
  // Example: if period = '1970-1974' it returns 1972
  // :param period: year period. Example: '1970-1974'

  //  manage the exceptions
  if (period === '0-1919') {
    return 1919
  }
  if (period === '2015-x') {
    return 2015
  }

  // manage normal periods and extract the two years
  const years = period.split('-')

  return Number(years[0]) + (Number(years[1]) - Number(years[0])) / 2 || 0
}

export const calculateBuildingsGroupedAnalytics = (
  buildings: any,
  averageProtectedRank: number,
  averageConditionYear: number,
  // parcel: any
) => {
  let protectedRankCumulated = 0
  let protectedRankAreaCumulated = 0
  let renovationYearCumulatedArea = 0
  let renovationYearCumulated = 0

  buildings?.forEach((building: any) => {
    const floorNb = building.floorNbOveride || building.floor_nb

    // get the protected rank
    protectedRankAreaCumulated += building.cover_area * floorNb
    if (building.protected_rank) {
      protectedRankCumulated +=
        building.protected_rank * floorNb * building.cover_area
    }

    // Get renovation year
    building.buildings_administrative.forEach(
      (building_administrative: any) => {
        let renovationYear = 0
        const currentRenovationYear =
          Number(building.renovationYearOveride) ||
          building_administrative.renovation_year ||
          Number(building.constructionOveride) ||
          building_administrative.building_year

        const periodYear =
          building_administrative.renovation_period ||
          building_administrative.building_period

        const area = Number(building.areaOveride) || building.area
        const buildingAdministrativefloorNb =
          Number(building.floorNbOveride) || building.floor_nb || 1

        // add the differents results to accumulator
        renovationYearCumulatedArea +=
          area * buildingAdministrativefloorNb * building.cover_area_perc

        // TO CHECK
        if (currentRenovationYear) {
          renovationYear = currentRenovationYear
        } else if (periodYear) {
          renovationYear = getYearFromPeriod(periodYear)
        } else {
          // this building area shouldn't be take into account
          renovationYearCumulatedArea -=
            area * buildingAdministrativefloorNb * building.cover_area_perc
        }

        renovationYearCumulated +=
          renovationYear *
          buildingAdministrativefloorNb *
          area *
          building.cover_area_perc
      },
    )
  })

  return {
    average_protected_rank:
      protectedRankAreaCumulated > 0
        ? Number(
            Math.round(protectedRankCumulated / protectedRankAreaCumulated),
          )
        : averageProtectedRank,
    // average_renovation_year: Number(
    //   Math.round(renovationYearCumulated / renovationYearCumulatedArea)
    // ),
    average_condition_year:
      renovationYearCumulatedArea > 0
        ? Number(
            Math.round(renovationYearCumulated / renovationYearCumulatedArea),
          )
        : averageConditionYear,
  }
}
