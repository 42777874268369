/* eslint-disable max-lines */
import { formatPrice, useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import RepeatOnIcon from '@mui/icons-material/RepeatOn'
import EventHistoryChart from './EventHistoryChart'

const EventHistoryMainInformation = ({ event }: { event: any }) => {
  const { t } = useI18n()

  return (
    <>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingBottom: '5px',
                paddingTop: '5px',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: '4px',
                }}
                fontSize="1.2rem"
              >
                {t('land.fao.price')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  flexWrap: 'wrap',
                }}
              >
                <Typography
                  color="primary"
                  variant="h4"
                  fontWeight="bold"
                  fontSize="1.5rem"
                >
                  {event.price ? formatPrice(event.price) : '-'}
                </Typography>
                <TrendingDownIcon
                  sx={{
                    fontSize: '1.6rem',
                    color: '#E63900',
                    strokeWidth: 2,
                  }}
                />
              </Box>
              <Typography
                variant="h5"
                sx={{
                  color: '#969696',
                  paddingBottom: '10px',
                }}
                fontSize="1rem"
              >
                {event?.price &&
                  event?.area > 0 &&
                  `${formatPrice(Math.round(event.price / event.area))} / m2`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingBottom: '5px',
                paddingTop: '5px',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: '4px',
                }}
                fontSize="1.2rem"
              >
                {t('common.type')}
              </Typography>
              <Typography
                color="primary"
                variant="h4"
                fontWeight="bold"
                fontSize="1.3rem"
              >
                {`${t(`land.property_type.${event.property_type}`)} / ${t(
                  `land.property_type.${event.property_category}`,
                )}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingBottom: '5px',
                paddingTop: '5px',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: '4px',
                }}
                fontSize="1.2rem"
              >
                {t('common.rooms')}
              </Typography>
              <Typography
                color="primary"
                variant="h4"
                fontWeight="bold"
                fontSize="1.5rem"
              >
                {event?.rooms_nb > 0 ? event?.rooms_nb : '-'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingBottom: '5px',
                paddingTop: '5px',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: '4px',
                }}
                fontSize="1.2rem"
              >
                {t('financial.Surface habitable')}
              </Typography>
              <Typography
                color="primary"
                variant="h4"
                fontWeight="bold"
                fontSize="1.5rem"
              >
                {event.area > 0 ? `${event.area} m2` : '-'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          mt: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <EventHistoryChart />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '4px',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: '4px',
                }}
                fontSize="1.2rem"
              >
                {t('common.Mandat')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#8ED1A5',
                    color: '#F7F7F7',
                    fontSize: '13px',
                    fontWeight: 500,
                    borderRadius: '4px',
                    width: '4.2rem',
                    padding: '2px 4px 0px',
                    cursor: 'pointer',
                  }}
                >
                  {t('common.status.pro')}
                </Box>
                <Box
                  sx={{
                    backgroundColor: '#F14C11',
                    color: '#F7F7F7',
                    fontSize: '13px',
                    fontWeight: 500,
                    borderRadius: '2px',
                    width: '4.2rem',
                    padding: '2px 4px 0px',
                    cursor: 'pointer',
                  }}
                >
                  {t('common.Partagé')}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '4px',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: '4px',
                }}
                fontSize="1.2rem"
              >
                {t('common.Agence')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: '8px',
                }}
              >
                <Typography color="#969696" variant="h4" fontSize="1.2rem">
                  Barnes SA
                </Typography>
                <Typography color="#969696" variant="h4" fontSize="1.2rem">
                  Bernard Nicod
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '4px',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: '4px',
                }}
                fontSize="1.2rem"
              >
                {t('common.Portails')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <RepeatOnIcon
                  sx={{
                    fontSize: '1.4rem',
                  }}
                />
                <RepeatOnIcon
                  sx={{
                    fontSize: '1.4rem',
                  }}
                />
                <RepeatOnIcon
                  sx={{
                    fontSize: '1.4rem',
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default EventHistoryMainInformation
