import { bbox } from '@turf/turf'
import { createSelector } from '@reduxjs/toolkit'

const alertsByIdSelector = (state) => state.alert.alertsById

const zoneAffectationSelector = (state) => state.alert.zoneAffectation

const alertsSelector = createSelector(alertsByIdSelector, (alertById) =>
  Object.values(alertById),
)

const minDateAlertsSelector = createSelector(
  alertsSelector,
  (alert) =>
    new Date(Math.min(...alert.map((alert) => new Date(alert?.createdAt)))),
)

const maxDateAlertsSelector = createSelector(
  alertsSelector,
  (alert) =>
    new Date(Math.max(...alert.map((alert) => new Date(alert?.createdAt)))),
)

const alertsLoadingSelector = (state) =>
  state.alert.alertDataLoading ||
  state.alert.getZonesByCityLoading ||
  state.alert.getZonesByDistrictLoading ||
  state.alert.getZonesByRegionLoading ||
  state.alert.getDistrictByIdLoading ||
  state.alert.getCityByIdLoading ||
  state.alert.getRegionByIdLoading ||
  state.alert.getDistrictGeojsonByZoneLoading ||
  state.alert.getRegionGeojsonByZoneLoading ||
  state.alert.getCityGeojsonByZoneLoading

const alertGeoPolygonSelector = (state) => state.alert.geoPolygon

const alertGeoPointSelector = (state) => state.alert.geoPoint

const alertFeaturesSelector = createSelector(
  (state) => state.alert.features,
  (features) => features || [],
)

const alertNameSelector = (state) => state.alert.name

const alertBboxSelector = createSelector(
  alertGeoPolygonSelector,
  (geoPolygon) => {
    try {
      return geoPolygon?.coordinates?.length ? bbox(geoPolygon) : undefined
    } catch (error) {
      return undefined
    }
  },
)

const featureDeletedIdSelector = (state) => state.alert.featureDeletedId

export {
  alertBboxSelector,
  alertsSelector,
  minDateAlertsSelector,
  maxDateAlertsSelector,
  alertsLoadingSelector,
  zoneAffectationSelector,
  alertGeoPolygonSelector,
  alertGeoPointSelector,
  alertFeaturesSelector,
  alertNameSelector,
  alertsByIdSelector,
  featureDeletedIdSelector,
}
