import { ApiClient } from '../../../services'

const client = new ApiClient()

export type ListingSearchOpts = Record<string, any>

const getListings = (filter: ListingSearchOpts) =>
  client.post('/listing/search', filter)

const getSelectedListings = (filter: ListingSearchOpts) =>
  client.post('/listing/search', filter)

const countListings = (filter: ListingSearchOpts) =>
  client.post('/listing/count', filter)

const getListingStats = (filter: ListingSearchOpts) =>
  client.post('/listing/stats', filter)

const getListingsTileDetails = (tile: string[], q = '') =>
  client.get(`/listing/tiles/details?tile=${tile}&query=${q}`)

const getAggregationsListings = ({
  filters,
  signal,
}: {
  filters: ListingSearchOpts
  signal?: any
}) => client.post('/listing/aggregations', filters, signal && { signal })

const getAggregationsPropertyListings = (filter: ListingSearchOpts) =>
  client.post('/listing/aggregations', filter)

const getLocationsAggregations = (filter: ListingSearchOpts) =>
  client.post('/listing/locations/aggregations', filter)

const getPlotsFromListing = (values: any) =>
  client.post('/listing/search/plots', values.data || values, {
    signal: values.signal,
  })

const saveFavoriteListing = (values: any) => {
  const { id } = JSON.parse(localStorage.getItem('user') as any)

  return client.post(
    `/listing/save/favorite/${id}/${values.landId}/${values.listingId}`,
    { address: values.address, geoCenter: values.geoCenter },
  )
}

const getFavoriteListing = () => {
  const { id } = JSON.parse(localStorage.getItem('user') as any)

  return client.get(`/listing/get/${id}`)
}

const activeOrDesactivateFavoriteListing = ({ active, id, listingId }: any) => {
  client.put(`/listing/favorite/${active}/${id}`)

  return { active, listingId }
}

const getListingsTileDetailsRedux = ({ tile, q }: any) => {
  return client.get(`/listing/tiles/details?tile=${tile}&query=${q}`)
}

const listingHistoryService = {
  getListings,
  getListingsTileDetails,
  getListingStats,
  getAggregationsListings,
  countListings,
  getPlotsFromListing,
  saveFavoriteListing,
  getFavoriteListing,
  activeOrDesactivateFavoriteListing,
  getSelectedListings,
  getLocationsAggregations,
  getAggregationsPropertyListings,
  getListingsTileDetailsRedux,
}

export default listingHistoryService
