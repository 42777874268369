/* eslint-disable max-lines */
import { forwardRef } from 'react'
import {
  useI18n,
  FilterChips,
  toReadableNumber,
  format,
  formatPrice,
  useRouter,
} from '@popety_io/popety-io-lib'
import { Box, Grid, Typography } from '@mui/material'

type TransactionMapTileProps = {
  feature?: any
  handleClose: () => void
  data: any[]
  loading?: boolean
  onClick?: () => void
}

const TransactionMapTilePopup = (
  { data, onClick }: TransactionMapTileProps,
  ref: any,
) => {
  const { query, updateQuery } = useRouter()
  const { t } = useI18n()

  const handleClick = (transaction: any) => {
    updateQuery({
      transactionId: transaction?._id || transaction?.id || null,
    })
    if (onClick) {
      onClick()
    }
  }

  const chips = (transaction: any) => {
    return [
      {
        label: transaction.property_type,
        value: transaction.property_type,
        key: 'property_type',
      },
      {
        label: `${t('listing.rooms', { count: transaction.rooms_nb })}`,
        value: transaction.rooms_nb,
        key: 'rooms_nb',
      },
      {
        label: `${toReadableNumber(transaction?.area || 0)} m2`,
        value: transaction.area,
        key: 'area',
      },
      {
        label: transaction.YearOfConstruction,
        value: transaction.YearOfConstruction,
        key: 'YearOfConstruction',
      },
      { label: transaction.Floor, value: transaction.Floor, key: 'Floor' },
      {
        label: `${toReadableNumber(transaction?.UsefulArea || 0)} m2`,
        value: transaction.UsefulArea,
        key: 'UsefulArea',
      },
    ].filter(
      (i) =>
        typeof i.value === 'string' ||
        (typeof i.value === 'number' && i.value > 0),
    )
  }

  return (
    <div
      ref={ref}
      style={{
        background: '#ffffff',
        backgroundColor: '#ffffff',
        padding: '9px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      {data?.map((transaction: any, index: number) => {
        return (
          <Grid
            key={index}
            sx={{
              cursor: 'pointer',
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 4px',
              padding: '4px 10px 3px',
              gap: '4px',
              backgroundColor: `${String(query?.transactionId) === String(transaction?._id || transaction?.id) ? '#00ade6' : ''}`,
            }}
            onClick={() => {
              handleClick(transaction)
            }}
            justifyContent="space-between"
            container
          >
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
              }}
              item
              xs={7}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <Typography
                  color={`${String(query?.transactionId) === String(transaction?._id) ? '#000000' : 'rgb(141, 155, 163)'}`}
                  sx={{
                    display: 'flex',
                    whiteSpace: 'nowrap',
                    fontSize: '10px',
                  }}
                >
                  {t('common.publishedOn')}
                  {' : '}
                </Typography>
                <span
                  style={{
                    color: '#000000',
                    marginLeft: 2,
                    fontSize: '10px',
                  }}
                >
                  {format(transaction?.date, 'P')}
                </span>
              </Box>
              {!!chips?.length && (
                <FilterChips
                  sx={{
                    '> .MuiChip-root': {
                      fontSize: '10px',
                      height: '17px',
                    },
                    flexWrap: 'wrap',
                  }}
                  items={chips(transaction)}
                  size="small"
                  getChipKey={(item: any) => item.key}
                />
              )}
            </Grid>
            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="left"
              item
              xs={4}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'space-around',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2px',
                  }}
                >
                  <Typography
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    fontWeight="500"
                    fontSize="10px"
                    color="#000000"
                  >
                    {transaction?.ownership_type}
                  </Typography>
                  {transaction?.ownership_type && transaction?.reason && (
                    <Typography
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      fontWeight="400"
                      fontSize="10px"
                      color="#000000"
                    >
                      -
                    </Typography>
                  )}
                  <Typography
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    fontWeight="400"
                    fontSize="10px"
                    color="#000000"
                  >
                    {transaction?.reason ? `- ${transaction?.reason}` : ''}
                  </Typography>
                </Box>
                <Typography
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontWeight="500"
                  fontSize="10px"
                  color={`${String(query?.transactionId) === String(transaction?._id) ? '#000000' : '#00ADE6'}`}
                >
                  {formatPrice(transaction?.price)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )
      })}
    </div>
  )
}

export default forwardRef(TransactionMapTilePopup)
