import { useState, useEffect, useCallback, useMemo } from 'react'
import mapboxgl from 'mapbox-gl'
import { Map, useRouter, useI18n } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import { buildingAnalysisHistorySelector } from '../../redux'
import useMapEvents from '../../../../hooks/useMapEvents'
import { addClusters } from './clusters.util'

import '../../../Estimate/Estimate.style'

interface BuildingMapControllerProps {
  map: Map
  loaded?: boolean
}

interface Feature {
  type: 'Feature'
  geometry: {
    type: 'Point'
    coordinates: any
  }
  properties: {
    price?: string
    title?: string
  }
}

interface Building {
  archived: boolean
}

const SOURCE = 'building-history'

const BuildingHistoryMapController: React.FC<BuildingMapControllerProps> = ({
  map,
  loaded,
}) => {
  const buildingAnalysisHistory: any = useSelector(
    buildingAnalysisHistorySelector,
  )
  const { query } = useRouter()
  const [popups, setPopups] = useState<mapboxgl.Popup[]>([])
  const isHomePage = query?.tab === 'home'
  const isArchived = query?.archived === 'true'
  const isActived = query?.active === 'true'

  const history = useMemo(() => {
    if (!isActived && !isArchived) {
      return []
    }

    return buildingAnalysisHistory.filter((building: Building) => {
      return (
        (isActived && !building?.archived) || (isArchived && building?.archived)
      )
    })
  }, [buildingAnalysisHistory, isActived, isArchived])

  const features = useMapEvents(map, loaded, isHomePage, SOURCE)

  const { t } = useI18n()

  const createPopup = useCallback(
    (feature: Feature) => {
      const { properties, geometry } = feature
      const { title } = properties
      const coordinates = geometry.coordinates.slice()

      const popupContent = `
      <p>${title}</p>
    `

      return new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      })
        .setLngLat(coordinates)
        .setHTML(popupContent)
    },
    [t],
  )

  const handleClick = (e: any) => {
    const feature = e?.features?.[0]

    let building_id = feature?.properties?.building_id

    if (building_id && typeof building_id === 'string') {
      building_id = JSON.parse(building_id || '')
    }

    let url = '?tab=summary'

    if (building_id) {
      const buildingIds = Array.isArray(building_id)
        ? building_id.join(',')
        : building_id

      url += `&buildingId=${buildingIds}`
    }

    window.open(url, '_blank')
  }

  useEffect(() => {
    if (!map || !loaded || !isHomePage) return

    // Remove existing popups
    popups.forEach((popup) => popup.remove())

    // Create new popups
    const newPopups = features.map((feature) => createPopup(feature).addTo(map))

    setPopups(newPopups)

    return () => {
      newPopups.forEach((popup) => popup.remove())
    }
  }, [map, loaded, isHomePage, features, createPopup])

  useEffect(() => {
    if (!map || !loaded || !isHomePage || !history?.length) {
      return
    }

    addClusters(map, history)
    map.on('click', 'unclustered-point', handleClick)

    return () => {
      if (map.getSource(SOURCE)) {
        map.removeLayer('clusters')
        map.removeLayer('cluster-count')
        map.removeLayer('unclustered-point')
        map.removeSource(SOURCE)
      }
      map.off('click', 'unclustered-point', handleClick)
    }
  }, [map, loaded, isHomePage, history])

  if (!isHomePage || !map || !loaded) return null

  return null
}

export default BuildingHistoryMapController
