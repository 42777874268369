/* eslint-disable max-lines */
import { useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const FaoDetailNature = ({ fao }: { fao: any }) => {
  const { t } = useI18n()

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={8} md={8}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: '5px',
              paddingTop: '5px',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                paddingBottom: '4px',
              }}
              fontSize="1.2rem"
            >
              {t('fao.nature')}
            </Typography>
            <Typography
              color="primary"
              variant="h4"
              fontWeight="bold"
              fontSize="1.5rem"
            >
              {fao.work_type}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingTop: '5px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                paddingBottom: '5px',
              }}
            >
              <Typography variant="h5" fontSize="1.2rem">
                {t('land.history.lastKnown')}
              </Typography>
              <InfoOutlinedIcon
                sx={{
                  fontSize: '1.4rem',
                  color: '#969696',
                }}
              />
            </Box>
            <Typography
              color="primary"
              variant="h4"
              fontWeight="bold"
              fontSize="1.3rem"
            >
              {fao.result || fao.result_name || '-'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FaoDetailNature
