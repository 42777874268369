import { css, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Grid } from '@mui/material'

export const RightContainer = styled(Box)`
  top: 0;
  bottom: 0;
  right: 0;
`

export const LandDetailsRoot = styled(Box)`
  height: 100%;
  border: 1px solid #ebebeb;
  @media (max-width: 991px) {
    margin-bottom: 40px;
  }
`

export const LeftContainer = styled(Box)`
  z-index: 2;
  padding: 0 20px;

  ${({ theme }) => css`
    ${theme.breakpoints.up('lg')} {
      height: calc(100vh - 157px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  `}
`

export const HomePageHeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 86px;
  gap: 8px;
  padding: 2;

  .Radar {
    width: 73px;
    left: -12px;
    top: -46px;
    margin-right: -20px;
  }
  .buildingIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`

export const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  flex-grow: 1;
`

export const SpanLeftRoot = styled('span')`
  font-weight: 500;
  font-size: 2rem;
  color: #041725;
  display: flex;
`

export const LandRadarWrapper = styled(Grid)`
  display: flex;
  align-items: flex-start;
  height: 74px;
  gap: 8px;

  .Radar {
    height: 164px;
    width: 76px !important;
    left: -12px !important;
    top: -45px !important;
    margin-right: -20px !important;
  }

  .ScoreContainer {
    margin-top: 20px !important;
  }
  .buildingIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`
