import {
  useI18n,
  getBuildingColor,
  toReadableNumber,
} from '@popety_io/popety-io-lib'
import { IconButton, Tooltip } from '@mui/material'
import WarningIcon from '@mui/icons-material/WarningOutlined'
import chroma from 'chroma-js'

import CollapseList from '../../LandDetails/Summary/CollapseList'

export const getBuildingInfosSummary = (
  building: any,
  handleClickProtectedBuilding: any,
) => {
  const { t } = useI18n()

  const data = [
    {
      label: t('land.financialAnalysis.buildingGroundArea'),
      value: building?.gross_floor
        ? `${toReadableNumber(building?.gross_floor)} m2`
        : '-',
      color: building?.gross_floor && '#00ade6',
    },
    {
      label: t('building.info.Surface reference energetique'),
      value: building?.energy_reference_area
        ? `${toReadableNumber(Math.round(building?.energy_reference_area))} m2`
        : '-',
    },
    {
      label: t('common.buildingVolume'),
      value: building?.volume_estimate
        ? `${toReadableNumber(building?.volume_estimate)} m3`
        : '-',
      color: building?.gross_floor && '#00ade6',
    },
    {
      label: t('building.info.Hauteur'),
      value:
        (building?.height_estimate &&
          `${toReadableNumber(Math.round(building.height_estimate * 100) / 100)} m`) ||
        '-',
      color: building?.gross_floor && '#00ade6',
    },
    {
      label: t('building.info.Etages'),
      value: building?.floor_nb || '-',
    },
    {
      label: t('land.existingScore.units'),
      value: building?.dwellings_nb || '-',
    },
  ]

  const data2 = [
    {
      label: t('building.info.EGID'),
      value: building?.egid || '-',
    },
    {
      label: t('building.info.Protection patrimoine'),
      value:
        building?.protected_rank > 0 ? (
          <Tooltip title={t('land.situationIcon.moreInfo')}>
            <IconButton
              sx={{ p: 0, height: 10 }}
              size="small"
              aria-label="Description"
              onClick={handleClickProtectedBuilding}
              style={{
                color: building
                  ? chroma(
                      getBuildingColor(
                        building?.protected_rank,
                        building?.classification,
                      ),
                    ).hex()
                  : '#000',
              }}
            >
              <WarningIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : (
          '-'
        ),
      color: chroma(
        getBuildingColor(building?.protected_rank, building?.classification),
      ).hex(),
    },
    {
      label: t('common.building.officialNumber'),
      value: building?.buildings_administrative?.[0]?.official_nb || '-',
    },
    {
      label: t('land.zone.name'),
      value: building?.buildings_administrative?.[0]?.name || '-',
    },
    {
      label: t('common.landRadarInfo.buildingYear'),
      value: building?.building_year || building?.building_period || '-',
    },
    {
      label: t('land.building.renovationYearSection'),
      value: building?.renovation_year || building?.renovation_period || '-',
    },
    {
      label: t('common.addresses'),
      value: (
        <CollapseList
          items={building?.addresses}
          moreKey="common.seeMoreAddresses"
        />
      ),
    },
  ]

  return [data, data2]
}
