import { getAddresses, i18n } from '@popety_io/popety-io-lib'
import proj4 from 'proj4'
import { HOST } from '../config'

proj4.defs([
  [
    'EPSG:2056',
    '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs',
  ],
  [
    'EPSG:3857',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs',
  ],
])

const getEstimatePublicLink = (data: {
  hash?: string
  currentLanguage: string
}) => {
  if (!data?.hash) return

  const { hash, currentLanguage } = data

  return window.open(`${HOST}/${currentLanguage}?hash=${hash}`)
}

const getRddfLandLink = (land: any, regionRdppfLink?: string) => {
  if (!regionRdppfLink) return

  const find = regionRdppfLink?.search('{egrid_nb}')

  return find
    ? regionRdppfLink?.replace('{egrid_nb}', land?.egrid_nb)
    : regionRdppfLink
        ?.replace('{city_ofs_number}', land.city_ofs_number)
        ?.replace('{code_number}', land.code_number)
}

const getGeoPortalLink = (land: any, regionGeoPortalLink: string) => {
  if (!regionGeoPortalLink && !land) return

  const find = regionGeoPortalLink?.search('{egrid_nb}')

  const regionGeoPortalEgrid =
    find !== -1
      ? regionGeoPortalLink?.replace('{egrid_nb}', land?.egrid_nb)
      : null

  const EPSG =
    regionGeoPortalLink?.search('{x_3857}') > 0 ? 'EPSG:3857' : 'EPSG:2056'
  const xToReplace = EPSG === 'EPSG:3857' ? '{x_3857}' : '{x}'
  const yToReplace = EPSG === 'EPSG:3857' ? '{y_3857}' : '{y}'

  const coordinates = land?.geo_center?.lon
    ? proj4('EPSG:4326', EPSG, [land?.geo_center?.lon, land?.geo_center?.lat])
    : undefined

  const x = coordinates ? coordinates[0] : undefined
  const y = coordinates ? coordinates[1] : undefined

  return (
    regionGeoPortalEgrid ||
    regionGeoPortalLink?.replaceAll(xToReplace, x)?.replaceAll(yToReplace, y)
  )
}

type GetPhoneBookLinks = {
  city?: string
  street?: string
  streetNb?: string | number
  fullAddress?: string | undefined
}

const getPhoneBookLinks = ({
  city,
  street,
  streetNb,
  fullAddress,
}: GetPhoneBookLinks) => {
  const query = `${street}+${streetNb ? `${streetNb}+` : ''}${city || ''}`

  return `https://tel.local.ch/fr/q?where=${fullAddress || query}`
}

const getGoogleMapLink = (lon: number, lat: number) => {
  return `https://www.google.com/maps/@${(
    lat - 0.0012
  ).toString()},${lon},155a,35y,39.34t/data=!3m1!1e3`
}

const getLandRegisterLink = (rfUri: string, egid?: string, region?: string) => {
  if (egid) {
    return `https://www.housing-stat.ch/${
      i18n.currentLanguage === 'fr' ? 'fr' : 'de'
    }/query/egid.html?egid=${egid}`
  }

  return region === 'Vaud' ? 'https://prestations.vd.ch/pub/101435/' : rfUri
}

const getExternalLinks = (
  data: Record<string, any>,
  region: Record<string, any>,
) => {
  let address = data

  address = getAddresses([...(data?.buildings || [data])])[0] as any

  const linkArr = [
    {
      text: 'Google Map',
      href: getGoogleMapLink(data?.geo_center?.lon, data?.geo_center?.lat),
      type: 'googleMap',
    },
    {
      text: data?.egid ? 'RegBL' : i18n.t('common.externallinks.landRegister'),
      href: getLandRegisterLink(data?.rf_uri, data?.egid, data?.region_name),
      type: 'rf',
    },
    {
      text: i18n.t('common.externallinks.geoportal'),
      href: getGeoPortalLink(data, region?.geo_portal_link),
      type: 'geoPortal',
    },
    {
      text: i18n.t('common.externallinks.cadastre'),
      href: getRddfLandLink(data, region?.rdppf_link),
      type: 'rdppf',
    },
  ]

  if (address) {
    const buildingAddress = (data?.buildings_administrative || [])[0]
      ?.addresses[0]
    const addressStreet = address.street || buildingAddress?.street
    const addressStreetNb = address.street_nb || buildingAddress?.street_nb
    const addressCity = address.city || buildingAddress?.city

    if (addressStreet || addressStreetNb || addressCity || data?.address_full) {
      linkArr.unshift({
        text: i18n.t('common.externallinks.phoneBook'),
        href: getPhoneBookLinks({
          street: addressStreet,
          streetNb: addressStreetNb,
          city: addressCity,
          fullAddress: data?.address_full,
        }),
        type: 'phoneBook',
      })
    }
  }

  return linkArr.filter((v: any) => v?.href) as any[]
}

export {
  getPhoneBookLinks,
  getGeoPortalLink,
  getGoogleMapLink,
  getLandRegisterLink,
  getRddfLandLink,
  getExternalLinks,
  getEstimatePublicLink,
}
