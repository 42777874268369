import Box from '@mui/material/Box'
import { LeftMenu, useRouter, useI18n } from '@popety_io/popety-io-lib'

import useAutoCloseSidebar from '../../../../hooks/useAutoCloseSidebar'
import { getAccountMenuData } from './AccountMenu.data'

const AccountMenu = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()

  const menus = getAccountMenuData(t)

  const { onCloseSidebar } = useAutoCloseSidebar()

  const data = menus.map(({ page: _, ...menu }, i) => ({
    ...menu,
    active: !query.tab ? i === 0 : query.tab === menu.key,
    onClick: () => {
      updateQuery({ tab: menu.key, selected: null })
      onCloseSidebar()
    },
  }))

  return (
    <Box
      width={107}
      height="100%"
      sx={{
        borderRight: query.tab === 'tags' ? '' : '1px solid #ebebeb',
      }}
    >
      <LeftMenu data={data as any} />
    </Box>
  )
}

export default AccountMenu
