import * as turf from '@turf/turf'

export const getEstimateBbox = (
  featureColletion: any,
  isBbox: boolean,
  geoCenter?: { lat: number; lon: number },
  isHistoryTab?: boolean,
  historyGeojson?: any,
) => {
  if (!geoCenter) return
  const padding = 0.04
  const geojson = isHistoryTab ? historyGeojson : featureColletion

  if (isBbox) {
    const envelope = geojson ? turf.envelope(geojson) : null
    const paddedBounds = envelope?.bbox?.map((element: any, index: number) => {
      return index < 2 ? element - padding : element + padding
    })

    return paddedBounds
  }

  const options = { steps: 64, units: 'kilometers', properties: {} }
  const circle = turf.circle(
    [geoCenter.lon, geoCenter.lat] as any,
    2.4,
    options as any,
  )

  const bbox = turf.bbox(circle?.geometry)

  return bbox
}
