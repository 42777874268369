/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-lines */
import React, { FC } from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import { useI18n } from '@popety_io/popety-io-lib'
import { HeatingLabelRoot } from './HeatingAndHotWater.style'
import HeatingValueField from './HeatingValueField'

const formatDate = (inputDate: string | undefined) => {
  if (!inputDate) return undefined
  const [year, month, day]: any = inputDate?.split('-') || inputDate?.split('/')

  if (!year || !month || !day) {
    return inputDate
  }
  const date = new Date(year, month - 1, day)

  const monthStr = String(date.getMonth() + 1).padStart(2, '0')
  const dayStr = String(date.getDate()).padStart(2, '0')

  const yearStr = date.getFullYear()

  return `${monthStr}/${dayStr}/${yearStr}`
}

export interface IHeatingAndHotWaterValues {
  heatGenerator: string
  energySource: string
  informationSource: string
  updatedYear: string
}
export interface IHeatingAndHotWaterProps {
  title: string
  isEdit: boolean
  onChangeValue: (name: string, value: string) => void
  onReset?: (name: string) => void
  onRetrieve?: (name: string) => void
  currentValues: IHeatingAndHotWaterValues
  usesEditedValues?: IHeatingAndHotWaterValues
  defaultValues?: IHeatingAndHotWaterValues
  isLoading: boolean
}
const HeatingAndHotWater: FC<IHeatingAndHotWaterProps> = ({
  title,
  usesEditedValues,
  isEdit,
  onChangeValue,
  onReset,
  onRetrieve,
  defaultValues,
  currentValues,
  isLoading,
}) => {
  const { t } = useI18n()

  const { energySource, heatGenerator, informationSource, updatedYear } =
    currentValues
  const energySourceEdited = usesEditedValues?.energySource
  const heatGeneratorEdited = usesEditedValues?.heatGenerator
  const informationSourceEdited = usesEditedValues?.informationSource
  const updatedYearEdited = usesEditedValues?.updatedYear

  const energySourceDefault = defaultValues?.energySource
  const heatGeneratorDefault = defaultValues?.heatGenerator
  const informationSourceDefault = defaultValues?.informationSource
  const updatedYearDefault = defaultValues?.updatedYear

  const onChange = (name: string) => (e: string) => {
    onChangeValue(name, e)
  }

  return (
    <Box>
      <Typography
        fontWeight={500}
        marginBottom="10px"
        color="#041725"
        fontSize="1rem"
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HeatingLabelRoot sx={{ flex: '0 0 200px' }}>
            {t('building.energy.heatGenerator')}
          </HeatingLabelRoot>
          {isLoading ? (
            <Skeleton variant="text" width={100} />
          ) : (
            <HeatingValueField
              value={heatGenerator}
              valueEdited={heatGeneratorEdited}
              valueDefault={heatGeneratorDefault}
              onChangeValue={onChange('heatGenerator')}
              onReset={() => onReset && onReset('heatGenerator')}
              onRetrieve={() => onRetrieve && onRetrieve('heatGenerator')}
              isEdit={isEdit}
            />
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HeatingLabelRoot sx={{ flex: '0 0 200px' }}>
            {t('building.energy.energySource')}
          </HeatingLabelRoot>
          {isLoading ? (
            <Skeleton variant="text" width={100} />
          ) : (
            <HeatingValueField
              value={energySource}
              valueEdited={energySourceEdited}
              valueDefault={energySourceDefault}
              onChangeValue={onChange('energySource')}
              onReset={() => onReset && onReset('energySource')}
              onRetrieve={() => onRetrieve && onRetrieve('energySource')}
              isEdit={isEdit}
            />
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HeatingLabelRoot sx={{ flex: '0 0 200px' }}>
            {t('building.energy.informationSource')}
          </HeatingLabelRoot>
          {isLoading ? (
            <Skeleton variant="text" width={100} />
          ) : (
            <HeatingValueField
              value={informationSource}
              valueEdited={informationSourceEdited}
              valueDefault={informationSourceDefault}
              onChangeValue={onChange('informationSource')}
              onReset={() => onReset && onReset('informationSource')}
              onRetrieve={() => onRetrieve && onRetrieve('informationSource')}
              isEdit={isEdit}
            />
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HeatingLabelRoot sx={{ flex: '0 0 200px' }}>
            {t('building.energy.dateUpdated')}
          </HeatingLabelRoot>
          {isLoading ? (
            <Skeleton variant="text" width={100} />
          ) : (
            <HeatingValueField
              value={formatDate(updatedYear)}
              valueEdited={formatDate(updatedYearEdited)}
              valueDefault={formatDate(updatedYearDefault)}
              onChangeValue={onChange('updatedYear')}
              onReset={() => onReset && onReset('updatedYear')}
              onRetrieve={() => onRetrieve && onRetrieve('updatedYear')}
              isEdit={isEdit}
              type="date"
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default HeatingAndHotWater
