/* eslint-disable max-lines */
import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box, { BoxProps } from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import {} from '../../../pages/ListingHistory/redux'

import { useI18n, format } from '@popety_io/popety-io-lib'
import {
  buyAppartmentDataSelector,
  rentAppartmentDataSelector,
  buyHouseDataSelector,
  rentHouseDataSelector,
  getListingRentAndBuyInsightsForAppartmentAndHouses,
} from '../redux'
import { StyledDivider } from '../LandPreview.style'
import { calculatePriceM2GrowthOverLast12Months } from '../../../utils'
import PricePercent from '../../../pages/ListingHistory/components/ListingAnalyticCard/PricePercent'
import ListingContent from './ListingContent'
import { DivContainer, StyledSpan } from './Listings.style'

export type ListingsProps = {
  sx?: BoxProps['sx']
  data?: {
    id?: string
    lands?: any[]
    buildings?: any[]
    city: string
    egid?: string
  } & Record<string, any> &
    BoxProps
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Listings = ({ data, ...other }: ListingsProps) => {
  const { t } = useI18n()

  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const id = data?.id
  const isGrouped = Array.isArray(id)

  const buyAppartmentData: Record<string, any>[] = useSelector(
    buyAppartmentDataSelector,
  )

  const rentAppartmentData: Record<string, any>[] = useSelector(
    rentAppartmentDataSelector,
  )

  const buyHouseData: Record<string, any>[] = useSelector(buyHouseDataSelector)

  const rentHouseData: Record<string, any>[] = useSelector(
    rentHouseDataSelector,
  )

  const interval = 'Q'

  const getXaxis = (data: Record<string, any>) => {
    const intervalText = t('common.quarter', {
      count: +format(data.date, interval),
    })

    return `${intervalText} ${new Date(data.date).getFullYear()}`
  }

  const buyAppartment = useMemo(
    () => buyAppartmentData?.map((d) => ({ ...d, x: getXaxis(d) })),

    [buyAppartmentData],
  )

  const rentAppartment = useMemo(
    () => rentAppartmentData?.map((d) => ({ ...d, x: getXaxis(d) })),

    [rentAppartmentData],
  )

  const buyHouse = useMemo(
    () => buyHouseData?.map((d) => ({ ...d, x: getXaxis(d) })),

    [buyAppartmentData],
  )

  const rentHouse = useMemo(
    () => rentHouseData?.map((d) => ({ ...d, x: getXaxis(d) })),

    [rentAppartmentData],
  )

  const allFeature = data?.egid ? data?.buildings : data?.lands

  const city = useMemo(() => {
    return (
      (isGrouped
        ? allFeature?.map((d) => d?.city_name)
        : [data?.city || data?.city_name]
      )?.filter(Boolean) || []
    )
  }, [allFeature, data?.city, data?.city_name, isGrouped])

  const dispatch = useDispatch<any>()

  useEffect(() => {
    if (!city?.length) return

    dispatch(getListingRentAndBuyInsightsForAppartmentAndHouses({ city }))
  }, [city?.[0]])

  if (
    !buyAppartmentData ||
    !rentAppartmentData ||
    !buyAppartmentData ||
    !rentHouseData
  )
    return null

  return (
    <Box {...other}>
      <StyledDivider />
      <DivContainer>
        <StyledSpan>{t('common.details.listings')}</StyledSpan>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} variant="fullWidth">
              <Tab label={t('common.Apartment')} {...a11yProps(0)} />
              <Tab label={t('common.House')} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <ListingContent
            city={city}
            propertyCategory={value === 0 ? 'Apartment' : 'House'}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <PricePercent
                  label=""
                  score={
                    calculatePriceM2GrowthOverLast12Months(
                      value === 0 ? buyAppartment : buyHouse,
                    ).percent || 0
                  }
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  <span
                    style={{
                      fontSize: '0.7rem',
                      paddingLeft: 5,
                      color: 'rgb(207, 207, 207)',
                    }}
                  >
                    / {t('common.listing.an')}
                  </span>
                </PricePercent>
              </Box>
              <Box>
                <PricePercent
                  label=""
                  score={
                    calculatePriceM2GrowthOverLast12Months(
                      value === 0 ? rentAppartment : rentHouse,
                    ).percent || 0
                  }
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  <span
                    style={{
                      fontSize: '0.7rem',
                      paddingLeft: 5,
                      color: 'rgb(207, 207, 207)',
                    }}
                  >
                    / {t('common.listing.an')}
                  </span>
                </PricePercent>
              </Box>
            </Box>
          </ListingContent>
        </Box>
      </DivContainer>
    </Box>
  )
}

export default Listings
