/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { parseGeoJSON } from '@popety_io/popety-io-lib'

import summaryService from './summaryService'

export const summaryMapLayers = [
  'land',
  'authorizedLimit',
  'restrictions',
  'buildings',
]

const initialState = {
  loading: false,
  loadEstimation: false,
  imagesAreLoading: false,
  estimate: {},
  selectedLayers: summaryMapLayers,
  cityGeoCenter: {},
  cityGeojson: {},
  estimateEdited: {},
  dwellingSelected: {},
  pricehubble: {},
  loadEdited: false,
  loadSavePublicEstimate: false,
  estimateHistory: [],
  loadHistory: false,
  landBuildings: [],
  loadLandBuildings: false,
}

export const getEstimateSummary = createAsyncThunk(
  'summary/getEstimateSummary',
  summaryService.getEstimateSummary,
)

export const getCityGeojson = createAsyncThunk(
  'summary/getCityGeojson',
  summaryService.getCityGeojson,
)

export const submitEstimation = createAsyncThunk(
  'summary/submitEstimation',
  summaryService.submitEstimation,
)

export const savePublicEstimateSummary = createAsyncThunk(
  'estimateLandSummary/savePublicEstimateSummary',
  summaryService.savePublicEstimateSummary,
)

export const getPublicEditedEstimate = createAsyncThunk(
  'estimateLandSummary/getPublicEditedEstimate',
  summaryService.getPublicEditedEstimate,
)

export const saveEditedEstimate = createAsyncThunk(
  'estimateLandSummary/saveEditedEstimate',
  summaryService.saveEditedEstimate,
)

export const getDwellingDetails = createAsyncThunk(
  'summary/getDwellingDetails',
  summaryService.getDwellingDetails,
)

export const saveEstimatePicture = createAsyncThunk(
  'summary/saveEstimatePicure',
  summaryService.saveEstimatePicture,
)

export const deleteEstimatePicture = createAsyncThunk(
  'summary/deleteEstimatePicture',
  summaryService.deleteEstimatePicture,
)

export const getPublicHashEstimate = createAsyncThunk(
  'summary/getPublicHashEstimate',
  summaryService.getPublicHashEstimate,
)

export const savePublicEstimateObject = createAsyncThunk(
  'summary/savePublicEstimateObject',
  summaryService.savePublicEstimateObject,
)

export const getEstimateHistory = createAsyncThunk(
  'summary/getEstimateHistory',
  summaryService.getEstimateHistory,
)

export const deleteEstimateHistory = createAsyncThunk(
  'summary/deleteEstimateHistory',
  summaryService.deleteEstimateHistory,
)

export const archiveEstimateHistory = createAsyncThunk(
  'summary/archiveEstimateHistory',
  summaryService.archiveEstimateHistory,
)

export const getLandBuildings = createAsyncThunk(
  'summary/getLandBuildings',
  summaryService.getLandBuildings,
)

export const getBuildingsByAddressId = createAsyncThunk(
  'summary/getBuildingsByAddressId',
  summaryService.getBuildingsByAddressId,
)

const estimateSummary = createSlice({
  name: 'estimateSummary',
  initialState,
  reducers: {
    updateEstimate: (state, action) => {
      state.estimate = { ...state.estimate, ...action.payload }
    },
    clearEstimate: (state) => {
      state.estimate = {}
      state.estimateEdited = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEstimateSummary.pending, (state) => {
        state.loading = true
      })
      .addCase(getEstimateSummary.rejected, (state) => {
        state.loading = false
        state.estimate = {}
      })
      .addCase(getEstimateSummary.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const { arg } = action.meta || {}

        if (arg?.estimateId) {
          state.estimate.estimateId = arg?.estimateId
        }

        state.estimate = { ...state.estimate, ...data }
        state.loading = false
      })
      .addCase(getCityGeojson.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.cityGeoCenter = parseGeoJSON(data?.[0]?.geojson_center)
        state.cityGeojson = parseGeoJSON(data?.[0]?.geojson_polygon)
      })
      .addCase(submitEstimation.pending, (state) => {
        state.loadEstimation = true
      })
      .addCase(submitEstimation.rejected, (state) => {
        state.loadEstimation = false
      })
      .addCase(submitEstimation.fulfilled, (state, action) => {
        state.loadEstimation = false
        const { data } = action.payload || {}

        state.estimate = { ...state.estimate, ...data }
      })
      .addCase(savePublicEstimateSummary.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.estimate.estimateId = data?.id

        if (data?.value) {
          state.estimate.share = {
            ...data?.value,
            expiration_date: data?.expiration_date,
          }
        }
      })
      .addCase(getPublicEditedEstimate.pending, (state) => {
        state.loadEdited = true
      })
      .addCase(getPublicEditedEstimate.rejected, (state) => {
        state.loadEdited = false
      })
      .addCase(getPublicEditedEstimate.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.loadEdited = false
        state.estimateEdited = data
      })
      .addCase(saveEditedEstimate.pending, (state) => {
        state.loadEdited = true
        state.loading = true
      })
      .addCase(saveEditedEstimate.fulfilled, (state, action) => {
        const data = action.meta.arg

        state.estimateEdited = data?.body
        state.loadEdited = false
        state.loading = false
      })
      .addCase(saveEditedEstimate.rejected, (state) => {
        state.loadEdited = false
        state.loading = false
      })
      .addCase(getDwellingDetails.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.dwellingSelected = data
      })
      .addCase(saveEstimatePicture.pending, (state) => {
        state.imagesAreLoading = true
      })
      .addCase(saveEstimatePicture.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.estimateEdited = data?.data
        state.imagesAreLoading = false
      })
      .addCase(saveEstimatePicture.rejected, (state) => {
        state.imagesAreLoading = false
      })
      .addCase(deleteEstimatePicture.pending, (state) => {
        state.imagesAreLoading = true
      })
      .addCase(deleteEstimatePicture.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.estimateEdited = data?.data
        state.imagesAreLoading = false
      })
      .addCase(deleteEstimatePicture.rejected, (state) => {
        state.imagesAreLoading = false
      })
      .addCase(getPublicHashEstimate.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.estimate.hash = data.hash

        if (data?.expiration_date) {
          state.estimate.share = {
            expiration_date: data?.expiration_date,
          }
        }
      })
      .addCase(savePublicEstimateObject.pending, (state) => {
        state.loadSavePublicEstimate = true
      })
      .addCase(savePublicEstimateObject.rejected, (state) => {
        state.loadSavePublicEstimate = false
      })
      .addCase(savePublicEstimateObject.fulfilled, (state, action) => {
        state.loadSavePublicEstimate = false
        const { data } = action.payload || {}

        state.estimate.share = {
          expiration_date: data?.expiration_date,
        }
      })
      .addCase(getEstimateHistory.pending, (state) => {
        state.loadHistory = true
      })
      .addCase(getEstimateHistory.rejected, (state) => {
        state.loadHistory = false
      })
      .addCase(getEstimateHistory.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.loadHistory = false
        state.estimateHistory = data
      })
      .addCase(deleteEstimateHistory.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.estimateHistory = state.estimateHistory.filter(
          (item) => item.id !== data.id,
        )
      })
      .addCase(archiveEstimateHistory.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.estimateHistory = state.estimateHistory.map((item) => {
          if (item.id === data.id) {
            return { ...item, archive: data.archive }
          }

          return item
        })
      })
      .addCase(getLandBuildings.pending, (state) => {
        state.loadLandBuildings = true
      })
      .addCase(getLandBuildings.rejected, (state) => {
        state.loadLandBuildings = false
      })
      .addCase(getLandBuildings.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.loadLandBuildings = false
        state.landBuildings = data?.buildings || []
      })
  },
})

export const estimateSummaryReducer = {
  estimateSummary: estimateSummary.reducer,
}

export const { updateEstimate, clearEstimate } = estimateSummary.actions
