/* eslint-disable max-lines */
import { useState, useEffect, useMemo } from 'react'
import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Skeleton,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import TablePagination from '@mui/material/TablePagination'

import { useDispatch, useSelector } from 'react-redux'
import { fetchOnce, useI18n, useRouter } from '@popety_io/popety-io-lib'
import BuildingHistoryContainer from '../BuildingHistoryContainer'
import {
  buildingAnalysisHistorySelector,
  getBuildingAnalysisHistory,
  loadBuildingAnalysisHistorySelector,
} from '../../redux'

interface Building {
  archived: boolean
}

const BuildingHistory: React.FC = () => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const { query, updateQuery } = useRouter()
  const buildingAnalysisHistory = useSelector(buildingAnalysisHistorySelector)
  const loading = useSelector(loadBuildingAnalysisHistorySelector)
  const [value, setValue] = useState('')
  const { page = 0, size = 25 } = query
  const isArchived = query?.archived === 'true'
  const isActived = query?.active === 'true'

  useEffect(() => {
    updateQuery({ active: true, archived: false })
  }, [query.tab])

  useEffect(() => {
    fetchOnce('getBuildingAnalysisHistory', null, () => {
      dispatch(getBuildingAnalysisHistory() as any)
    })
  }, [])

  const handleChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      updateQuery({ [name]: event.target.checked, page: 0 })
    }

  const history = useMemo(() => {
    let items = buildingAnalysisHistory

    if (value) {
      items = buildingAnalysisHistory?.filter((item: any) => {
        const decentValue = value
          ?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          ?.toLowerCase()
          ?.trim()

        const isAddress = item?.addresses?.some((address: any) => {
          const filterString = address || ''
          const decentFilterString = filterString
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            ?.toLowerCase()
            ?.trim()

          return (
            filterString?.toLowerCase()?.includes(value?.toLowerCase()) ||
            filterString?.toLowerCase()?.includes(value?.toLowerCase()) ||
            decentFilterString?.includes(value) ||
            decentFilterString?.includes(decentValue)
          )
        })
        const filterMunicipalityString = item?.municipality_name || ''
        const decentFilterString = filterMunicipalityString
          ?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          ?.toLowerCase()
          ?.trim()

        const isMunicipality =
          filterMunicipalityString
            ?.toLowerCase()
            ?.includes(value?.toLowerCase()) ||
          filterMunicipalityString
            ?.toLowerCase()
            ?.includes(value?.toLowerCase()) ||
          decentFilterString?.includes(value) ||
          decentFilterString?.includes(decentValue)

        return isAddress || isMunicipality
      })
    }

    if (!isActived && !isArchived) {
      return []
    }

    return items.filter((building: Building) => {
      return (
        (isActived && !building?.archived) || (isArchived && building?.archived)
      )
    })
  }, [buildingAnalysisHistory, isActived, isArchived, value])

  const pagedResult = useMemo(
    () => history?.slice(+page * +size, (+page + 1) * +size) || [],
    [history, page, size],
  )
  const hasPage = useMemo(
    () => pagedResult?.length !== history?.length,
    [pagedResult, history],
  )

  const handleChangePage = (e: any, newPage: number) => {
    updateQuery({ page: newPage })
  }

  const handleChangePageSize = (event: any) => {
    updateQuery({ size: +event.target.value, page: 0 })
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          background: '#fff',
          width: 'calc(100% + 20px)',
          padding: ' 0 10px',
          marginLeft: '-10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              marginBottom: '4px',
              textTransform: 'uppercase',
            }}
            fontSize="1.5rem"
            fontWeight={500}
          >
            {t('common.Analyse détaillée de bâtiment')}
          </Typography>
          <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => handleChange('active')(e)}
                  checked={isActived}
                />
              }
              label={t('common.Actifs')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isArchived}
                  onChange={(e) => handleChange('archived')(e)}
                />
              }
              label={t('common.Archivés')}
            />
          </FormGroup>
        </Box>
        <TextField
          variant="outlined"
          label={`${t('common.Entrer une commune, une adresse')}`}
          placeholder={`${t('common.Entrer une commune, une adresse')}...`}
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        {hasPage && (
          <TablePagination
            component="div"
            align="left"
            count={history?.length}
            page={+page}
            onPageChange={handleChangePage}
            rowsPerPage={+size}
            onRowsPerPageChange={handleChangePageSize}
            labelRowsPerPage={t('common.rowsPerPage')}
          />
        )}
      </Box>

      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          paddingBottom: '20px',
        }}
      >
        {!loading ? (
          pagedResult?.map((element: any) => (
            <BuildingHistoryContainer
              key={element?.id || ''}
              element={element}
            />
          ))
        ) : (
          <div>
            {Array.from({ length: 10 }).map((_: any, idx: number) => (
              <Skeleton
                key={idx}
                variant="rectangular"
                width="100%"
                height="110px"
                sx={{
                  borderRadius: '2px',
                  marginBottom: '12px',
                }}
              />
            ))}
          </div>
        )}
        {hasPage && (
          <TablePagination
            component="div"
            align="left"
            count={history?.length}
            page={+page}
            onPageChange={handleChangePage}
            rowsPerPage={+size}
            onRowsPerPageChange={handleChangePageSize}
            labelRowsPerPage={t('common.rowsPerPage')}
          />
        )}
      </Box>
    </Box>
  )
}

export default BuildingHistory
