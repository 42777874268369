/* eslint-disable max-lines */
import {
  calculateUnderExploitedScore,
  calculateDevelopmentScore,
  calculateLegacyScore,
} from '../../../../utils'

import {
  calculateSb,
  calculateSdb,
  type,
  classification,
  spdSbpuType,
  calculateSpdSbpu,
  calculateSp,
  categories,
  calculateVbr,
} from '../building/building.utils'

import { calculateBuildingsGroupedAnalytics } from '../underExploited/underExploited.utils'

export const recalculateUnderexploitedScore = (state, action) => {
  const { lup, land, landIndice } = action.payload

  const indiceUsed = landIndice?.split('_')?.[0]
  const indiceIsOverrided =
    landIndice?.endsWith('_overide') || landIndice?.endsWith('Override')

  // If any value used for calculate the score is overrided
  // we recalculate the under exploited score and the development score
  const isOverrided =
    indiceIsOverrided ||
    !!land?.sbOverride ||
    !!land?.sbdOverride ||
    !!land?.sbpuOverride ||
    !!land?.spdOverride ||
    !!land?.spOverride

  if (isOverrided) {
    // We get the authorized indices. The overrided value or the official value or the value estimated by us
    const authorizedValueOverrided =
      lup?.[`${indiceUsed}_overide`]?.value || lup?.[`${indiceUsed}Override`]

    // We get the authorized indices. The overrided value or the official value or the value estimated by us
    // const authorizedOccupation = lup?.[`${occupation}_overide`]?.value

    // We check if a current value have been overrided
    const currentIndiceOverrided = land?.[`current_${indiceUsed}Override`]

    // Here we select the authorized value used to calculate the score
    const authorized = authorizedValueOverrided || lup?.[land?.indice_used]

    // Here we select the current value used to calculate the score
    // It's sorted by priority
    const current = currentIndiceOverrided || state.land?.[indiceUsed]

    const underExploitedScoreOveride = calculateUnderExploitedScore(
      land?.std,
      current,
      authorized,
    )

    const developmentScoreOveride = calculateDevelopmentScore(
      underExploitedScoreOveride,
      land?.geo_shape_score,
      land?.existing_score,
      land?.livability_score,
    )

    state.land.underExploitedScoreOveride = underExploitedScoreOveride
    state.land.developmentScoreOveride = developmentScoreOveride
  } else {
    state.land.underExploitedScoreOveride = null
    state.land.developmentScoreOveride = calculateDevelopmentScore(
      land?.under_exploited_score,
      land?.geo_shape_score,
      land?.existing_score,
      land?.livability_score,
    )
  }
}

export const handleBuildings = (state, action) => {
  const data = action.payload

  if (data?.buildings?.length) {
    const areaEdited = data.buildings?.find((building) => building.areaOveride)

    if (areaEdited) {
      const sdbRecalculate = calculateSdb(
        data.buildings
          .filter((building) => !type.includes(building.building_type))
          .filter(
            (building) =>
              !classification.includes(
                building.classification || !building.classification,
              ),
          ),
      )

      const sbRecalculate = calculateSb(
        data.buildings.filter(
          (building) =>
            !classification.includes(
              building.classification || !building.classification,
            ),
        ),
      )

      state.land.sbOverride = sbRecalculate
      state.land.current_cosOverride = (sbRecalculate / state.land.std).toFixed(
        2,
      )
      state.land.current_iosOverride = (
        sdbRecalculate / state.land.std
      ).toFixed(2)
      state.land.sbdOverride = sdbRecalculate
    } else {
      state.land.sbOverride = null
      state.land.current_cosOverride = null
      state.land.sbdOverride = null
      state.land.current_iosOverride = null
    }

    const floorEdited = data?.buildings?.find(
      (building) =>
        !!building.floorAreaOveride ||
        !!building.areaOveride ||
        !!building.floorNbOveride,
    )

    if (floorEdited) {
      const spRecalculate = Math.round(
        calculateSp(
          data.buildings
            .filter(
              (building) =>
                !['Habitation provisoire'].includes(building.building_type),
            )
            .filter(
              (building) => !['projected'].includes(building.classification),
            ),
        ),
      )

      const sbpuSpdRecalculate = Math.round(
        calculateSpdSbpu(
          data.buildings
            .filter((building) => !spdSbpuType.includes(building.building_type))
            .filter((building) => !categories.includes(building.category))
            .filter(
              (building) => !['projected'].includes(building.classification),
            ),
        ),
      )

      const sbpuSp = Math.round(sbpuSpdRecalculate)

      state.land.spOverride = spRecalculate
      state.land.current_ibusOverride = (
        spRecalculate / state.land.std
      ).toFixed(2)
      state.land.current_iusOverride = (sbpuSp / state.land.std).toFixed(2)
      state.land.current_cusOverride = (sbpuSp / state.land.std).toFixed(2)
      state.land.sbpuOverride = sbpuSp
      state.land.spdOverride = sbpuSp
    } else {
      state.land.spOverride = null
      state.land.sbpuOverride = null
      state.land.spdOverride = null
      state.land.current_ibusOverride = null
      state.land.current_iusOverride = null
      state.land.current_cusOverride = null
    }

    const vbrEdited = data?.buildings?.find((building) => !!building.vbrOveride)

    if (vbrEdited) {
      const vbrRecalculate = Math.round(calculateVbr(data.buildings))

      state.land.vbrOverride = vbrRecalculate
      state.land.current_imOverride = (vbrRecalculate / state.land.std).toFixed(
        2,
      )
    } else {
      state.land.vbrOverride = null
      state.land.current_imOverride = null
    }

    state.land = {
      ...state.land,
      ...calculateBuildingsGroupedAnalytics(
        data.buildings,
        state.land.average_protected_rank,
        state.land.average_condition_year,
      ),
    }

    state.land.existing_score = calculateLegacyScore(
      state.land.average_protected_rank,
      state.land.average_condition_year,
      state.land.official_area || state.land.area,
      state.land.ios,
      state.land.ios,
    )

    state.land.developmentScoreOveride = calculateDevelopmentScore(
      state.land.underExploitedScoreOveride || state.land.under_exploited_score,
      state.land.geo_shape_score,
      state.land.existing_score,
      state.land.livability_score,
    )
  }
}
