import {
  sum,
  useI18n,
  toReadableNumber,
  InfoBox,
} from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'

import { buildingsSelector, landSelector } from '../redux'

const BuildingInfo = () => {
  const { t } = useI18n()
  const buildings = useSelector(buildingsSelector)
  const land: any = useSelector(landSelector)

  const maxFloor = Math.max(...(buildings?.map((b) => b.floor_nb || 0) || []))

  const maxHeight =
    Number(land?.max_height) ||
    Math.max(...(buildings?.map((b) => b.height || 0) || []))

  const groundArea = sum(
    buildings?.map((b) => b.areaOveride ?? (b.area || 0)) || [],
  )

  const floorArea = sum(
    buildings?.map((b) => b.floorAreaOveride ?? b.area * (b?.floor_nb || 1)) ||
      [],
  )

  const data = [
    { label: t('land.zone.nomber'), value: land.building_nb || '-' },
    {
      label: t('common.buildingYear'),
      value: land.average_building_year || '-',
    },
    {
      label: t('land.underExploited.floor'),
      value: Number.isInteger(maxFloor) ? maxFloor : '-',
    },
    {
      label: t('land.underExploited.height'),
      value: `${land.max_height} m` || '-',
    },
  ]

  const data2 = [
    {
      label: t('land.groundArea.total'),
      value:
        Number.isInteger(groundArea) && groundArea
          ? `${toReadableNumber(groundArea)} m2`
          : '-',
    },
    {
      label: t('land.floorArea.total'),
      value:
        Number.isInteger(floorArea) && floorArea
          ? `${toReadableNumber(floorArea)} m2`
          : '-',
    },
    {
      label: t('land.building.mass'),
      value: land.im ? `${toReadableNumber(land.im)}` : '-',
    },
    {
      label: t('common.buildingVolume'),
      value: land.vbr ? `${toReadableNumber(land.vbr)} m3` : '-',
    },
  ]

  return (
    <InfoBox
      title={t('land.building')}
      data={data}
      data2={data2}
      id="land-building-info"
      sx={{
        '& .MuiGrid-grid-xs-7:nth-child(8)': {
          color: '#00ade6',
        },
        '& .MuiGrid-grid-xs-7:nth-child(10)': {
          color: '#00ade6',
        },
        '& .MuiGrid-grid-xs-7:nth-child(12)': {
          color: '#00ade6',
        },
        '& .MuiGrid-grid-xs-7:nth-child(14)': {
          color: '#00ade6',
        },
        '& .MuiGrid-grid-xs-12:nth-child(2)': {
          color: '#00ade6',
        },
        '& .MuiGrid-grid-xs-12:nth-child(6)': {
          color: '#00ade6',
        },
        '& .MuiGrid-grid-xs-12:nth-child(10)': {
          color: '#00ade6',
        },
      }}
    />
  )
}

export default BuildingInfo
