/* eslint-disable max-lines */
import { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import SaveIcon from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close'
import {
  useI18n,
  ResetIcon,
  useRouter,
  TypeIcon,
  LivingSpaceIcon,
  VolumeIcon,
  ConstructionYearIcon,
  PieceIcon,
  BathroomIcon,
  ParkingIcon,
  GagrageIcon,
  EtatIcon,
  BalconIcon,
  TerrasseIcon,
  RenovYearIcon,
} from '@popety_io/popety-io-lib'
import EditIcon from '@mui/icons-material/Edit'
import { useDispatch, useSelector } from 'react-redux'
import {
  dwellingSelectedSelector,
  estimateEditedSelector,
  estimateIdSelector,
  saveEditedEstimate,
} from '../../redux'
import { ValueField } from '../../../../components/ValueField'
import { SelectField } from '../../../../components'

const EstimationDetails = () => {
  const { t } = useI18n()
  const { query } = useRouter()
  const dispatch = useDispatch()
  const estimateId = useSelector(estimateIdSelector)
  const estimateEdited: any = useSelector(estimateEditedSelector)
  const dwelling = useSelector(dwellingSelectedSelector)
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData]: any = useState({})

  const defaultData = useMemo(
    () => ({
      type: query?.propertyType || 'apartment',
      area: query.livingArea || dwelling?.area || undefined,
      volume: undefined,
      construction_date:
        query?.constructionYear || dwelling?.construction_date || undefined,
      rooms: query.roomsNb || dwelling?.room_nb || undefined,
      baths: query.numberOfBathrooms || dwelling?.bathroom_nb || undefined,
      parking: undefined,
      garage: undefined,
      condition: query.condition || dwelling?.condition || undefined,
      balcon: undefined,
      terrasse: undefined,
      renovation_year:
        query.renovationYear || dwelling?.renovation_year || undefined,
    }),
    [query, dwelling],
  )

  useEffect(() => {
    setData({
      ...defaultData,
      type: estimateEdited?.type || defaultData.type,
      area: estimateEdited?.area || defaultData.area,
      volume: estimateEdited?.volume || defaultData.volume,
      construction_date:
        estimateEdited?.construction_date || defaultData.construction_date,
      rooms:
        estimateEdited?.rooms || defaultData.rooms || query.numberOfBathrooms,
      parking: estimateEdited?.parking || defaultData.parking,
      garage: estimateEdited?.garage || defaultData.garage,
      condition:
        query.condition || estimateEdited?.condition || defaultData.condition,
      balcon: estimateEdited?.balcon || defaultData.balcon,
      terrasse: estimateEdited?.terrasse || defaultData.terrasse,
      renovation_year:
        query.renovationYear ||
        estimateEdited?.renovation_year ||
        defaultData.renovation_year,
      baths: estimateEdited?.baths || defaultData.baths,
    })
  }, [dwelling, estimateEdited])

  const data1 = [
    {
      label: t('common.type'),
      name: 'type',
      value: t(`common.${data.type}`),
      icon: (
        <TypeIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
      labelValue: data.type
        ? data.type.charAt(0).toUpperCase() + data.type.slice(1)
        : '',
      options: [
        {
          label: t('common.apartment'),
          value: 'Apartment',
        },
        {
          label: t('common.house'),
          value: 'House',
        },
      ],
    },
    {
      label: t('financial.Surface habitable'),
      name: 'area',
      value: data.area,
      endAdornment: ' m2',
      icon: (
        <LivingSpaceIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      label: t('building.info.Volume'),
      name: 'volume',
      endAdornment: data.volume && ' m3',
      value: data.volume,
      icon: (
        <VolumeIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      label: t('common.landRadarInfo.buildingYear'),
      name: 'construction_date',
      value: data?.construction_date,
      icon: (
        <ConstructionYearIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
  ]

  const data2 = [
    {
      label: t('common.rooms'),
      name: 'rooms',
      value: data.rooms || '',
      icon: (
        <PieceIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      label: t('common.Bathrooms'),
      name: 'baths',
      value: data.baths,
      icon: (
        <BathroomIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      label: t('common.Place parking'),
      name: 'parking',
      value: data.parking,
      icon: (
        <ParkingIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      label: t('common.Place garage'),
      name: 'garage',
      value: data.garage,
      icon: (
        <GagrageIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
  ]

  const data3 = [
    {
      name: 'condition',
      label: t('common.Etat'),
      value: data.condition ? t(`common.${data.condition}`) : '',
      icon: (
        <EtatIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
      labelValue: data.condition,
      options: [
        {
          label: t('common.renovation_needed'),
          value: 'renovation_needed',
        },
        { label: t('common.well_maintained'), value: 'well_maintained' },
        {
          label: t('common.new_or_recently_renovated'),
          value: 'new_or_recently_renovated',
        },
      ],
    },
    {
      name: 'balcon',
      label: t('common.Balcon'),
      value: data.balcon,
      icon: (
        <BalconIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      name: 'terrasse',
      label: t('common.Terrasse'),
      value: data.terrasse,
      icon: (
        <TerrasseIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      name: 'renovation_year',
      label: t('search.renovationYearSection'),
      value: data.renovation_year,
      icon: (
        <RenovYearIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
  ]

  const onChange = (name: string) => (value: string) => {
    setData((pre: any) => ({
      ...(pre || {}),
      [name]: value || '',
    }))
  }

  const onChangeSelect =
    (name: string) =>
    ({ value }: { value: string }) => {
      setData((pre: any) => ({
        ...(pre || {}),
        [name]: value || '',
      }))
    }

  const onSave = () => {
    dispatch(
      saveEditedEstimate({
        id: estimateId,
        body: { ...estimateEdited, ...data },
      }) as any,
    )
    setIsEdit(false)
  }

  const onReset = () => {
    setData(defaultData)
  }

  const onClose = () => {
    setData(defaultData)
    setIsEdit(false)
  }

  return (
    <Box
      sx={{
        marginTop: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '10px',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: '4px',
            fontSize: '1.1rem',
            textTransform: 'uppercase',
          }}
        >
          {t('estimate.details du bien')}
        </Typography>
        {!isEdit ? (
          <IconButton
            aria-label="alarm"
            sx={{
              color: '#969696',
              padding: '0',
            }}
            onClick={() => setIsEdit(true)}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <div>
            <IconButton
              aria-label="alarm"
              sx={{
                color: '#969696',
                padding: '0',
              }}
              onClick={onReset}
            >
              <ResetIcon />
            </IconButton>
            <IconButton
              aria-label="alarm"
              sx={{
                color: '#969696',
                padding: '0',
              }}
              onClick={onSave}
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              aria-label="alarm"
              sx={{
                color: '#969696',
                padding: '0',
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          {data1.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                marginBottom: '4px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item sm={7}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      color: '#969696',
                      fontSize: '0.9rem',
                      justifyContent: 'start',
                      alignItems: 'center',
                    }}
                  >
                    {item.icon}
                    <Typography
                      fontWeight="500"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={5}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {!item.options ? (
                    <ValueField
                      value={item.value}
                      valueDefault={item.value}
                      onChangeValue={(v: any) => onChange(item.name)(v)}
                      isEdit={isEdit}
                      endAdornment={item.value ? item?.endAdornment : ''}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                        fontWeight: '500',
                      }}
                    />
                  ) : (
                    <SelectField
                      labelValue={item.labelValue}
                      value={item.value}
                      valueDefault={item.value}
                      options={item.options}
                      onChangeValue={(v: any) => onChangeSelect(item.name)(v)}
                      isEdit={isEdit}
                      endAdornment={item.value ? item?.endAdornment : ''}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                        fontWeight: '500',
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          {data2.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '4px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item sm={7}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      color: '#969696',
                      justifyContent: 'start',
                      alignItems: 'center',
                    }}
                  >
                    {item.icon}
                    <Typography
                      fontWeight="500"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={5}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ValueField
                    value={item.value}
                    valueDefault={item.value}
                    onChangeValue={(v: any) => onChange(item.name)(v)}
                    isEdit={isEdit}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      fontWeight: '500',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          {data3.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '4px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item sm={7}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      color: '#969696',
                      justifyContent: 'start',
                      alignItems: 'center',
                    }}
                  >
                    {item.icon}
                    <Typography
                      fontWeight="500"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={5}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {!item.options ? (
                    <ValueField
                      value={item.value}
                      valueDefault={item.value}
                      onChangeValue={(v: any) => onChange(item.name)(v)}
                      isEdit={isEdit}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                        fontWeight: '500',
                      }}
                    />
                  ) : (
                    <SelectField
                      labelValue={item.labelValue}
                      value={item.value}
                      valueDefault={item.value}
                      options={item.options}
                      onChangeValue={(v: any) => onChangeSelect(item.name)(v)}
                      isEdit={isEdit}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                        fontWeight: '500',
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default EstimationDetails
