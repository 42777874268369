import Grid from '@mui/material/Grid'
import { Fragment } from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import OkButton from '@mui/icons-material/CheckCircleOutline'
import NotOkButton from '@mui/icons-material/RemoveCircleOutline'
import { useI18n, toReadableNumber } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'

import { InfoBoxRoot } from '../../../components/InfoBox/InfoBox.style'
import { landsSelector, buildingSelector } from '../redux'

const Parcelle = () => {
  const { t } = useI18n()
  const lands = useSelector(landsSelector)
  const building = useSelector(buildingSelector)

  const land = lands?.[0] || {}

  const data = [
    { label: t('common.EGRID'), value: land?.egrid_nb || '-' },
    {
      label: t('land.zone.number'),
      value:
        land.city_name && land.code_number
          ? `${land.city_name} ${land.code_number}`
          : '-',
    },
    {
      label: t('land.underExploited.buildingArea'),
      value: land?.building_area ? (
        <OkButton
          style={{ color: 'rgb(142, 209, 165)' }}
          fontSize="small"
          className="iconOk"
        />
      ) : (
        <NotOkButton color="error" fontSize="small" className="iconNotOk" />
      ),
    },
    {
      label: t('common.landRadarInfo.area'),
      value: land?.area ? `${toReadableNumber(land?.area)} m2` : '-',
    },
  ]

  const data2 = [
    {
      label: t('building.plot.Zone de dénom cantonal'),
      value: building?.principal_type || '-',
    },
    {
      label: t("building.plot.Zone d'affection(s)"),
      value: building?.lupa_name || '-',
    },
  ]

  return (
    <InfoBoxRoot pb={0}>
      <Typography variant="h6">{t('common.land')?.toUpperCase()}</Typography>

      <Box className="ContentBox">
        <Grid container spacing={{ xs: 1, sm: 3 }} alignItems="flex-start">
          <Grid item container xs={12} sm={5}>
            {data.map((item) => (
              <Fragment key={item.label as string}>
                <Grid className="label" item xs={5}>
                  {item.label}
                </Grid>
                <Grid item xs={7}>
                  {item.value}
                </Grid>
              </Fragment>
            ))}
          </Grid>

          <Grid item container xs={12} sm={7}>
            {data2.map((item) => (
              <Fragment key={item.label as string}>
                <Grid className="label" item xs={12} sm={4}>
                  {item.label}
                </Grid>
                <Grid item xs={12} sm={8}>
                  {item.value}
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Grid>
      </Box>
    </InfoBoxRoot>
  )
}

export default Parcelle
