import { fromFilter, parseGeoJSON } from '@popety_io/popety-io-lib'

/**
 * Get fao filters from url query
 * @param {Object} query url query param
 */
const getFaoFilters = (query: Record<string, any>) => {
  let { value } = query
  const { orderBy = 'inquiry_start_date:desc' } = query

  if (query.landId) {
    value = `LAND_ID:${query.landId}`
  } else if (query.id) {
    value = `ID:${query.id}`
  }

  return {
    value,
    date: fromFilter(query.date, true),
    keyword: fromFilter(query.keyword),
    results: fromFilter(query.results),
    typeName: fromFilter(query.typeName),
    workType: fromFilter(query.workType),
    landUsePlans: fromFilter(query.landUsePlans),
    result: fromFilter(query.result),
    landUsePlan: fromFilter(query.landUsePlan),
    classification: fromFilter(query.classification),
    region: fromFilter(query.region),
    zone: fromFilter(query.zone),
    municipality: fromFilter(query.city),
    polygon: parseGeoJSON(query.polygon),
    area: fromFilter(query.area, true),
    npa: fromFilter(query.npa || query.npa_history),
    size: +query.size || 25,
    page: +query.page || 0,
    typeNameSize: +query.typeNameSize || 1000,
    resultSize: +query.resultSize || 1000,
    workTypeSize: +query.workTypeSize || 1000,
    view: query.view,
    orderBy,
  }
}

export { getFaoFilters }
