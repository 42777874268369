/* eslint-disable max-lines */
import React, { useState, useRef } from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import SellIcon from '@mui/icons-material/Sell'
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent'
import { StyledEngineProvider } from '@mui/material/styles'
import { format } from 'date-fns'
import CircularProgress from '@mui/material/CircularProgress'
import FaoIcon from '@mui/icons-material/Construction'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import { Box, BoxProps, Slide, Typography } from '@mui/material'
import { formatPrice, Tag, useI18n } from '@popety_io/popety-io-lib'

enum EVENT_HISTORY_CATEGORY {
  INVESTIGATION = 'Investigation',
  TRANSACTION = 'Transaction',
  RENTAL = 'Rental',
  SALE = 'Sale',
}

type IEventHistoryProps = {
  listEventHistory: Record<string, any>[]
  checked: Record<string, any>[]
  handleClickSeeMore: (data: any) => void
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  event: Record<string, any>
} & BoxProps

const EventHistory = ({
  listEventHistory,
  checked,
  handleClickSeeMore,
  loading,
  setLoading,
  event,
  ...other
}: IEventHistoryProps) => {
  const { t } = useI18n()
  const ref = useRef(null)
  const [indexSlice, setIndexSlice] = useState(100)
  const quantity = 10

  const handleScroll = () => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current

      if (
        scrollTop + clientHeight + 2 >= scrollHeight &&
        indexSlice < quantity
      ) {
        setLoading(true)

        return new Promise((r) => {
          setTimeout(() => {
            r(setIndexSlice((prev) => prev + 20))
          }, 200)
        }).then(() => {
          setLoading(false)
        })
      }
    }
  }

  return (
    <Box width="100%" {...other}>
      <Slide
        direction="left"
        timeout={1000}
        in={!(event?.id && event?._id)}
        style={{
          width: '100%',
        }}
      >
        <Box>
          <StyledEngineProvider>
            <Timeline
              id="event-history"
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.1,
                },
                padding: '0 0 12px 0',
              }}
              onScroll={handleScroll}
            >
              {[...listEventHistory, ...listEventHistory]
                ?.slice(0, indexSlice)
                .map((event: any, indexEvent: number) => {
                  if (
                    event?.type === EVENT_HISTORY_CATEGORY.INVESTIGATION &&
                    checked.find((item: any) => item.key === 'Investigation')
                  ) {
                    return (
                      <TimelineItem key={indexEvent}>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                          style={{ fontSize: '12px' }}
                        >
                          {event?.timestamp
                            ? format(new Date(event?.timestamp), 'dd.LL.yyyy')
                            : ''}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              backgroundColor: '#00ade6',
                            }}
                          >
                            <FaoIcon style={{ width: 16, height: 16 }} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent
                          sx={{
                            py: '12px',
                            px: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                            '& .Tag': {
                              fontSize: '9px !important',
                              fontWeight: 500,
                              height: '18px',
                            },
                          }}
                        >
                          <div>
                            <span style={{ fontSize: '16px', fontWeight: 500 }}>
                              {t('common.Mise à l’enquête')}
                            </span>
                          </div>
                          <div>
                            <Tag
                              bgColor="#b3def5"
                              sx={{
                                color: '#041725',
                              }}
                            >
                              {t('land.fao.developmentProject')}
                            </Tag>
                          </div>
                          <div>
                            <span
                              style={{
                                color: '#041725',
                              }}
                            >
                              {event?.description || ''}
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                color: '#00ADE6',
                                fontSize: '12px',
                                cursor: 'pointer',
                              }}
                              onClick={() => handleClickSeeMore(event)}
                            >
                              {t('common.seeMore')}
                            </span>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  }

                  if (
                    event?.type === EVENT_HISTORY_CATEGORY.TRANSACTION &&
                    checked.find((item: any) => item.key === 'Transaction')
                  ) {
                    return (
                      <TimelineItem key={indexEvent}>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                          style={{ fontSize: '12px' }}
                        >
                          {event?.timestamp
                            ? format(new Date(event?.timestamp), 'dd.LL.yyyy')
                            : ''}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              backgroundColor: '#0a6ad9',
                            }}
                          >
                            <MonetizationOnIcon
                              style={{
                                width: 16,
                                height: 16,
                              }}
                            />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent
                          sx={{
                            py: '12px',
                            px: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                          }}
                        >
                          <div>
                            <span style={{ fontSize: '16px', fontWeight: 500 }}>
                              {t('common.Transaction')}
                            </span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '2px',
                            }}
                          >
                            <Typography
                              color="primary"
                              variant="h4"
                              fontWeight="bold"
                              fontSize="14px"
                            >
                              {event.price ? formatPrice(event.price) : '-'}
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{
                                color: '#969696',
                                fontSize: '13px',
                                fontWeight: '500',
                              }}
                            >
                              {event?.price && event?.area > 0 && '-'}
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{
                                color: '#969696',
                                fontSize: '13px',
                                fontWeight: '500',
                              }}
                            >
                              {event?.price &&
                                event?.area > 0 &&
                                `${formatPrice(Math.round(event.price / event.area))} / m2`}
                            </Typography>
                          </div>
                          <div>
                            <span
                              style={{
                                color: '#041725',
                              }}
                            >
                              {event?.description || ''}
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                color: '#00ADE6',
                                fontSize: '12px',
                                cursor: 'pointer',
                              }}
                              onClick={() => handleClickSeeMore(event)}
                            >
                              {t('common.seeMore')}
                            </span>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  }

                  if (
                    (event?.type === EVENT_HISTORY_CATEGORY.RENTAL ||
                      event?.type === EVENT_HISTORY_CATEGORY.SALE) &&
                    checked.find(
                      (item: any) =>
                        item.key === 'Rental' || item.key === 'Sale',
                    )
                  ) {
                    return (
                      <TimelineItem key={indexEvent}>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                          style={{ fontSize: '12px' }}
                        >
                          {event?.timestamp
                            ? format(new Date(event?.timestamp), 'dd.LL.yyyy')
                            : ''}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              backgroundColor: '#969696',
                            }}
                          >
                            <SellIcon
                              style={{
                                width: 16,
                                height: 16,
                              }}
                            />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent
                          sx={{
                            py: '12px',
                            px: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                          }}
                        >
                          <div>
                            <span style={{ fontSize: '16px', fontWeight: 500 }}>
                              {t(
                                `common.${event?.type === EVENT_HISTORY_CATEGORY.RENTAL ? 'Annonce de location' : 'Annonce de vente'}`,
                              )}
                            </span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px',
                            }}
                          >
                            <Typography
                              color="primary"
                              variant="h4"
                              fontWeight="bold"
                              fontSize="14px"
                            >
                              {event.price ? formatPrice(event.price) : '-'}
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{
                                color: '#969696',
                                fontSize: '13px',
                                fontWeight: '500',
                              }}
                            >
                              {event?.price && event?.area > 0 && '-'}
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{
                                color: '#969696',
                                fontSize: '13px',
                                fontWeight: '500',
                              }}
                            >
                              {event?.price &&
                                event?.area > 0 &&
                                `${formatPrice(Math.round(event.price / event.area))} / m2`}
                            </Typography>
                          </div>
                          <div>
                            <span
                              style={{
                                color: '#041725',
                              }}
                            >
                              {event?.description || ''}
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                color: '#00ADE6',
                                fontSize: '12px',
                                cursor: 'pointer',
                              }}
                              onClick={() => handleClickSeeMore(event)}
                            >
                              {t('common.seeMore')}
                            </span>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  }

                  return <></>
                })}
              {loading && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '4px',
                  }}
                >
                  <CircularProgress size={20} />
                </Box>
              )}
            </Timeline>
          </StyledEngineProvider>
        </Box>
      </Slide>
    </Box>
  )
}

export default EventHistory
