/* eslint-disable max-lines */
import Grid from '@mui/material/Grid'
import {
  formatCurrency,
  useI18n,
  format,
  formatPrice,
  toReadableNumber,
  FilterChips,
} from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import HistoryAccordion, {
  HistoryAccordionProps,
} from '../components/HistoryAccordion'
import HistoryListItem from '../components/HistoryListItem'

export type TransactionAccordionProps = HistoryAccordionProps & {
  transaction: {
    sellers_information_json: any[]
    buyers_information_json: any[]
    [k: string]: any
  }
}

const TransactionAccordion = (props: TransactionAccordionProps) => {
  const { transaction, ...other } = props
  const { t } = useI18n()

  const regex = /^[\s,]*$/
  const addresses =
    transaction?.address
      ?.split(';')
      ?.filter((item: string) => !regex.test(item))
      ?.join(',') || ''

  const chips = [
    {
      label: transaction.property_type,
      value: transaction.property_type,
      key: 'property_type',
    },
    {
      label: `${t('listing.rooms', { count: transaction.rooms_nb })}`,
      value: transaction.rooms_nb,
      key: 'rooms_nb',
    },
    {
      label: `${toReadableNumber(transaction?.area || 0)} m2`,
      value: transaction.area,
      key: 'area',
    },
    {
      label: transaction.YearOfConstruction,
      value: transaction.YearOfConstruction,
      key: 'YearOfConstruction',
    },
    { label: transaction.Floor, value: transaction.Floor, key: 'Floor' },
    {
      label: `${toReadableNumber(transaction?.UsefulArea || 0)} m2`,
      value: transaction.UsefulArea,
      key: 'UsefulArea',
    },
  ].filter(
    (i) =>
      typeof i.value === 'string' ||
      (typeof i.value === 'number' && i.value > 0),
  )

  const title = (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} md={3}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'space-around',
          }}
        >
          <Typography
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            fontWeight="500"
            fontSize="1rem"
          >
            {transaction?.reason}
          </Typography>
          <Typography
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            fontWeight="500"
            fontSize="1rem"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {formatPrice(transaction?.price)}
          </Typography>
          {transaction.price_per_m2 ? (
            <Typography fontSize="0.8rem" color="#969696">
              {transaction.price_per_m2
                ? formatPrice(transaction.price_per_m2, 0)
                : ''}
              {transaction.price_per_m2 ? ' /m2' : ''}
            </Typography>
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={12} md={9}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'space-around',
            paddingRight: '10px',
          }}
        >
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              fontWeight: 500,
              fontSize: '1rem',
            }}
          >
            {transaction.ownership_type}
          </Typography>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              fontWeight: 400,
              fontSize: '1rem',
            }}
          >
            {transaction.details}
          </Typography>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              fontWeight: 400,
              fontSize: '0.8rem',
              color: '#969696',
            }}
          >
            {addresses}
          </Typography>
          {!!chips?.length && (
            <FilterChips
              sx={{
                '> .MuiChip-root': {
                  fontSize: '0.9rem',
                },
                flexWrap: 'wrap',
              }}
              items={chips}
              size="small"
              getChipKey={(item: any) => item.key}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  )

  const date = (
    <Typography
      sx={{
        textAlign: 'center',
        fontSize: '0.9rem',
      }}
    >
      <span
        style={{
          color: '#969696',
        }}
      >
        {t('common.publishedOn')}
      </span>{' '}
      {format(transaction.date, 'P')}
    </Typography>
  )
  const items = [
    { title: t('land.fao.camacNo'), value: transaction.official_id },
    { title: t('land.fao.ownershipType'), value: transaction.ownership_type },
    { title: t('land.fao.reason'), value: transaction.reason },
    {
      title: t('land.fao.price'),
      value: transaction.price
        ? formatCurrency(transaction.price, 'CHF ')
        : '-',
    },
    { title: t('land.fao.details'), value: transaction.details },
  ]

  const items2 = [
    {
      title: t('land.fao.seller'),
      value: transaction.sellers,
    },
    {
      title: t('land.fao.previousTransactionDate'),
      value: transaction.previous_transaction_date,
    },
    {
      title: t('land.fao.buyer'),
      value: transaction.buyers,
    },
    {
      title: t('land.fao.address'),
      value: transaction.address,
    },
    {
      title: t('land.fao.lands'),
      value: transaction.land_information,
    },
  ]

  return (
    <HistoryAccordion
      {...other}
      col1={{ title, md: 10 }}
      col2={{ title: date, md: 2 }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {items?.map((item, i) => (
            <HistoryListItem key={i} {...item} md={3} />
          ))}
        </Grid>
        <Grid item xs={12}>
          {items2?.map((item, i) => (
            <HistoryListItem {...item} key={i} md={3} />
          ))}
        </Grid>
      </Grid>
    </HistoryAccordion>
  )
}

export default TransactionAccordion
