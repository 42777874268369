import ListingDescription from '../ListingComponent/ListingDescription'
import ListingDetails from '../ListingComponent/ListingDetails'
import ListingFeatures from '../ListingComponent/ListingFeatures'
import ListingFooter from '../ListingComponent/ListingFooter'
import ListingHeader from '../ListingComponent/ListingHeader'
import ListingImages from '../ListingComponent/ListingImages'
import ListingMainInformation from '../ListingComponent/ListingMainInformation'

const ListingAccordionBody = ({
  listing,
  children,
}: {
  listing: any
  onSave?: (listings: any) => void
  comparablesIds?: string[]
  onRemove?: (listing: any) => void
  children?: any
}) => (
  <>
    <ListingHeader listing={listing} />
    {children}
    <ListingImages listing={listing} />
    <ListingMainInformation listing={listing} />
    <ListingDetails listing={listing} />
    <ListingFeatures listing={listing} />
    <ListingDescription listing={listing} />
    <ListingFooter listing={listing} />
  </>
)

export default ListingAccordionBody
