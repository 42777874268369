import { ApiClient, getUserId } from '../../../../services'

const client = new ApiClient()

const setSearchHistory = async (data: any) => {
  return client.post(`/search-history/land-search`, data)
}

const getLandSearchHistory = async () => {
  return client.get(`/search-history/land-search/${getUserId()}`)
}

const searchHistoryService = { setSearchHistory, getLandSearchHistory }

export default searchHistoryService
