/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import { Tag, format, useI18n } from '@popety_io/popety-io-lib'
import Typography from '@mui/material/Typography'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Box from '@mui/material/Box'

const FaoDetailHeader = ({ fao }: { fao: any }) => {
  const { t } = useI18n()
  const regex = /^[\s,]*$/
  const addresses = fao?.address
    ?.split(';')
    ?.filter((item: string) => !regex.test(item))

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        flexDirection: 'column',
        paddingTop: 1,
        paddingBottom: 1,
        width: '100%',
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '8px',
          }}
        >
          <Typography fontSize="1.4rem" fontWeight={500}>
            {t('common.faoHistory')}{' '}
            {fao?.fao_code_number || fao.camac_code_number}
          </Typography>
          {fao.classification === 'development' && (
            <Tag
              color="default"
              style={{
                backgroundColor: '#daf0f7',
                fontWeight: 500,
                fontSize: '9px',
                height: '18px',
              }}
            >
              {t('land.fao.developmentProject')}
            </Tag>
          )}
        </Box>
        {(addresses?.length ||
          fao?.postal_codes_names?.length ||
          fao?.postal_code_name) && (
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
              marginLeft: '-4px',
            }}
          >
            <LocationOnIcon
              sx={{
                color: (theme) => theme.palette.primary.main,
              }}
            />
            {addresses?.length ? (
              <Box>
                {addresses?.map((address: string) => (
                  <Typography
                    key={address}
                    fontSize="1rem"
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {address}
                  </Typography>
                ))}
              </Box>
            ) : (
              <Typography
                fontSize="1rem"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {fao?.postal_codes_names?.[0] || fao?.postal_code_name}
              </Typography>
            )}
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          color="rgb(141, 155, 163)"
          sx={{ display: 'flex', whiteSpace: 'nowrap' }}
        >
          {t('common.publishedOn')}
          {' : '}
        </Typography>
        <span style={{ color: '#000000', marginLeft: 2 }}>
          {format(fao?.inquiry_start_date, 'P')}
        </span>
      </Box>
    </Box>
  )
}

export default FaoDetailHeader
