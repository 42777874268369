import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import Box from '@mui/material/Box'

const EventHistoryImages = ({ event }: { event: any }) => {
  return (
    <>
      {!!event?.urls_image?.filter((url: string) => url)?.length && (
        <Swiper
          spaceBetween={5}
          slidesPerView="auto"
          modules={[Navigation]}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          {event.urls_image
            ?.filter((url: string) => url)
            .map((image: any, i: number) => (
              <SwiperSlide key={i}>
                <Box
                  sx={{
                    position: 'relative',
                    height: 300,
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: 2,
                  }}
                >
                  <img
                    src={image}
                    alt="Uploaded"
                    style={{
                      width: 'auto',
                      height: '100%',
                      display: 'block',
                    }}
                  />
                </Box>
              </SwiperSlide>
            ))}
          <SwiperSlide>
            <></>
          </SwiperSlide>
        </Swiper>
      )}
    </>
  )
}

export default EventHistoryImages
