import {
  Box,
  InputLabel,
  styled,
  TextareaAutosize as BaseTextareaAutosize,
  TextField,
} from '@mui/material'
import { useEffect } from 'react'
import { StaticDatePicker } from '@mui/x-date-pickers'
import { useMediaQuery, useI18n } from '@popety_io/popety-io-lib'

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
}

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
}

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  resize: none;

  &:hover {
    border-color: ${blue[400]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
)

interface Props {
  reminder: any
  action?: { id?: string; alarm_date?: string }
  date: Date | undefined
  setDate: React.Dispatch<React.SetStateAction<Date | undefined>>
  subject: string
  setSubject: React.Dispatch<React.SetStateAction<string>>
}

const ReminderDetail = ({
  date,
  setDate,
  subject,
  setSubject,
  reminder,
  action = {},
}: Props) => {
  const { t } = useI18n()

  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const handleChange = (newDate: Date | null) => {
    setDate(newDate || undefined)
  }

  const handleChangeSubject = (e: any) => {
    setSubject(e?.target?.value)
  }

  useEffect(() => {
    if (action?.alarm_date) {
      setDate(new Date(action.alarm_date))
    }
  }, [action])

  useEffect(() => {
    setSubject(reminder?.subject || '')
  }, [reminder])

  return (
    <Box
      sx={{
        // width: '460px',
        maxWidth: 460,
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '12px 7px',
        maxHeight: '60vh',
        msOverflowY: 'scroll',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          paddingX: '12px',
        }}
      >
        <InputLabel
          sx={{
            color: '#041725',
            fontWeight: 500,
          }}
        >
          {t('search.form.description')}
        </InputLabel>
        <TextareaAutosize
          value={subject}
          onChange={handleChangeSubject}
          minRows={2}
          placeholder={t('common.reminderDescription')}
        />
      </Box>
      <StaticDatePicker
        orientation={!onDesktop ? 'portrait' : 'landscape'}
        value={date}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} />}
        minDate={new Date()}
        displayStaticWrapperAs="desktop"
      />
    </Box>
  )
}

export default ReminderDetail
