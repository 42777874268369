import TransactionClickModal from '../../../../LandDetails/Transaction/TransactionAround/TransactionClickModal'
import TransactionMapContent from '../../../../LandDetails/Transaction/TransactionAround/TransactionMapContent'
import TransactionMapHover from '../../../../LandDetails/Transaction/TransactionAround/TransactionMapHover'
import EstimateTransactionsConfig from './EstimateTransactionController'

export const EstimateTransactionMapConfig = {
  Click: TransactionClickModal,
  Hover: TransactionMapHover,
  Content: TransactionMapContent,
  Controller: EstimateTransactionsConfig,
}
