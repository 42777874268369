/* eslint-disable max-lines */
import { mapBy } from '@popety_io/popety-io-lib'

import * as actions from './actions'
import { getAlertGeoPoint, getAlertGeoPolygon } from './updateGeoJSONData'

const extraReducers = (builder) => {
  builder
    .addCase(actions.getAlerts.pending, (state) => {
      state.alertDataLoading = true
    })
    .addCase(actions.getAlerts.rejected, (state) => {
      state.alertDataLoading = false
    })
    .addCase(actions.getAlerts.fulfilled, (state, action) => {
      const { data = [] } = action.payload || {}

      state.alertDataLoading = false
      state.alertsById = mapBy('id', data)
    })
    .addCase(actions.getZonesByCity.pending, (state) => {
      state.getZonesByCityLoading = true
    })
    .addCase(actions.getZonesByCity.fulfilled, (state, action) => {
      state.getZonesByCityLoading = false

      const { data } = action.payload

      if (data) state.zoneAffectation = data
    })
    .addCase(actions.getZonesByCity.rejected, (state) => {
      state.getZonesByCityLoading = false
    })
    .addCase(actions.getZonesByDistrict.pending, (state) => {
      state.getZonesByDistrictLoading = true
    })
    .addCase(actions.getZonesByDistrict.rejected, (state) => {
      state.getZonesByDistrictLoading = false
    })
    .addCase(actions.getZonesByDistrict.fulfilled, (state, action) => {
      state.getZonesByDistrictLoading = false

      const { data } = action.payload

      if (data) state.zoneAffectation = data
    })
    .addCase(actions.getZonesByRegion.pending, (state) => {
      state.getZonesByRegionLoading = true
    })
    .addCase(actions.getZonesByRegion.fulfilled, (state, action) => {
      state.getZonesByRegionLoading = false

      const { data } = action.payload

      if (data) state.zoneAffectation = data
    })
    .addCase(actions.getZonesByRegion.rejected, (state) => {
      state.getZonesByRegionLoading = false
    })
    .addCase(actions.getDistrictById.pending, (state) => {
      state.getDistrictByIdLoading = true
    })
    .addCase(actions.getDistrictById.fulfilled, (state, action) => {
      state.getDistrictByIdLoading = false

      const { data = [] } = action.payload || {}

      state.data = { ...state.data, district: data }

      state.geoPoint = getAlertGeoPoint(state.data)
      state.geoPolygon = getAlertGeoPolygon(state.data)
    })
    .addCase(actions.getDistrictById.rejected, (state) => {
      state.getDistrictByIdLoading = false
    })
    .addCase(actions.getCityById.pending, (state) => {
      state.getCityByIdLoading = true
    })
    .addCase(actions.getCityById.fulfilled, (state, action) => {
      state.getCityByIdLoading = false

      const { data = [] } = action.payload || {}

      state.data = { ...state.data, city: data }

      state.geoPoint = getAlertGeoPoint(state.data)
      state.geoPolygon = getAlertGeoPolygon(state.data)
    })
    .addCase(actions.getCityById.rejected, (state) => {
      state.getCityByIdLoading = false
    })
    .addCase(actions.getRegionById.pending, (state) => {
      state.getRegionByIdLoading = true
    })
    .addCase(actions.getRegionById.fulfilled, (state, action) => {
      state.getRegionByIdLoading = false

      const { data = [] } = action.payload || {}

      state.data = { ...state.data, region: data }

      state.geoPoint = getAlertGeoPoint(state.data)
      state.geoPolygon = getAlertGeoPolygon(state.data)
    })
    .addCase(actions.getRegionById.rejected, (state) => {
      state.getRegionByIdLoading = false
    })
    .addCase(actions.getRegionGeojsonByZone.pending, (state) => {
      state.getRegionGeojsonByZoneLoading = true
    })
    .addCase(actions.getDistrictGeojsonByZone.fulfilled, (state, action) => {
      state.getDistrictGeojsonByZoneLoading = false

      const { data = [] } = action.payload || {}

      state.data = { ...state.data, district: data }

      state.geoPoint = getAlertGeoPoint(state.data)
      state.geoPolygon = getAlertGeoPolygon(state.data)
    })
    .addCase(actions.getDistrictGeojsonByZone.rejected, (state) => {
      state.getDistrictGeojsonByZoneLoading = false
    })
    .addCase(actions.getDistrictGeojsonByZone.pending, (state) => {
      state.getDistrictGeojsonByZoneLoading = true
    })
    .addCase(actions.getRegionGeojsonByZone.fulfilled, (state, action) => {
      state.getRegionGeojsonByZoneLoading = false

      const { data = [] } = action.payload || {}

      state.data = { ...state.data, region: data }

      state.geoPoint = getAlertGeoPoint(state.data)
      state.geoPolygon = getAlertGeoPolygon(state.data)
    })
    .addCase(actions.getRegionGeojsonByZone.rejected, (state) => {
      state.getRegionGeojsonByZoneLoading = false
    })
    .addCase(actions.getCityGeojsonByZone.pending, (state) => {
      state.getCityGeojsonByZoneLoading = true
    })
    .addCase(actions.getCityGeojsonByZone.fulfilled, (state, action) => {
      state.getCityGeojsonByZoneLoading = false

      const { data = [] } = action.payload || {}

      state.data = { ...state.data, city: data }

      state.geoPoint = getAlertGeoPoint(state.data)
      state.geoPolygon = getAlertGeoPolygon(state.data)
    })
    .addCase(actions.getCityGeojsonByZone.rejected, (state) => {
      state.getCityGeojsonByZoneLoading = false
    })
    .addCase(actions.createAlert.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data) {
        const input = action.meta.arg
        const newAlert = {
          ...input,
          ...data,
          city: input.cityLocation,
          zone: input.zoneLocation,
          region: input.regionLocation,
          type: input.typeName,
        }

        state.alertsById[newAlert.id] = newAlert
      }
    })
    .addCase(actions.deleteAlert.fulfilled, (state, action) => {
      const id = action.meta.arg

      delete state.alertsById[id]
    })
    .addCase(actions.updateAlert.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data) {
        const input = action.meta.arg

        const newAlert = {
          ...input,
          ...data,
          city: input.cityLocation,
          zone: input.zoneLocation,
          region: input.regionLocation,
          type: input.typeName,
        }

        state.alertsById[newAlert.id] = newAlert
      }
    })
}

export default extraReducers
