/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
import { Grid, Box, Typography, Paper } from '@mui/material'
import { useI18n, format, ellipsis } from '@popety_io/popety-io-lib'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ApartmentIcon from '@mui/icons-material/Apartment'

import BuildingIcon from '../../../../components/LandPreview/LandDetails/BuildingIcons'
import { BuildingTagsChips } from '../../../../components/Tag'
import BuildingHistoryMenu from './BuildingHistoryMenu'
// import BuildingHistoryMenu from './BuildingHistoryMenu'

const BuildingHistoryContainer = ({ element }: any) => {
  const { t } = useI18n()

  const options = [
    {
      label: t('land.fao.constructionYearSection'),
      name: 'type',
      value: element?.building_year || element?.building_period || '-',
      icon: (
        <ApartmentIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      label: t('common.Ground floor area'),
      name: 'area',
      value: element?.ground_floor_area || '-',
      endAdornment: 'm²',
      icon: (
        <ApartmentIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      label: t('land.financialAnalysis.floors'),
      name: 'rooms',
      value: element?.floor_nb || '-',
      icon: (
        <ApartmentIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
  ]

  return (
    <Grid
      container
      key={element?.id}
      display="flex"
      justifyContent="space-between"
      component={Paper}
      elevation={2}
      sx={{
        opacity: element?.archived ? 0.33 : 1,
        paddingTop: 1.8,
        paddingBottom: 1.8,
        paddingLeft: 1.8,
        paddingRight: 1,
      }}
    >
      <Grid item display="flex" gap={1} xs={12} md={6}>
        <Box
          sx={{
            '& img': {
              width: '30px',
              height: '30px',
              marginBottom: '1px',
            },
            '& .custom-logo': {
              padding: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '70px',
              borderWidth: '1px',
              borderRadius: '8px',
              '& span': {
                width: '35px',
              },
            },
            '& span': {
              width: 'auto',
              fontSize: '9px',
              lineHeight: '0.8',
            },
            marginRight: '10px',
            padding: '2px',
          }}
        >
          <BuildingIcon classification={element?.popety_classification} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          color={element?.archived ? 'text.disabled' : 'text.primary'}
        >
          <Box
            style={{ textTransform: 'uppercase' }}
            sx={{
              fontWeight: 500,
              fontSize: '1.1rem',
            }}
          >
            {ellipsis(`EGID ${element?.egid}`, 40)}
          </Box>
          <Box
            sx={{
              minHeight: '10px',
            }}
          >
            <BuildingTagsChips
              buildingIds={element?.building_id || element?.id}
            />
          </Box>
          <Box
            component="div"
            display="flex"
            alignItems="center"
            sx={{
              marginLeft: '-4px',
              marginTop: '4px',
            }}
            color={element?.archived ? 'text.disabled' : 'text.primary'}
          >
            <LocationOnIcon fontSize="small" />
            {element?.addresses?.[0] || element?.address_postal_code_name}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {options.map((option, i) => (
          <Box
            key={i}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
            }}
            color={element?.archived ? 'text.disabled' : 'text.primary'}
          >
            <Grid container spacing={3}>
              <Grid item sm={7}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    color: '#969696',
                    fontSize: '0.9rem',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  {option.icon}
                  <Typography
                    fontWeight="500"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {option.label}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                sm={5}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  fontWeight="500"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {option.value}
                  {option.endAdornment &&
                    option.value !== '-' &&
                    `${option.endAdornment}`}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        md={1}
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        flexDirection="column"
      >
        <BuildingHistoryMenu
          id={element?.building_id || element?.id}
          archive={element?.archived}
        />
        <Box component="h5" fontWeight={400}>
          {element?.create_timestamp && format(element?.create_timestamp, 'P')}
        </Box>
      </Grid>
    </Grid>
  )
}

export default BuildingHistoryContainer
