/* eslint-disable max-lines */
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, IconButton, Typography } from '@mui/material'
import { Modal, useI18n } from '@popety_io/popety-io-lib'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { getEstimatePublicLink } from '../../../../utils'
import { archiveEstimateHistory, deleteEstimateHistory } from '../../redux'

type EstimateHistoryMenuProps = {
  dwellingId?: string
  id?: string
  geo_center?: Record<string, any>
  area?: number
  type?: string
  rooms?: number
  constructionYear?: number
  condition?: string
  renovationYear?: number
  hash?: string
  expirationDate?: string
  archive?: boolean
  baths?: number
}

const EstimateHistoryMenu = ({
  dwellingId,
  id,
  geo_center,
  area,
  type,
  rooms,
  constructionYear,
  condition,
  renovationYear,
  hash,
  expirationDate,
  baths,
  archive = false,
}: EstimateHistoryMenuProps) => {
  const { t, currentLanguage } = useI18n()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget as any)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = () => {
    let url = '?tab=summary'

    if (dwellingId) {
      url += `&dwellingId=${dwellingId}`
    }

    if (id) {
      url += `&estimateId=${id}`
    }

    if (geo_center) {
      url += `&geoCenter=${geo_center?.lon},${geo_center?.lat}`
    }

    if (area) {
      url += `&livingArea=${area}`
    }

    if (type) {
      const propertyType = type.charAt(0).toUpperCase() + type.slice(1)

      url += `&propertyCategory=${propertyType}`
      url += `&propertyType=${propertyType}`
    }

    if (rooms) {
      url += `&roomsNb=${rooms}`
    }

    if (constructionYear) {
      url += `&constructionYear=${constructionYear}`
    }

    if (condition) {
      url += `&condition=${condition}`
    }

    if (renovationYear) {
      url += `&renovationYear=${renovationYear}`
    }

    if (baths) {
      url += `&numberOfBathrooms=${baths}`
    }

    window.open(url, '_blank')
    setAnchorEl(null)
  }

  const handleDelete = () => {
    dispatch(deleteEstimateHistory(id) as any)
    setAnchorEl(null)
  }

  const handleArchive = () => {
    dispatch(archiveEstimateHistory({ id, archive: !archive }) as any)
    setAnchorEl(null)
    setOpenModal(false)
  }

  const handlePermalink = () => {
    getEstimatePublicLink({ hash, currentLanguage })
    setAnchorEl(null)
  }

  return (
    <Box display="flex">
      <IconButton size="small" onClick={handleClick} sx={{ opacity: 1 }}>
        <MoreVertIcon color="primary" />
      </IconButton>
      {anchorEl && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {!archive ? (
            <>
              <MenuItem sx={{ fontSize: '1.1rem' }} onClick={handleOpen}>
                {t('estimate.Editer')}
              </MenuItem>
              {expirationDate && (
                <MenuItem sx={{ fontSize: '1.1rem' }} onClick={handlePermalink}>
                  {t('estimate.Ouvrir permalink')}
                </MenuItem>
              )}
              <MenuItem sx={{ fontSize: '1.1rem' }} onClick={handleArchive}>
                {t('estimate.Archiver')}
              </MenuItem>
            </>
          ) : (
            <MenuItem sx={{ fontSize: '1.1rem' }} onClick={handleArchive}>
              {t('estimate.Désarchiver')}
            </MenuItem>
          )}
          <MenuItem
            sx={{ fontSize: '1.1rem', color: 'red' }}
            onClick={() => setOpenModal(true)}
          >
            {t('common.delete')}
          </MenuItem>
        </Menu>
      )}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        okText={t('common.delete')}
        showCloseIcon={false}
        cancelText={t('common.close')}
        onOk={handleDelete}
        onCancel={() => setOpenModal(false)}
      >
        <Typography variant="h5">
          {t('estimate.Êtes-vous sûr de vouloir supprimer cette estimation ?')}
        </Typography>
      </Modal>
    </Box>
  )
}

export default EstimateHistoryMenu
