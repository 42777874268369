import Box from '@mui/material/Box'
import { Slide } from '@mui/material'
import ListingAccordionBody from './ListingAccordionBody'

export type ListingListProps = {
  listing: Record<string, any>
  setListing: React.Dispatch<React.SetStateAction<Record<string, any>>>
  onSave?: (listings: any) => void
  comparablesIds?: string[]
  onRemove?: (listing: any) => void
}

const ListingDetail = ({
  listing,
  onRemove,
  onSave,
  comparablesIds,
}: ListingListProps) => {
  return (
    <Box
      width="100%"
      sx={{
        position: 'relative',
        display: listing?.id || listing?._id ? '' : 'none',
      }}
    >
      <Slide
        direction="left"
        timeout={1000}
        in={!!(listing?.id || listing?._id)}
        style={{
          width: '100%',
        }}
      >
        <Box
          data-cy="land-detail-accordionDetails"
          sx={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <ListingAccordionBody
            listing={listing}
            onSave={onSave}
            onRemove={onRemove}
            comparablesIds={comparablesIds}
          />
        </Box>
      </Slide>
    </Box>
  )
}

export default ListingDetail
