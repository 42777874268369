/* eslint-disable max-lines */
import { forwardRef } from 'react'
import {
  useI18n,
  FilterChips,
  toReadableNumber,
  format,
  formatPrice,
  useRouter,
} from '@popety_io/popety-io-lib'
import { Box, Typography } from '@mui/material'

type EstimateListingProps = {
  feature?: any
  handleClose: () => void
  data: any[]
  loading?: boolean
  onClick?: () => void
}

const EstimateListingPopup = (
  { data, onClick }: EstimateListingProps,
  ref: any,
) => {
  const { query, updateQuery } = useRouter()
  const { t } = useI18n()

  const handleClick = (listing: any) => {
    updateQuery({
      listingId: listing?._id || listing?.id || null,
    })
    if (onClick) {
      onClick()
    }
  }

  const chips = (listing: any) => {
    return [
      {
        label: listing.property_type,
        value: listing.property_type,
        key: 'property_type',
      },
      {
        label: `${t('listing.rooms', { count: listing.rooms_nb })}`,
        value: listing.rooms_nb,
        key: 'rooms_nb',
      },
      {
        label: `${toReadableNumber(listing?.area || 0)} m2`,
        value: listing.area,
        key: 'area',
      },
      {
        label: listing.YearOfConstruction,
        value: listing.YearOfConstruction,
        key: 'YearOfConstruction',
      },
      { label: listing.Floor, value: listing.Floor, key: 'Floor' },
      {
        label: `${toReadableNumber(listing?.UsefulArea || 0)} m2`,
        value: listing.UsefulArea,
        key: 'UsefulArea',
      },
    ].filter(
      (i) =>
        typeof i.value === 'string' ||
        (typeof i.value === 'number' && i.value > 0),
    )
  }

  return (
    <div
      ref={ref}
      style={{
        background: '#ffffff',
        backgroundColor: '#ffffff',
        padding: '9px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      {data?.map((listing, index) => {
        return (
          <Box
            key={index}
            sx={{
              cursor: 'pointer',
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 4px',
              padding: '3px 10px 5.5px',
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              backgroundColor: `${String(query?.listingId) === String(listing?._id || listing?.id) ? '#00ade6' : ''}`,
            }}
            onClick={() => {
              handleClick(listing)
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <Typography
                  color={`${String(query?.listingId) === String(listing?._id) ? '#000000' : 'rgb(141, 155, 163)'}`}
                  sx={{
                    display: 'flex',
                    whiteSpace: 'nowrap',
                    fontSize: '10px',
                  }}
                >
                  {t('common.publishedOn')}
                  {' : '}
                </Typography>
                <span
                  style={{
                    color: '#000000',
                    marginLeft: 2,
                    fontSize: '10px',
                  }}
                >
                  {format(listing?.listing_timestamp, 'P')}
                </span>
              </Box>
              <Typography
                fontSize="12px"
                fontWeight={500}
                color={`${String(query?.listingId) === String(listing?._id) ? '#000000' : '#00ADE6'}`}
              >
                {formatPrice(listing.price, 0)}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="left">
              {!!chips?.length && (
                <FilterChips
                  sx={{
                    '> .MuiChip-root': {
                      fontSize: '10px',
                      height: '17px',
                    },
                    flexWrap: 'wrap',
                  }}
                  items={chips(listing)}
                  size="small"
                  getChipKey={(item: any) => item.key}
                />
              )}
            </Box>
          </Box>
        )
      })}
    </div>
  )
}

export default forwardRef(EstimateListingPopup)
