import { useState, useEffect, useCallback } from 'react'
import mapboxgl from 'mapbox-gl'
import { Map, useRouter, useI18n } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import { estimateHistorySelector } from '../../redux'
import useMapEvents from '../../../../hooks/useMapEvents'
import { addClusters } from './clusters.util'

import './EstimateHistory.style.css'

interface Feature {
  type: 'Feature'
  geometry: {
    type: 'Point'
    coordinates: any
  }
  properties: {
    price?: string
    title?: string
  }
}

interface EstimateMapControllerProps {
  map: Map
  loaded?: boolean
}

const SOURCE = 'estimate-history'

const EstimateHistoryMapController: React.FC<EstimateMapControllerProps> = ({
  map,
  loaded,
}) => {
  const estimateHistory = useSelector(estimateHistorySelector)
  const { query } = useRouter()
  const [popups, setPopups] = useState<mapboxgl.Popup[]>([])
  const isHomePage = query?.tab === 'home'
  const { t } = useI18n()

  const features = useMapEvents(map, loaded, isHomePage, SOURCE)

  const createPopup = useCallback(
    (feature: Feature) => {
      const { properties, geometry } = feature
      const { price, title } = properties
      const coordinates = geometry.coordinates.slice()

      const popupContent = `
      <h4>${title || t('common.estimation')}</h4>
      <p>${price}</p>
    `

      return new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      })
        .setLngLat(coordinates)
        .setHTML(popupContent)
    },
    [t],
  )

  useEffect(() => {
    if (!map || !loaded || !isHomePage) return

    // Remove existing popups
    popups.forEach((popup) => popup.remove())

    // Create new popups
    const newPopups = features.map((feature) => createPopup(feature).addTo(map))

    setPopups(newPopups)

    return () => {
      newPopups.forEach((popup) => popup.remove())
    }
  }, [map, loaded, isHomePage, features, createPopup])

  useEffect(() => {
    if (!map || !loaded || !isHomePage || !estimateHistory?.length) return

    addClusters(map, estimateHistory)

    return () => {
      if (map.getSource(SOURCE)) {
        map.removeLayer('clusters')
        map.removeLayer('cluster-count')
        map.removeLayer('unclustered-point')
        map.removeSource(SOURCE)
      }
    }
  }, [map, loaded, isHomePage, estimateHistory])

  if (!isHomePage || !map || !loaded) return null

  return null
}

export default EstimateHistoryMapController
