import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SwipeableTabs, fetchOnce, useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Badge, SxProps } from '@mui/material'

import {
  activitySelector,
  addNote,
  countActivitySelector,
  countCategoriesSelector,
  getActionHistory,
} from '../../pages/Dashboard/redux'
import { NoteRichTextInput } from '../NoteRichTextInput'
// eslint-disable-next-line import/no-cycle
import { ActivityTimeline } from '../ActivityTimeline'
import NoteList from './NoteList'
import useLandNote from './useLandNote'

export type NotePreviewProps = {
  landId: string | string[]
  addressId: string
  listContentStyle?: SxProps
}

export const StyledSpan = styled(Box)`
  font-size: 1.1rem;
  font-weight: 500;
`

const NotePreview = ({
  landId,
  addressId,
  listContentStyle,
}: NotePreviewProps) => {
  const dispatch = useDispatch<any>()
  const ref = useRef<HTMLDivElement>()

  const { t } = useI18n()

  const note = useLandNote({ landId })

  const activities = useSelector(activitySelector)
  const countCategories = useSelector(countCategoriesSelector)

  const countActivity = useSelector(countActivitySelector)

  const scrollToBottom = (opts?: any) => {
    const noteInput = ref.current?.querySelector<HTMLInputElement>('#noteInput')

    ref.current?.scrollTo({ top: noteInput?.offsetTop, ...opts })
  }

  const handleNoteAdd = (content: string) => {
    dispatch(addNote({ landId, content }))
    setTimeout(() => scrollToBottom({ behavior: 'smooth' }), 300)
  }

  useEffect(() => {
    if (landId) {
      fetchOnce('getActionHistory', landId, () => {
        dispatch(getActionHistory(landId))
      })
    }
  }, [landId])

  const tabsNote = [
    {
      label: t('common.details.notes'),
      count: note.count,
    },
    { label: t('common.activities'), count: countActivity },
  ]
    .slice()
    .map((value) => ({
      label: (
        <Badge
          badgeContent={value.count}
          color="primary"
          sx={{
            '& .MuiBadge-badge': { top: -2 },
            paddingTop: 1,
            textTransform: 'none',
          }}
          showZero
        >
          {value.label}
        </Badge>
      ),
      key: value.label,
    }))

  return (
    <Box ref={ref}>
      <StyledSpan>{t('common.myspace.title')}</StyledSpan>
      <SwipeableTabs
        tabs={tabsNote}
        sx={{
          button: {
            width: '50%',
          },
        }}
        value={0}
      >
        <NoteList
          landId={landId}
          onMore={note.onClick}
          listContentStyle={listContentStyle}
        >
          <NoteRichTextInput onConfirm={handleNoteAdd} />
        </NoteList>
        <ActivityTimeline
          activities={activities}
          countCategories={countCategories}
          landId={landId}
          addressIds={addressId}
          countActivity={countActivity}
        />
      </SwipeableTabs>
    </Box>
  )
}

export default NotePreview
