import Grid, { GridProps } from '@mui/material/Grid'
import { Button, useRouter, useI18n } from '@popety_io/popety-io-lib'

type CustomFiltersProps = GridProps & {
  filters: Record<string, any>
  children: React.ReactNode
  showMore?: boolean
}

const CustomFilters = ({
  filters,
  children,
  showMore = true,
  ...other
}: CustomFiltersProps) => {
  const { t } = useI18n()
  const { updateQuery } = useRouter()

  // const hasFilters = !!Object.values(filters).filter(Boolean).length

  const handleReset = () => {
    const newQuery: any = {}

    Object.keys(filters).forEach((k) => {
      newQuery[k] = undefined
    })

    updateQuery(newQuery)
  }

  return (
    <Grid
      container
      spacing={2}
      p={2}
      sx={{
        py: 1,
      }}
    >
      {children}
      <Grid item xs={12} pt="0 !important" {...other}>
        {showMore && (
          <Button
            variant="text"
            sx={{ ml: -3, textTransform: 'none', color: '#E97B0E' }}
            onClick={handleReset}
          >
            {t('land.history.clearFilters')}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default CustomFilters
