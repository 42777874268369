/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Map,
  useRouter,
  useMediaQuery,
  useI18n,
} from '@popety_io/popety-io-lib'
import mapboxgl from 'mapbox-gl'
import { useLocation } from 'react-router-dom'
import { regionsByNameSelector } from '../../../../components/LocationsInput'
import {
  getFaoTiles,
  removeFaoTiles,
  buildingsFaoTiles,
} from '../../redux/fao/fao.layers'
import { estimateSelector } from '../../../Estimate/redux'
import { geoCenterSelector, landRegionSelector } from '../../redux'
import { buildingSelector } from '../../../BuildingDetails/redux'
import {
  faosTileDetailsLoadingsSelector,
  faosTileDetailsSelector,
  getFaosTileDetails,
} from '../../../FaoHistory'
import { getFaoFilters } from './utils'
import FaoMapTilePopup from './FaoMapTilePopup'

const fillStyle = 'layer_fill'

const FaoTiles = ({ map, loaded }: { map: Map; loaded?: boolean }) => {
  // const { dispatch, data, loading } = useAsync<any[]>()
  const dispatch = useDispatch<any>()
  const { t } = useI18n()
  const router = useRouter()
  const building = useSelector(buildingSelector)
  const estimate: any = useSelector(estimateSelector)
  const landGeoCenter = useSelector(geoCenterSelector)
  const location = useLocation()
  const isAnalysisBuilding = location.pathname?.includes(
    '/land-detail/building',
  )
  const isEstimate = !!router?.query?.estimateId
  const geoCenter = isEstimate
    ? estimate?.geo_center
    : isAnalysisBuilding
      ? building?.geo_center
      : landGeoCenter
  const data = useSelector(faosTileDetailsSelector)
  const loading = useSelector(faosTileDetailsLoadingsSelector)
  const [feature, setFeature] = useState<any>()
  const [coordinatesCurrent, setCoordinatesCurrent] = useState<any>()
  const [popup, setPopup] = useState<mapboxgl.Popup>()
  const [popupFao, setPopupFao] = useState<mapboxgl.Popup>()
  const touchScreen = useMediaQuery('(hover: none)')
  const regionsByName = useSelector(regionsByNameSelector)
  const landRegion = useSelector(landRegionSelector)

  const defaultDate = regionsByName[landRegion]?.first_fao_date

  const isFaoTab = router?.query?.tab === 'fao'
  const { faoCenter, title } = router.query
  const containerRef = useRef<any>(null)

  const filters = getFaoFilters(router?.query, geoCenter, defaultDate)
  const query = filters && encodeURIComponent(JSON.stringify(filters))
  const buildingQuery = encodeURIComponent(
    JSON.stringify({
      classification: 'projected',
      geoPoint: { lat: filters?.center?.[1], lon: filters?.center?.[0] },
      radius: filters?.radius,
    }),
  )

  const handleClickItemPopup = () => {
    if (map && popupFao) {
      popupFao.remove()
    }
  }

  const handleClickMap = () => {
    if (map && popupFao) {
      popupFao.remove()
    }
  }

  const handleClick = (e: any) => {
    if (map && popupFao) {
      popupFao.remove()
    }

    dispatch(
      getFaosTileDetails({
        tile: e.features.map(
          (f: { properties: { _key: string } }) => f.properties._key,
        ),
        q: query,
      }),
    )

    const coordinates = e.features[0].geometry.coordinates.slice()

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
    }

    setCoordinatesCurrent(coordinates)
  }

  const handleMouseMove = (e: any) => {
    map.getCanvas().style.cursor = 'pointer'
    if (popup) {
      const coordinates = e.features[0].geometry.coordinates.slice()
      const str = t('land.history.faos')
      const description = `${e.features[0].properties._count} ${str}`

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(map)
    }
  }

  const handleMouseLeave = () => {
    map.getCanvas().style.cursor = ''

    if (popup) {
      popup.remove()
    }
  }

  const addFaoTiles = () => {
    try {
      buildingsFaoTiles({ map, query: buildingQuery })
      getFaoTiles({
        map,
        query,
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    setPopup(
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    )
    setPopupFao(
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'custom-popup-fao',
      }),
    )
  }, [])

  useEffect(() => {
    if (router?.query?.tab !== 'fao' && map && loaded) {
      removeFaoTiles({ map })
    }
  }, [router?.query?.tab])

  useEffect(() => {
    if (touchScreen) setPopup(undefined)
  }, [touchScreen])

  useEffect(() => {
    if (loading && popupFao) {
      popupFao.remove()
    }

    if (router.query?.tab !== 'fao' && popupFao) {
      popupFao.remove()
      setCoordinatesCurrent(null)
    }

    if (
      !loading &&
      data &&
      coordinatesCurrent &&
      popupFao &&
      containerRef.current &&
      router.query?.tab === 'fao'
    ) {
      if (data?.length === 0) {
        popupFao.remove()

        return
      }
      if (data?.length === 1) {
        router.updateQuery({
          faoId: data?.[0]?._id || data?.[0]?.id || null,
        })
      } else {
        popupFao
          .setLngLat(coordinatesCurrent)
          .setDOMContent(containerRef.current)
          .addTo(map)
      }
    }
  }, [data, loading, popupFao, coordinatesCurrent, router.query?.tab])

  useEffect(() => {
    if (map && loaded && faoCenter && popup && title) {
      const [lng, lat] = faoCenter.split(',')

      map.flyTo({ center: [lng, lat], zoom: 17 })
      popup.setLngLat([lng, lat]).setHTML(title).addTo(map)
    } else if (map && loaded && !faoCenter && popup && !title) {
      popup.remove()
    }
  }, [faoCenter, map, loaded, popup, title])

  useEffect(() => {
    if (!map || !loaded || router?.query?.tab !== 'fao') return

    addFaoTiles()

    map.on('style.load', addFaoTiles)
    map.on('click', fillStyle, handleClick)
    map.on('mousemove', fillStyle, handleMouseMove)
    map.on('mouseleave', fillStyle, handleMouseLeave)
    map.on('click', handleClickMap)

    return () => {
      map.off('style.load', addFaoTiles)
      map.off('click', fillStyle, handleClick)
      map.off('mousemove', fillStyle, handleMouseMove)
      map.off('mouseleave', fillStyle, handleMouseLeave)
      map.off('click', handleClickMap)
    }
  }, [isFaoTab, loaded, query, faoCenter, title])

  useEffect(() => {
    if (data?.length) {
      setFeature(data[0])
    }
  }, [data])

  const handleClose = () => {
    setFeature(null)
  }

  // if (!isFaoTab || !map || !loaded) return null

  return (
    <FaoMapTilePopup
      data={data}
      feature={feature}
      handleClose={handleClose}
      ref={containerRef}
      loading={loading}
      onClick={handleClickItemPopup}
    />
  )
}

export default FaoTiles
