const transactionsSelector = (state) => state.transactionHistory.transactions

const selectedTransactionsSelector = (state) =>
  state.transactionHistory.selectedTransactions

const aggregationsSelector = (state) => state.transactionHistory.aggregations

const aggregationDatesSelector = (state) =>
  state.transactionHistory.aggregations.dates.dates

const transactionLoadingSelector = (state) => state.transactionHistory.loading

const transactionBboxSelector = (state) => state.transactionHistory.bbox

const countTransactionSelector = (state) =>
  state.transactionHistory.count || state.transactionHistory.total

const transactionsTileDetailsSelector = (state) =>
  state.transactionHistory.transactionsTileDetails

const transactionsTileDetailsLoadingSelector = (state) =>
  state.transactionHistory.transactionsTileDetailsLoading

export {
  transactionsSelector,
  aggregationsSelector,
  aggregationDatesSelector,
  transactionLoadingSelector,
  transactionBboxSelector,
  countTransactionSelector,
  selectedTransactionsSelector,
  transactionsTileDetailsSelector,
  transactionsTileDetailsLoadingSelector,
}
