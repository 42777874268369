/* eslint-disable max-lines */
import { useState, useRef, useMemo } from 'react'
import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useI18n, useRouter } from '@popety_io/popety-io-lib'
import { EventHistoryDeTail } from '../../../../components/EventHistoryDetail'
import { EventHistory } from '../../../../components/EventHistory'

export const dataExample = {
  _index: 'listing-v1',
  _id: '9206931',
  _score: 6,
  _ignored: ['description.keyword'],
  _source: {
    id: 9206931,
    title:
      'Très bel appartement de 5 pièces avec beaucoup de cachet au centre-ville',
    price: 1300000,
    city_id: 797,
    property_id: 1,
    area: 117,
    rooms_nb: 5,
    active: false,
    postal_code: 1201,
    postal_code_name: '1201 Genève',
    active_listing_timestamp: '2023-02-13T00:46:26Z',
    inactive_status_timestamp: '2023-02-15T00:31:27Z',
    last_update_listing_timestamp: '2023-02-13 06:00:00',
    hash_images: null,
    create_timestamp: '2023-02-13T06:00:00Z',
    update_timestamp: '2024-07-29T14:47:38.037577Z',
    bathroom_nb: null,
    construction_year: 1905,
    new_construction: false,
    listing_duration: 2,
    city_name: 'Genève',
    municipality_id: 2140,
    municipality_name: 'Genève',
    district_id: 140,
    district_name: 'Genève',
    region_id: 6,
    region_name: 'Genève',
    deal_type: 'purchase',
    listing_timestamp: '2023-02-13T00:46:26Z',
    ad_status: false,
    description:
      "Il est composé comme suit :</br>- bel hall d'entrée,</br>- cuisine équipée et aménagée,</br>- salle à manger,</br>- grand séjour avec cheminée décorative,</br>- 2 chambres à coucher,</br>- salle de bains avec wc</br>- wc visiteurs.</br>Une cave est également à disposition.</br>",
    floor: 5,
    BuildingArea: null,
    land_area: null,
    price_type: 'Prix de vente',
    property_type: 'Apartment',
    property_category: 'Apartment',
    exact_location: true,
    geo_center: 'POINT(6.146901494452516 46.20821919736918)',
    address: 'Rue François-Bonivard, 1201 Genève',
    vendor_type: 'unknown',
    original_url: 'https://www.homegate.ch/kaufen/3002531731',
    land_id: 2029253,
    land_use_principal_type: '2',
    portals: ['homegate.ch'],
    features: ['Balconies', 'Lift', 'ParkingOutdoor'],
    urls_image: [
      'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_1',
      'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_10',
      'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_11',
      'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_2',
      'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_3',
      'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_4',
      'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_5',
      'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_6',
      'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_7',
      'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_8',
      'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_9',
    ],
    hashes_image: [
      'fc9456cbaedaeb046034b89d798f0672',
      '2a3cf398b82473fbe21ea0e34e997383',
      'f83dbc3714a5224646fa083b913621e0',
      'fd9e816e7235dfc0469d83aadb53b554',
      'c2976b704391a66bfcaa8fa6b765c19a',
      'a3046eadd8328f2545cd23c367a6c26e',
      '1ed62a29f73f0d0947228b02791cea05',
      '8edf6ccc56a3a42cf929274d99f4be09',
      'd26fb9584d67970942d7111ca8f67cf4',
      'd3d81ca00f2d970c2dd5ead929f8d4b2',
      '815c4bb7f295d4873551462649bec399',
    ],
    price_history: [
      {
        listing_id: 9206931,
        listing_timestamp: '2023-02-13',
        price: 1300000,
      },
    ],
    scraping_sources: ['comparis'],
    price_per_square_meter: 11111,
    indexing_timestamp: '2024-08-30T15:57:51.066579Z',
  },
  id: 9206931,
  title:
    'Très bel appartement de 5 pièces avec beaucoup de cachet au centre-ville',
  price: 1300000,
  city_id: 797,
  property_id: 1,
  area: 117,
  rooms_nb: 5,
  active: false,
  postal_code: 1201,
  postal_code_name: '1201 Genève',
  active_listing_timestamp: '2023-02-13T00:46:26Z',
  inactive_status_timestamp: '2023-02-15T00:31:27Z',
  last_update_listing_timestamp: '2023-02-13 06:00:00',
  hash_images: null,
  create_timestamp: '2023-02-13T06:00:00Z',
  update_timestamp: '2024-07-29T14:47:38.037577Z',
  bathroom_nb: null,
  construction_year: 1905,
  new_construction: false,
  listing_duration: 2,
  city_name: 'Genève',
  municipality_id: 2140,
  municipality_name: 'Genève',
  district_id: 140,
  district_name: 'Genève',
  region_id: 6,
  region_name: 'Genève',
  deal_type: 'purchase',
  listing_timestamp: '2023-02-13T00:46:26Z',
  ad_status: false,
  description:
    "Il est composé comme suit :</br>- bel hall d'entrée,</br>- cuisine équipée et aménagée,</br>- salle à manger,</br>- grand séjour avec cheminée décorative,</br>- 2 chambres à coucher,</br>- salle de bains avec wc</br>- wc visiteurs.</br>Une cave est également à disposition.</br>",
  floor: 5,
  BuildingArea: null,
  land_area: null,
  price_type: 'Prix de vente',
  property_type: 'Apartment',
  property_category: 'Apartment',
  exact_location: true,
  geo_center: 'POINT(6.146901494452516 46.20821919736918)',
  address: 'Rue François-Bonivard, 1201 Genève',
  vendor_type: 'unknown',
  original_url: 'https://www.homegate.ch/kaufen/3002531731',
  land_id: 2029253,
  land_use_principal_type: '2',
  portals: ['homegate.ch'],
  features: ['Balconies', 'Lift', 'ParkingOutdoor'],
  urls_image: [
    'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_1',
    'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_10',
    'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_11',
    'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_2',
    'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_3',
    'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_4',
    'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_5',
    'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_6',
    'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_7',
    'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_8',
    'https://storage.googleapis.com/popetyio-external/listings/c/images/06f7d8559575da6230e0fbd4407aa00c/image_9',
  ],
  hashes_image: [
    'fc9456cbaedaeb046034b89d798f0672',
    '2a3cf398b82473fbe21ea0e34e997383',
    'f83dbc3714a5224646fa083b913621e0',
    'fd9e816e7235dfc0469d83aadb53b554',
    'c2976b704391a66bfcaa8fa6b765c19a',
    'a3046eadd8328f2545cd23c367a6c26e',
    '1ed62a29f73f0d0947228b02791cea05',
    '8edf6ccc56a3a42cf929274d99f4be09',
    'd26fb9584d67970942d7111ca8f67cf4',
    'd3d81ca00f2d970c2dd5ead929f8d4b2',
    '815c4bb7f295d4873551462649bec399',
  ],
  price_history: [
    {
      listing_id: 9206931,
      listing_timestamp: '2023-02-13',
      price: 1300000,
    },
  ],
  scraping_sources: ['comparis'],
  price_per_square_meter: 11111,
  indexing_timestamp: '2024-08-30T15:57:51.066579Z',
}

enum EVENT_HISTORY_CATEGORY {
  INVESTIGATION = 'Investigation',
  TRANSACTION = 'Transaction',
  RENTAL = 'Rental',
  SALE = 'Sale',
}

const EstimateEventHistory = () => {
  const { t } = useI18n()
  const ref = useRef(null)
  const { updateQuery } = useRouter()
  const [indexSlice, setIndexSlice] = useState(100)
  const [loading, setLoading] = useState(false)
  const countEvent = 10
  const quantity = 10

  const [checked, setChecked] = useState<any[]>([
    { key: EVENT_HISTORY_CATEGORY.INVESTIGATION, checked: true },
    { key: EVENT_HISTORY_CATEGORY.TRANSACTION, checked: true },
    { key: EVENT_HISTORY_CATEGORY.RENTAL, checked: true },
    { key: EVENT_HISTORY_CATEGORY.SALE, checked: true },
  ])
  const [event, setEvent] = useState<any>({})

  const handleScroll = () => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current

      if (
        scrollTop + clientHeight + 2 >= scrollHeight &&
        indexSlice < quantity
      ) {
        setLoading(true)

        return new Promise((r) => {
          setTimeout(() => {
            r(setIndexSlice((prev) => prev + 20))
          }, 200)
        }).then(() => {
          setLoading(false)
        })
      }
    }
  }

  const checkMarks = [
    {
      label: t('common.Mise à l’enquête'),
      count: 1,
      key: 'Investigation',
    },
    { label: t('common.Transaction'), count: 2, key: 'Transaction' },
    {
      label: t('common.Annonce de location'),
      count: 3,
      key: 'Rental',
    },
    {
      label: t('common.Annonce de vente'),
      count: 4,
      key: 'Sale',
    },
  ].filter(Boolean)

  const handleChange = (e: any) => {
    setChecked((prev: any[]) => {
      return prev?.map((item: any) => {
        if (item?.key === e.target.name) {
          return {
            key: item?.key,
            checked: e.target.checked,
          }
        }

        return item
      })
    })
  }

  const handleClickSeeMore = (item: any) => {
    updateQuery({
      historyId: item?.id || '',
    })
    setEvent(item)
  }

  const listEventHistoryData = [
    {
      type: EVENT_HISTORY_CATEGORY.INVESTIGATION,
      ...dataExample,
      category: 'Development',
      description: 'Appartement 4 pieces 93 m2',
      seeMore: '12345',
      timestamp: '2024-02-22T02:29:57.525Z',
      id: 1,
    },
    {
      type: EVENT_HISTORY_CATEGORY.TRANSACTION,
      ...dataExample,
      price: 13000,
      ownershipType: 'PPE',
      description:
        'Désolé nous n’avons trouvé aucune information historique survenue sur cette parcelle dans notre base de données',
      seeMore: '12345',
      timestamp: '2024-02-22T02:29:57.525Z',
      id: 1,
    },
    {
      type: EVENT_HISTORY_CATEGORY.RENTAL,
      ...dataExample,
      price: 13000,
      priceArea: 12,
      description: 'Appartement 4 pieces 93 m2',
      seeMore: '12345',
      timestamp: '2024-02-22T02:29:57.525Z',
      id: 1,
    },
    {
      type: EVENT_HISTORY_CATEGORY.SALE,
      ...dataExample,
      price: 13000,
      priceArea: 12,
      description: 'Appartement 4 pieces 93 m2',
      seeMore: '12345',
      timestamp: '2024-02-22T02:29:57.525Z',
      id: 1,
    },
  ]

  const handleBackTransactions = () => {
    setEvent({})
    updateQuery({
      historyId: null,
    })
  }

  const listEventHistory = useMemo(() => {
    return listEventHistoryData?.filter((item: any) => {
      return checked?.find((check) => check?.key === item?.type)?.checked
    })
  }, [checked, listEventHistoryData])

  return (
    <div
      id="act"
      style={{
        maxHeight: countEvent > 0 ? 600 : 0,
        display: 'flex',
        justifyContent: 'center',
      }}
      ref={ref}
      onScroll={handleScroll}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Box
          position="sticky"
          top={0}
          zIndex={10}
          bgcolor="background.paper"
          sx={{
            pb: 1,
            pt: 2,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              marginBottom: '4px',
            }}
            fontSize="1.7rem"
            fontWeight={500}
          >
            {t('common.historique')}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#969696',
              paddingBottom: '10px',
            }}
            fontSize="1rem"
          >
            {t('common.history.history_description')}
          </Typography>
          {(event?.id || event?._id) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                cursor: 'pointer',
              }}
              onClick={handleBackTransactions}
            >
              <ArrowBackIosIcon
                sx={{
                  width: '0.8em',
                  height: '0.8em',
                }}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '1.1rem',
                }}
              >
                {t('estimate.comparables.retour à la liste')}
              </Typography>
            </Box>
          )}
          {countEvent > 0 && !event?.id ? (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                top: 0,
                zIndex: 1000,
                background: '#fff',
                width: '100%',
                justifyContent: 'space-between',
                marginLeft: '-9px',
              }}
            >
              {checkMarks.map((mark: any, indexMark: number) => (
                <MenuItem
                  key={indexMark}
                  value={mark.label}
                  sx={{
                    paddingX: 0,
                  }}
                >
                  <Checkbox
                    checked={
                      checked.find((item: any) => item.key === mark.key)
                        ?.checked
                    }
                    name={mark.key}
                    onChange={handleChange}
                  />
                  <ListItemText primary={`${mark.label} (${mark.count})`} />
                </MenuItem>
              ))}
            </Box>
          ) : (
            ''
          )}
        </Box>
        {!listEventHistory?.length && (
          <Typography>{t('common.history.noHistory')}</Typography>
        )}
        <EventHistory
          listEventHistory={listEventHistory}
          checked={checked}
          handleClickSeeMore={handleClickSeeMore}
          loading={loading}
          setLoading={setLoading}
          event={event}
          sx={{
            display: !(event?.id && event?._id) ? '' : 'none',
            marginLeft: '-9px',
          }}
        />
        <EventHistoryDeTail
          event={event}
          setEvent={setEvent}
          sx={{
            display: event?.id || event?._id ? '' : 'none',
          }}
        />
      </Box>
    </div>
  )
}

export default EstimateEventHistory
