import Grid from '@mui/material/Grid'
import OkButton from '@mui/icons-material/CheckCircleOutline'
import NotOkButton from '@mui/icons-material/RemoveCircleOutline'

import { useI18n, LabelGrid } from '@popety_io/popety-io-lib'
import ValueGrid from '../../../../components/ValueGrid/ValueGrid'
import IndiceValueGrid from '../../../../components/ValueGrid/IndiceValueGrid'
import { getStatus } from '../UnderExploited.utils'
import LandUsePlanDocuments from './LandUsePlanDocuments'
import type { LandUsePlansOpts } from './LandUsePlan'

type LandUsePlanHeaderProps = {
  lup: LandUsePlansOpts
  isPLQ: boolean
}

const LandUsePlanHeader = ({ lup, isPLQ }: LandUsePlanHeaderProps) => {
  const { t } = useI18n()

  return (
    <Grid container style={{ fontSize: '1rem' }} justifyContent="center">
      <Grid item sm={6} xs={12}>
        <Grid container>
          <LabelGrid text={t('land.underExploited.planType')} xs={4} />
          <ValueGrid
            text={
              (t(`land.underExploited.${lup.plan_type}`) || '') +
              (lup.adoption_date
                ? ` ${t('land.underExploited.adopted')} ${lup.adoption_date}`
                : '') +
              (lup.status ? ` - ${getStatus(lup.status, t)}` : '')
            }
            xs={8}
            color={isPLQ && '#f86733'}
            comment={isPLQ && t('land.underExploited.warningSpecialPlan')}
          />
          <LabelGrid text={t('land.underExploited.planName')} xs={4} />
          <ValueGrid text={t(`land.underExploited.${lup.plan_name}`)} xs={8} />
          <Grid container>
            <LabelGrid text={t('land.underExploited.affectationName')} xs={4} />
            <ValueGrid text={lup.name} xs={8} />
          </Grid>
          <Grid container>
            <LabelGrid text={t('land.underExploited.buildingArea')} xs={4} />
            {lup.building_area ? (
              <OkButton
                style={{ color: 'rgb(142, 209, 165)' }}
                fontSize="small"
                className="iconOk"
              />
            ) : (
              <NotOkButton
                color="error"
                fontSize="small"
                className="iconNotOk"
              />
            )}
          </Grid>
          <LabelGrid text={t('land.underExploited.coverArea')} xs={4} />
          <ValueGrid
            text={`${
              Math.round(lup.cover_area_perc * 100) === 0
                ? '< 1'
                : Math.round(lup.cover_area_perc * 100)
            } %`}
            xs={8}
            color={lup.cover_area_perc !== 1 && 'rgb(230, 57, 0)'}
          />
        </Grid>
        {lup.building_area && (
          <Grid container>
            <LabelGrid text={t('land.underExploited.buildingOrder')} xs={4} />
            <IndiceValueGrid
              value={lup.construction_arrangement || '-'}
              comment={lup.construction_arrangement_desc}
              estimate={false}
              style={{ padding: 4 }}
              xs={8}
            />
          </Grid>
        )}
      </Grid>
      <Grid item sm={6} xs={12}>
        <Grid container>
          <LandUsePlanDocuments lup={lup} />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 18 }}>
        <LabelGrid text={t('land.underExploited.destination')} xs={12} />
        <ValueGrid text={lup.purpose || '-'} xs={12} />
      </Grid>
    </Grid>
  )
}

export default LandUsePlanHeader
