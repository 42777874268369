/* eslint-disable max-lines */
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Map,
  useRouter,
  useMediaQuery,
  useI18n,
} from '@popety_io/popety-io-lib'

import mapboxgl from 'mapbox-gl'
import { useLocation } from 'react-router-dom'
import {
  getListingsTiles,
  removeListingTiles,
} from '../../redux/listing/listing.layers'
import { geoCenterSelector } from '../../redux'
import { buildingSelector } from '../../../BuildingDetails/redux'
import {
  listingsTileDetailLoadingsSelector,
  listingsTileDetailsSelector,
} from '../../../ListingHistory/redux/listingHistorySelector'
import { getListingsTileDetails } from '../../../ListingHistory/redux/listingHistorySlice'
import { getListingFilters } from './utils'
import ListingTilesPopup from './ListingTilesPopup'

const fillStyle = 'layer_fill_listing'

const ListingTiles = ({ map, loaded }: { map: Map; loaded?: boolean }) => {
  // const { dispatch, data, loading } = useAsync<any[]>()
  const dispatch = useDispatch<any>()
  const { t } = useI18n()
  const router = useRouter()
  const location = useLocation()
  const isAnalysisBuilding = location.pathname?.includes(
    '/land-detail/building',
  )

  const building = useSelector(buildingSelector)
  const landGeoCenter = useSelector(geoCenterSelector)
  const geoCenter = isAnalysisBuilding ? building?.geo_center : landGeoCenter
  const data = useSelector(listingsTileDetailsSelector)
  const loading = useSelector(listingsTileDetailLoadingsSelector)
  const containerRef = useRef<any>(null)
  const [feature, setFeature] = useState<any>()
  const [coordinatesCurrent, setCoordinatesCurrent] = useState<any>()
  const [popupListing, setPopupListing] = useState<mapboxgl.Popup>()
  const [popup, setPopup] = useState<mapboxgl.Popup>()
  const touchScreen = useMediaQuery('(hover: none)')

  const isListingTab = router.query.tab === 'listing'
  const filters: any = getListingFilters(router.query, geoCenter)

  const query = filters && encodeURIComponent(JSON.stringify(filters))

  const { listingCenter, title } = router.query

  const handleClickItemPopup = () => {
    if (map && popupListing) {
      popupListing.remove()
    }
  }

  const handleClickMap = () => {
    if (map && popupListing) {
      popupListing.remove()
    }
  }

  const handleClick = (e: any) => {
    if (map && popupListing) {
      popupListing.remove()
    }

    dispatch(
      getListingsTileDetails({
        tile: e.features.map(
          (f: { properties: { _key: string } }) => f.properties._key,
        ),
        q: query,
      }),
    )

    const coordinates = e.features[0].geometry.coordinates.slice()

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
    }

    setCoordinatesCurrent(coordinates)
  }

  const handleMouseMove = (e: any) => {
    map.getCanvas().style.cursor = 'pointer'
    if (popup && (!listingCenter || !title)) {
      const coordinates = e.features[0].geometry.coordinates.slice()
      const str =
        e.features[0].properties._count > 1
          ? `${t('listing.listing')}s`
          : t('listing.listing')
      const description = `${e.features[0].properties._count} ${str}`

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(map)
    }
  }

  const handleMouseLeave = () => {
    map.getCanvas().style.cursor = ''

    if (popup && (!listingCenter || !title)) {
      popup.remove()
    }
  }

  const addTransactionTiles = () => {
    try {
      getListingsTiles({
        map,
        query,
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    setPopup(
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    )
    setPopupListing(
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'custom-popup-listing',
      }),
    )
  }, [])

  useEffect(() => {
    if (router?.query?.tab !== 'listing' && map && loaded) {
      removeListingTiles({ map })
    }
  }, [router?.query?.tab])

  useEffect(() => {
    if (touchScreen) setPopup(undefined)
  }, [touchScreen])

  useEffect(() => {
    if (!map || !loaded || router?.query?.tab !== 'listing') return

    addTransactionTiles()

    map.on('style.load', addTransactionTiles)
    map.on('click', fillStyle, handleClick)
    map.on('mousemove', fillStyle, handleMouseMove)
    map.on('mouseleave', fillStyle, handleMouseLeave)
    map.on('click', handleClickMap)

    return () => {
      map.off('style.load', addTransactionTiles)
      map.off('click', fillStyle, handleClick)
      map.off('mousemove', fillStyle, handleMouseMove)
      map.off('mouseleave', fillStyle, handleMouseLeave)
      map.off('click', handleClickMap)
    }
  }, [isListingTab, loaded, query, listingCenter, title, map])

  useEffect(() => {
    if (data?.length) {
      setFeature(data[0])
    }
  }, [data])

  const handleClose = () => {
    setFeature(null)
  }

  useEffect(() => {
    if (loading && popupListing) {
      popupListing.remove()
    }

    if (router.query?.tab !== 'listing' && popupListing) {
      popupListing.remove()
      setCoordinatesCurrent(null)
    }

    if (
      !loading &&
      data &&
      coordinatesCurrent &&
      popupListing &&
      containerRef.current &&
      router.query?.tab === 'listing' &&
      map
    ) {
      if (data?.length === 0) {
        popupListing.remove()

        return
      }
      if (data?.length === 1) {
        router.updateQuery({
          listingId: data?.[0]?._id || data?.[0]?.id || null,
        })
      } else {
        popupListing
          .setLngLat(coordinatesCurrent)
          .setDOMContent(containerRef.current)
          .addTo(map)
      }
    }
  }, [data, loading, popupListing, coordinatesCurrent, router.query?.tab, map])

  useEffect(() => {
    if (map && loaded && listingCenter && popup && title) {
      const [lng, lat] = listingCenter.split(',')

      map.flyTo({ center: [lng, lat], zoom: 17 })
      popup.setLngLat([lng, lat]).setHTML(title).addTo(map)
    } else if (map && loaded && !listingCenter && popup && !title) {
      popup.remove()
    }
  }, [listingCenter, map, loaded, title, popup])

  // if (!isListingTab || !map || !loaded) return null

  return (
    <ListingTilesPopup
      data={data}
      feature={feature}
      handleClose={handleClose}
      ref={containerRef}
      loading={loading}
      onClick={handleClickItemPopup}
    />
  )
}

export default ListingTiles
