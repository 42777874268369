import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useI18n } from '@popety_io/popety-io-lib'
import LandOwners from '../../../pages/LandDetails/Summary/LandOwners/LandOwners'

import { Title } from './Ownership.style'

type OwnershipProps = BoxProps & {
  feature: any
}

const Ownership = ({ feature, ...other }: OwnershipProps) => {
  const { t } = useI18n()

  const allFeature = feature?.egid ? feature?.buildings : feature?.lands

  const owners: any[] = [
    ...(feature?.owners || []),
    ...(allFeature?.map((f: any) => f.owners || []).flat() || []),
  ]

  const uniqueOwners = Array.from(
    new Set(
      owners.filter(
        (owner, index, array) =>
          array.findIndex((o) => o?.name === owner?.name) === index,
      ),
    ),
  ).flat()

  const lastModified = Math.max(
    ...uniqueOwners.map((name) => name?.create_timestamp),
  )

  if (!uniqueOwners.length) return null

  return (
    <Box {...other}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <Title
          className="title"
          sx={{
            cursor: 'pointer',
            color: '#969696',
          }}
        >
          {t('common.owners')}
        </Title>
        {lastModified && (
          <Typography variant="body2">
            {t('common.search.details.lastModif')}:{' '}
            {new Date(lastModified)?.toLocaleString('fr-FR').substring(0, 10)}
          </Typography>
        )}
      </Box>

      <LandOwners data={uniqueOwners} modal={false} canAnalyse />
    </Box>
  )
}

export default Ownership
