const estimateComparablesSelector = (state: {
  estimateComparables: Record<string, any>
}) => state?.estimateComparables

const estimateComparablesLoadingSelector = (state: {
  estimateComparables: Record<string, any>
}) => state?.estimateComparables.loading

const comparablesGeojsonSelector = (state: {
  estimateComparables: Record<string, any>
}) => state?.estimateComparables.geojson

export {
  estimateComparablesSelector,
  estimateComparablesLoadingSelector,
  comparablesGeojsonSelector,
}
