import pkg from '../../package.json'

// TODO: check that all values are used

/**
 * Indicates whether NODE_ENV is production
 * @namespace Config
 */
export const isProd = process.env.NODE_ENV === 'production'

export const isApiProd =
  process.env.REACT_APP_API_BASE_URL === 'https://api-prod.popety.io/api/v2'

/**
 * Indicates whether NODE_ENV is development
 * @namespace Config
 */
export const isDev = process.env.NODE_ENV === 'development'

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN as string

export const GOOGLE_MAP_TOKEN =
  (process.env.GOOGLE_MAP_API_KEY as string) ||
  'AIzaSyDYwQ1GN907ZCPj0Vd75r39GQm2qpvyogs'

export const DEFAULT_PROFILE_IMG =
  'https://storage.googleapis.com/popetyio-web/user-company-logo/default.png'

export const DEFAULT_OFFICE_IMG = '/assets/img/office-icon.png'

export const DEFAULT_MAPSTYLE =
  'mapbox://styles/popety/cl3jyrrml000614n6aelcet8c'

export const SATELLITE_MAPSTYLE =
  'mapbox://styles/popety/cl449l1ah000414umwqgcr0pd'

export const STYLE_PREFIX = 'mapbox://styles/'

export const APP_VERSION = process.env.REACT_APP_VERSION || pkg.version

export const LIB_VERSION = pkg.dependencies[
  '@popety_io/popety-io-lib'
]?.replace('^', '')

export const GOOGLE_CLIENT_ID = String(
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
    '524651884991-dr8gcgbkg7rh6ic5eh0825mflgaik5h3.apps.googleusercontent.com',
)

export const GOOGLE_CLIENT_SECRET =
  process.env.GOOGLE_CLIENT_SECRET || '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'

export const livabilityDistance = 2400

let HOST_PUBLIC = !isDev
  ? 'https://dev-estimate-popety-io.netlify.app'
  : 'http://localhost:3001'

if (isApiProd) {
  HOST_PUBLIC = 'https://share.popety.io'
}

export const HOST = HOST_PUBLIC

export const DOWNLOAD_LINK = {
  googleAuthenticator: {
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1',
    appStore: 'https://apps.apple.com/fr/app/google-authenticator/id388497605',
  },
  microsoftAuthenticator: {
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=fr',
    appStore:
      'https://apps.apple.com/us/app/microsoft-authenticator/id983156458',
  },
}

/**
 * SENTRY SETUP
 */
export const SENTRY_DNS =
  'https://86b9b57afc3e40b4b55a8ea018dcbb13@o926084.ingest.sentry.io/4504769294041088'

/**
 * App pages. key is the unique key that identify each page. It is used to load i18n by page.
 * So add it must be unique and must match the its i18n folder like i18n/language/page where
 * page is the unique key of the page. Ex: i18n/fr/home
 * @namespace Config
 */
export const PAGES = [
  { pathname: '/', key: 'search,driver' },
  { pathname: '/counter', key: 'counter,driver' },
  { pathname: '/login', key: 'login' },
  { pathname: '/account', key: 'account,driver' },
  { pathname: '/developer-map', key: 'search,driver,land-detail' },
  {
    pathname: '/land-detail/plot',
    key: 'land-detail,listingHistory,faoHistory,transactionHistory,driver,estimate',
  },
  {
    pathname: '/land-detail/building',
    key: 'land-detail,listingHistory,faoHistory,transactionHistory,driver,estimate',
  },
  {
    pathname: '/land-detail',
    key: 'land-detail,listingHistory,faoHistory,transactionHistory,driver,estimate',
  },
  { pathname: '/transaction-history', key: 'transactionHistory,driver' },
  { pathname: '/listing-history', key: 'listingHistory,driver' },
  { pathname: '/fao-history', key: 'faoHistory,driver' },
  { pathname: '/help', key: 'help,driver' },
  { pathname: '/dashboard', key: 'land-detail,dashboard,driver' },
  { pathname: '/building-dashboard', key: 'dashboard,search,driver' },
  { pathname: '/sources', key: 'sources,driver' },
  { pathname: '/glossary', key: 'glossary,driver' },
  {
    pathname: '/my-alerts',
    key: 'myAlerts,listingHistory,faoHistory,transactionHistory,driver',
  },
  { pathname: '/company-dashboard', key: 'companyDashboard,driver' },
  { pathname: '/user-guide', key: 'user-guide,driver' },
  {
    pathname: '/estimate',
    key: 'land-detail,listingHistory,faoHistory,transactionHistory,driver,estimate,dashboard,search,listing',
  },
  { pathname: '/developer-map/list', key: 'search,land-detail,driver' },
  { pathname: '/dashboard', key: 'land-detail,driver' },
] as const

export const LOCATION_TYPE = {
  ADDRESS: 'address',
  REGION: 'region',
  CITY: 'city',
  DISTRICT: 'district',
  LAND: 'land',
  NPA: 'npa',
  EGID: 'egid',
  EGRID: 'egrid',
  MUNICIPALITY: 'municipality',
} as const

export type LocationType = (typeof LOCATION_TYPE)[keyof typeof LOCATION_TYPE]

export const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const EMAIL_REGEX = /^\S+@\S+\.\S+$/

export const LANG_OPTIONS = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'Allemand' },
]
