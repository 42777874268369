/* eslint-disable max-lines */
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  SwipeableTabs,
  useI18n,
  useRouter,
  Driver,
  fetchOnce,
} from '@popety_io/popety-io-lib'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import { Badge } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

import { regionsByNameSelector } from '../../../components/LocationsInput'
import HistoryAroundFilters from '../components/HistoryAroundFilters'
import {
  landRegionSelector,
  landTransactionSetSelector,
  transactionsAroundCountSelector,
  transactionsAroundSelector,
  transactionReasonsStatsSelector,
  transactionOwnershipTypesStatsSelector,
  landTransactionsLoadingSelector,
} from '../redux'
import { buildingRegionSelector } from '../../BuildingDetails/redux'
import { userSelector } from '../../Login'
import { updateTutorials } from '../../Account'
import { transactionsTileDetailsSelector } from '../../TransactionHistory'
import TransactionList from './TransactionList'
import TransactionStats from './TransactionStats'
import getDriverInstructions from './driverInstructions'
import TransactionDetail from './TransactionDetail'

interface IProps {
  show?: boolean
}

const Transaction = ({ show = true }: IProps) => {
  const { query, updateQuery } = useRouter()

  const [displayDriver, setDisplayDriver] = useState(false)
  const [transaction, setTransaction] = useState<Record<string, any>>({})
  const [swipeableTabs, setSwipeableTabs] = useState<any>(0)

  const transactions = useSelector(landTransactionSetSelector)
  const transactionsAround = useSelector(transactionsAroundSelector)
  const transactionsAroundCount = useSelector(transactionsAroundCountSelector)
  const transactionReasonStats = useSelector(transactionReasonsStatsSelector)
  const transactionOwnershipTypeStats = useSelector(
    transactionOwnershipTypesStatsSelector,
  )
  const loading = useSelector(landTransactionsLoadingSelector)

  const landRegion = useSelector(landRegionSelector)
  const buildingRegion = useSelector(buildingRegionSelector)
  const regionsByName = useSelector(regionsByNameSelector)
  const user = useSelector(userSelector)
  const transactionsTileDetail = useSelector(transactionsTileDetailsSelector)

  const region = landRegion || buildingRegion || ''

  const { t } = useI18n()
  const dispatch = useDispatch<any>()

  // const tabs = ['transaction', 'stats']
  //   .map((value) => ({
  //     label: t(`land.history.tab.${value}`),
  //     key: value,
  //   }))
  //   .filter((value) => show || (!show && value.key !== 'stats'))

  const tabsTransaction = [
    {
      label: t('land.transaction.title'),
      count: transactions?.length,
    },
    { label: t('land.transactionAround'), count: transactionsAroundCount },
    { label: t('land.history.tab.stats') },
  ]
    .slice(show ? 0 : 1)
    .map((value) => ({
      label: (
        <Badge
          badgeContent={value.count}
          color="primary"
          sx={{
            '& .MuiBadge-badge': { top: -2 },
            paddingTop: 1,
          }}
          showZero
        >
          {value.label}
        </Badge>
      ),
      key: value.label,
    }))

  const first_transaction_date = regionsByName[region]?.first_transaction_date

  const [opened, setOpened] = useState<string>()

  const handleChange = (transaction: any, isExpanded: boolean) => {
    const uid = isExpanded ? `transaction${transaction.id}` : undefined

    setOpened(uid)
    updateQuery({
      transactionOpened: uid,
    })
    setTransaction(transaction)
  }

  const handleTabChangeParent = (tab: any) => {
    setTransaction({})
    setSwipeableTabs(tab)
  }

  const handleBackTransactions = () => {
    setTransaction({})
    updateQuery({
      transactionId: null,
    })
  }

  useEffect(() => {
    if (loading) return
    if (swipeableTabs === 2) return
    if (swipeableTabs === 1 && transactionsAroundCount) return
    if (swipeableTabs === 0 && transactions?.length) return

    let tab = swipeableTabs

    if (transactions?.length) {
      tab = 0
    } else if (!transactions?.length && transactionsAroundCount) {
      tab = 1
    }

    setSwipeableTabs(tab)
  }, [transactions?.length, transactionsAroundCount, loading])

  useEffect(() => {
    if (query?.transactionOpened) {
      setOpened(query?.transactionOpened)
    }
  }, [query?.transactionOpened])

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedTransactionDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedTransactionDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  useEffect(() => {
    if (query?.transactionId) {
      const transactionSelected = transactionsTileDetail?.find(
        (item: any) =>
          String(item?.id || item?._id) === String(query?.transactionId),
      )

      if (transactionSelected) {
        setTransaction(transactionSelected)

        const { transactionId } = query

        if (
          transactions?.find(
            (item: any) => String(item.id) === String(transactionId),
          )
        ) {
          setSwipeableTabs(0)
        } else if (
          transactionsAround?.find(
            (item: any) => String(item.id) === String(transactionId),
          )
        ) {
          setSwipeableTabs(1)
        } else {
          setSwipeableTabs(0)
        }
      }

      if (transactionSelected && transactionSelected?.location) {
        const transactionCenter = transactionSelected?.location
          ?.substring(6)
          ?.replace(/^\((.+)\)$/, '$1')
          ?.replace(' ', ',')

        updateQuery({
          transactionCenter,
          title: transactionSelected.work_description,
        })
      } else {
        updateQuery({ faoCenter: undefined, title: undefined })
      }
    }
  }, [query?.transactionId, transactionsTileDetail])

  if (!show) {
    return (
      <Box>
        <Box
          position="sticky"
          top={0}
          pt={2}
          zIndex={10}
          bgcolor="background.paper"
        >
          <Box
            sx={{
              marginBottom: '20px',
            }}
          >
            <Typography variant="h2" fontSize="1.7rem" fontWeight={500}>
              {t('common.search.details.transaction')}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#969696',
                paddingBottom: '10px',
              }}
              fontSize="1rem"
            >
              {t('common.transactions.header_description')}
            </Typography>
          </Box>
          <HistoryAroundFilters />
        </Box>
        {loading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {Array.from({ length: 10 }).map((_, i) => (
              <Box
                key={i}
                sx={{
                  width: '100%',
                  height: '45px',
                  mt: 1.5,
                }}
              >
                <Skeleton
                  variant="rounded"
                  sx={{
                    fontSize: '1rem',
                    borderRadius: 2,
                    height: '45px',
                    width: '100%',
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <TransactionList
            transactions={transactionsAround}
            opened={opened}
            onChange={handleChange as any}
          />
        )}
      </Box>
    )
  }

  return (
    <Box px={1}>
      <SwipeableTabs
        divider={!(transaction?.id || transaction?._id)}
        fixedTabs
        tabs={tabsTransaction}
        value={swipeableTabs}
        handleTabChange={handleTabChangeParent}
        header={
          <Box position="sticky" top={0} zIndex={10} bgcolor="background.paper">
            <Box>
              <Typography variant="h2" fontSize="1.7rem" fontWeight={500}>
                {t('common.search.details.transaction')}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: '#969696',
                  paddingBottom: '10px',
                }}
                fontSize="1rem"
              >
                {t(
                  'common.Transactions immobilière historique trouvées dans le voisinage',
                )}
              </Typography>
            </Box>
          </Box>
        }
        filter={
          !(transaction?.id || transaction?._id) && swipeableTabs !== 0 ? (
            <Box
              pt={2}
              pb={1}
              zIndex={10}
              bgcolor="background.paper"
              sx={{
                position: {
                  lg: 'sticky',
                },
                top: {
                  lg: 0,
                },
              }}
            >
              <HistoryAroundFilters />
            </Box>
          ) : (
            <></>
          )
        }
        otherHeaderComponent={
          (transaction?.id || transaction?._id) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                cursor: 'pointer',
                paddingY: 1,
              }}
              onClick={handleBackTransactions}
            >
              <ArrowBackIosIcon
                sx={{
                  width: '0.8em',
                  height: '0.8em',
                }}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '1.1rem',
                }}
              >
                {t('estimate.comparables.retour à la liste')}
              </Typography>
            </Box>
          )
        }
      >
        {first_transaction_date &&
          (loading ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {Array.from({ length: 10 }).map((_, i) => (
                <Box
                  key={i}
                  sx={{
                    width: '100%',
                    height: '45px',
                    mt: 1.5,
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{
                      fontSize: '1rem',
                      borderRadius: 2,
                      height: '45px',
                      width: '100%',
                    }}
                  />
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              <TransactionList
                transactions={transactions}
                opened={opened}
                onChange={handleChange as any}
                transaction={transaction}
              />
              <TransactionDetail
                transaction={transaction}
                setTransaction={setTransaction}
              />
            </Box>
          ))}
        {loading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {Array.from({ length: 10 }).map((_, i) => (
              <Box
                key={i}
                sx={{
                  width: '100%',
                  height: '45px',
                  mt: 1.5,
                }}
              >
                <Skeleton
                  variant="rounded"
                  sx={{
                    fontSize: '1rem',
                    borderRadius: 2,
                    height: '45px',
                    width: '100%',
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box>
            <TransactionList
              transactions={transactionsAround}
              opened={opened}
              onChange={handleChange as any}
              transaction={transaction}
            />
            <TransactionDetail
              transaction={transaction}
              setTransaction={setTransaction}
            />
          </Box>
        )}
        {loading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {Array.from({ length: 10 }).map((_, i) => (
              <Box
                key={i}
                sx={{
                  width: '100%',
                  height: '45px',
                  mt: 1.5,
                }}
              >
                <Skeleton
                  variant="rounded"
                  sx={{
                    fontSize: '1rem',
                    borderRadius: 2,
                    height: '45px',
                    width: '100%',
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : (
          !(transaction?.id || transaction?._id) && (
            <TransactionStats
              transactionsStats={[
                transactionReasonStats,
                transactionOwnershipTypeStats,
              ].filter(Boolean)}
            />
          )
        )}
      </SwipeableTabs>

      <Driver instructions={getDriverInstructions(t)} display={displayDriver} />
    </Box>
  )
}

export default Transaction
