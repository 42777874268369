import { ApiClient } from '../../../services'

const client = new ApiClient()

export type HistorySearchOpts = Record<string, any>

const getTransactions = (filter: HistorySearchOpts) => {
  return client.post('/transactions/search', filter)
}

const getSelectedTransactions = (filter: HistorySearchOpts) => {
  return client.post('/transactions/search', filter)
}

const countTransactions = (filter: HistorySearchOpts) => {
  return client.post('/transactions/count', filter)
}

const getTransactionStats = ({ ...filter }: HistorySearchOpts) => {
  return client.post('/transactions/stats', filter)
}

const getTransactionsTileDetails = (tile: string[], q = '') => {
  return client.get(`/transactions/tiles/details?tile=${tile}&query=${q}`)
}

const getTransactionsTileDetailsRedux = ({ tile, q = '' }: any) => {
  return client.get(`/transactions/tiles/details?tile=${tile}&query=${q}`)
}

const transactionHistoryService = {
  getTransactions,
  getTransactionsTileDetails,
  getTransactionStats,
  countTransactions,
  getSelectedTransactions,
  getTransactionsTileDetailsRedux,
}

export default transactionHistoryService
