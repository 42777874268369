import { ReactNode } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import {
  fromFilter,
  toFilter,
  useRouter,
  useI18n,
  // OverlayLoader,
} from '@popety_io/popety-io-lib'
import { CircularProgress, InputAdornment } from '@mui/material'

export type FormAutocompleteProps = {
  name: string
  id?: string
  label?: ReactNode
  placeholder?: string
  options: { label: string; value: string }[]
  disabled?: boolean
  autoSelect?: boolean
  sxAutoComplete?: any
  sx?: TextFieldProps['sx']
  labelTranslation?: string
  loading?: boolean
}

const FormAutocomplete = ({
  placeholder,
  label,
  name,
  options,
  sx,
  autoSelect = false,
  labelTranslation,
  loading = false,
  sxAutoComplete = {},
  ...props
}: FormAutocompleteProps) => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()

  const value = (fromFilter(query[name]) as string[])?.map((v) => ({
    value: v,
    label: t(`${labelTranslation}.${v}`),
  }))

  const handleChange = (e: any, newValue: any[]) => {
    const value = {
      [name]: toFilter(newValue.map((i) => i.value ?? i ?? '')),
    }

    updateQuery(value)
  }

  return (
    <Autocomplete
      {...props}
      multiple
      // loading={loading}
      disabled={loading}
      autoHighlight
      filterSelectedOptions
      options={options || []}
      value={value || []}
      fullWidth
      selectOnFocus
      autoSelect={autoSelect}
      size="small"
      sx={{ '.MuiChip-root': { maxWidth: 200 }, ...sxAutoComplete }}
      id={`${name}_${Date.now()}`}
      onChange={handleChange}
      isOptionEqualToValue={(option, val) =>
        option.value === (val?.value ?? val)
      }
      getOptionLabel={(option) => option?.label || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={sx}
          variant="outlined"
          placeholder={placeholder}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && (
                  <InputAdornment position="end">
                    <CircularProgress color="inherit" size={20} />
                  </InputAdornment>
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
            form: { autoComplete: 'off' },
          }}
        />
      )}
      data-cy="select-affectation-alerts"
    />
  )
}

export default FormAutocomplete
