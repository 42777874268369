import { useEffect, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import {
  Map,
  // useI18n,
  useMediaQuery,
  useRouter,
} from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import { estimateSelector } from '../../../../Estimate/redux'
import { getEnvironmentTiles, removeEnvironmentTiles } from './utils'

const fillStyle = 'layer_fill_noise'

interface NoiseMapControllerProps {
  map: Map
  loaded?: boolean
}

const NoiseMapController = ({ map, loaded }: NoiseMapControllerProps) => {
  const estimate: any = useSelector(estimateSelector)
  const { query } = useRouter()

  const [popup, setPopup] = useState<mapboxgl.Popup>()
  const isNoiseTab = query.subTab === 'noise'
  const touchScreen = useMediaQuery('(hover: none)')

  useEffect(() => {
    if (touchScreen) setPopup(undefined)
  }, [touchScreen])

  useEffect(() => {
    setPopup(
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    )
  }, [])

  useEffect(() => {
    if (query.subTab !== 'noise' && map && loaded) {
      removeEnvironmentTiles({ map })
    }
  }, [query.subTab, query.tab])

  const addTiles = () => {
    try {
      getEnvironmentTiles({
        map,
        indexName: query.noiseLayer,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleMouseMove = (e: any) => {
    map.getCanvas().style.cursor = 'pointer'
    if (popup) {
      const feature = e?.features?.[0]

      if (!feature) return

      const str = `${Math.round(feature.properties['avg_db.value'])} db`

      popup.setLngLat(e.lngLat).setHTML(str).addTo(map)
    }
  }

  const handleMouseLeave = () => {
    map.getCanvas().style.cursor = ''

    if (popup) {
      popup.remove()
    }
  }

  useEffect(() => {
    if (query.subTab !== 'noise' || !map || !loaded) return

    addTiles()

    map.on('style.load', addTiles)
    map.on('mousemove', fillStyle, handleMouseMove)
    map.on('mouseleave', fillStyle, handleMouseLeave)

    return () => {
      map.off('style.load', addTiles)
      map.on('mousemove', fillStyle, handleMouseMove)
      map.on('mouseleave', fillStyle, handleMouseLeave)
    }
  }, [isNoiseTab, loaded, estimate, query.noiseLayer])

  if (!isNoiseTab || !map || !loaded) return null

  return null
}

export default NoiseMapController
