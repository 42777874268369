/* eslint-disable max-lines */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { BoxProps } from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { FormField, FormFieldProps, ResetIcon } from '@popety_io/popety-io-lib'
import { MinMaxFieldsRoot } from './MinMaxFields.style'

type FieldValue = number | string | null | undefined
type MinMaxValueObj = { min?: FieldValue; max?: FieldValue }
type MinMaxValue = FieldValue[] | MinMaxValueObj

export type MinMaxFieldsProps = BoxProps &
  Pick<FormFieldProps, 'endAdornment' | 'm2'> & {
    minFieldProps?: Omit<FormFieldProps, 'onChange' | 'value'>
    maxFieldProps?: Omit<FormFieldProps, 'onChange' | 'value'>
    minLabel?: FormFieldProps['label']
    maxLabel?: FormFieldProps['label']
    minPlaceholder?: FormFieldProps['placeholder']
    maxPlaceholder?: FormFieldProps['placeholder']
    positive?: boolean
    value?: MinMaxValue
    reset?: boolean
    clear?: boolean
    onChange?: (value: MinMaxValue, committed?: boolean) => void
    defaultValue?: MinMaxValueObj
    handleSubmit?: () => void
    startAdornment?: FormFieldProps['startAdornment']
  }

/**
 * Min max form fields
 */
const MinMaxFields = ({
  onChange = () => '',
  value: filter = [],
  id = '',
  minFieldProps,
  maxFieldProps,
  positive = true,
  reset,
  endAdornment,
  startAdornment,
  m2,
  minLabel = 'Min',
  maxLabel = 'Max',
  minPlaceholder,
  maxPlaceholder,
  clear,
  defaultValue,
  handleSubmit,
  ...other
}: MinMaxFieldsProps) => {
  let min: any = Array.isArray(filter) ? filter[0] : filter?.min
  let max: any = Array.isArray(filter) ? filter[1] : filter?.max

  const obj = !Array.isArray(filter)

  if (positive) {
    if (min < 0) min = 0
    if (max < 0) max = 0
  }

  const lastFieldUpdated = useRef<string>()
  const applyRef = useRef<boolean>()

  const [value, setValue] = useState({ min, max })

  const handleChange: FormFieldProps['onChange'] = (e: any) => {
    let newValue: any = e.target.value

    if (newValue) newValue = +newValue

    if (positive && newValue < 0) newValue = 0

    const newVal = { ...value, [e.target.name]: newValue }

    setValue({ ...value, [e.target.name]: newValue })
    lastFieldUpdated.current = e.target.name

    onChange(obj ? newVal : [newVal.min, newVal.max], false)
    applyRef.current = true
  }

  const handleApply = () => {
    if (isNaN(value.min) && isNaN(value.max)) {
      return
    }

    if (value.min === '' && value.max === '') return

    if (!lastFieldUpdated.current) return

    const valToApply = { ...value }

    if (valToApply.max === '') valToApply.max = undefined
    if (valToApply.min === '') valToApply.min = undefined

    if (
      lastFieldUpdated.current === 'min' &&
      Number(valToApply.min) > Number(valToApply.max)
    ) {
      valToApply.max = valToApply.min
    }

    if (
      lastFieldUpdated.current === 'max' &&
      Number(valToApply.max) < Number(valToApply.min)
    ) {
      valToApply.min = valToApply.max
    }

    setValue(valToApply)

    if (obj) return onChange(valToApply, true)

    onChange([valToApply.min, valToApply.max], true)
    applyRef.current = false
  }

  const handleEnter: FormFieldProps['onKeyDown'] = (e: any) => {
    if (e.key === 'Enter') {
      handleApply()
      handleSubmit?.()
    }
  }

  const handleClickAway = () => {
    // if (min === value.min && max === value.max) return
    // if (+min === +value.min && +max === +value.max) return

    if (applyRef.current) {
      handleApply()
      handleSubmit?.()
    }
  }

  const handleReset = () => {
    setValue({ min: defaultValue?.min || '', max: defaultValue?.max || '' })
    onChange(obj ? {} : [])
  }

  const filterStr = JSON.stringify(filter)

  useEffect(() => {
    setValue((val) => ({ ...val, min, max }))
  }, [filterStr])

  useEffect(() => {
    if (positive) {
      setValue((v) => (v.min < 0 || v.max < 0 ? { min: '', max: '' } : v))
    }
  }, [positive])

  useEffect(() => {
    if (!clear) return

    setValue({ min: defaultValue?.min || '', max: defaultValue?.max || '' })
    onChange(obj ? {} : [])
  }, [clear])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <MinMaxFieldsRoot {...other}>
        <FormField
          type="number"
          endAdornment={endAdornment}
          startAdornment={startAdornment}
          m2={m2}
          label={minLabel}
          placeholder={minPlaceholder}
          autoComplete="off"
          {...minFieldProps}
          onChange={handleChange}
          onKeyDown={handleEnter}
          value={+value.min === 0 ? value.min : String(+value.min || '')}
          id={`${id}-min`}
          name="min"
          shrink
        />

        <FormField
          type="number"
          endAdornment={endAdornment}
          startAdornment={startAdornment}
          m2={m2}
          label={maxLabel}
          placeholder={maxPlaceholder}
          autoComplete="off"
          {...maxFieldProps}
          onChange={handleChange}
          onKeyDown={handleEnter}
          value={+value.max === 0 ? value.max : String(+value.max || '')}
          id={`${id}-max`}
          name="max"
          shrink
        />

        {reset && (
          <IconButton
            aria-label="reset"
            onClick={handleReset}
            className="ResetButton"
          >
            <ResetIcon fontSize="small" />
          </IconButton>
        )}
      </MinMaxFieldsRoot>
    </ClickAwayListener>
  )
}

export default MinMaxFields
