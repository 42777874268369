import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import { useI18n, useRouter } from '@popety_io/popety-io-lib'
import { ChangeEvent } from 'react'
import { Slide, Typography } from '@mui/material'

import ListingAccordion from './ListingAccordion'

export type ListingListProps = {
  listings: Record<string, any>[]
  opened?: string
  onChange: (listing: Record<string, any>, isExpanded?: boolean) => void
  listing?: Record<string, any>
}

const ListingList = ({
  listings,
  opened,
  onChange,
  listing,
}: ListingListProps) => {
  const { t } = useI18n()
  const listingList = [...listings].sort((a, b) =>
    a.listing_timestamp > b.listing_timestamp ? -1 : 1,
  )

  const { query, updateQuery } = useRouter()
  const { page = 0, size = 25 } = query

  const pagedResult = listingList.slice(+page * +size, (+page + 1) * +size)
  const hasPage = pagedResult.length !== listingList.length

  const handleChangePage = (e: any, newPage: number) => {
    updateQuery({ page: newPage })
  }

  const handleChangePageSize = (event: ChangeEvent<HTMLInputElement>) => {
    updateQuery({ size: +event.target.value, page: 0 })
  }

  const handleChange =
    (listing: Record<string, any>) => (_e: any, isExpanded?: boolean) => {
      onChange(listing, isExpanded)

      if (isExpanded && listing?.geo_center) {
        const listingCenter = listing?.geo_center
          ?.substring(5)
          ?.replace(/^\((.+)\)$/, '$1')
          ?.replace(' ', ',')

        updateQuery({
          listingCenter,
          title: listing?.title,
        })
      } else {
        updateQuery({ listingCenter: undefined, title: undefined })
      }
    }

  if (!pagedResult.length)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="calc(100vh - 257px)"
      >
        <Typography variant="h5">{t('land.fao.noListing')}</Typography>
      </Box>
    )

  return (
    <Box
      mt={2}
      mb={hasPage ? 0 : 3}
      sx={{
        display: listing?.id || listing?._id ? 'none' : 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
      }}
    >
      <Slide
        direction="right"
        timeout={1000}
        in={!(listing?.id || listing?._id)}
        style={{
          width: '100%',
        }}
      >
        <Box>
          {pagedResult.map((listing) => {
            return (
              <Box sx={{ mb: 1.5, mx: 0.2 }} key={listing.id}>
                <ListingAccordion
                  listing={listing}
                  expanded={opened === `listing${listing.id}`}
                  onChange={handleChange(listing)}
                />
              </Box>
            )
          })}

          {hasPage && (
            <TablePagination
              component="div"
              align="left"
              count={listingList.length}
              page={+page}
              onPageChange={handleChangePage}
              rowsPerPage={+size}
              onRowsPerPageChange={handleChangePageSize}
              labelRowsPerPage={t('common.rowsPerPage')}
            />
          )}
        </Box>
      </Slide>
    </Box>
  )
}

export default ListingList
