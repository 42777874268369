/* eslint-disable max-lines */
import { useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import GavelIcon from '@mui/icons-material/Gavel'
import ArchitectureIcon from '@mui/icons-material/Architecture'

const FaoDetails = ({ fao }: { fao: any }) => {
  const { t } = useI18n()

  const data = [
    {
      label: t('land.fao.dispensation'),
      value: fao.dispensation || '-',
      icon: (
        <CalendarMonthIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      label: t('land.fao.authority'),
      value: `${fao.jurisdiction_code ? `(${fao.jurisdiction_code})` : ''} ${
        fao.jurisdiction_name || ''
      }`,
      endAdornment: 'm²',
      icon: (
        <GavelIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      label: t('land.fao.architect'),
      value: fao.architect || '-',
      endAdornment: 'm²',
      icon: (
        <ArchitectureIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
  ]

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: '1.1rem',
          fontWeight: 500,
          textTransform: 'uppercase',
          mb: 1,
        }}
      >
        {t('fao.details')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          {data.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '4px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      color: '#969696',
                      fontSize: '0.9rem',
                    }}
                  >
                    {item.icon}
                    <Typography
                      fontWeight="500"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={9}>
                  <Typography
                    fontWeight="500"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default FaoDetails
