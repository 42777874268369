import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getComparablesData = (filter: Record<string, any>) => {
  return client.post('/environment', filter)
}

const estimateComparablesService = { getComparablesData }

export default estimateComparablesService
