import { FC } from 'react'
import { ResetIcon } from '@popety_io/popety-io-lib'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { format } from 'date-fns'

import {
  HeatingValueFieldBoxRoot,
  HeatingValueFieldRoot,
  HeatingValueRoot,
} from './HeatingAndHotWater.style'

interface IProps {
  value: string | undefined

  onChangeValue: (v: string) => void
  onReset: () => void
  onRetrieve: () => void
  isEdit: boolean
  valueEdited?: string
  valueDefault?: string
  type?: string | Date
}
const HeatingValueField: FC<IProps> = ({
  value = '',
  valueEdited,
  valueDefault,
  onChangeValue,
  onReset,
  onRetrieve,
  isEdit,
  type,
}) => {
  const onChange = (e: any) => {
    if (type === 'date') {
      onChangeValue(format(e, 'MM/dd/yyyy'))
    } else {
      onChangeValue(e.target.value)
    }
  }

  const isReset = valueDefault !== value && value === valueEdited

  if (!isEdit) {
    return (
      <HeatingValueRoot edited={!!value && value !== valueDefault}>
        {value || '-'}
      </HeatingValueRoot>
    )
  }

  return (
    <HeatingValueFieldBoxRoot retrieve={!isReset}>
      {type === 'date' ? (
        <DatePicker
          renderInput={(p: any) => (
            <HeatingValueFieldRoot {...p} sx={{ maxWidth: 133 }} />
          )}
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={onChange}
        />
      ) : (
        <HeatingValueFieldRoot value={value} onChange={onChange} />
      )}
      {valueEdited && (
        <ResetIcon
          sx={{ margin: 0, fontSize: 14 }}
          onClick={() => (isReset ? onReset() : onRetrieve())}
        />
      )}
    </HeatingValueFieldBoxRoot>
  )
}

export default HeatingValueField
