/* eslint-disable max-lines */
import { Box, Divider, Grid, Typography } from '@mui/material'
import { useI18n } from '@popety_io/popety-io-lib'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'
import SolarPowerIcon from '@mui/icons-material/SolarPower'
// import {
//   potentialSolar,
//   potentialSolarColor,
// } from '../../LandDetails/Building/Building.utils'
// import { getIdcColor, getMinergieColor } from './utils'

const EnergyHeader = ({ building }: { building: any }) => {
  const { t } = useI18n()

  const totalPower = building?.instalationDate
    ?.map((d: any) => d?.kw)
    ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)

  // const solarAverage = potentialSolar(t, building?.solar_average_exposure)
  // const solarAverageColor = potentialSolarColor(
  //   building?.solar_average_exposure,
  // )
  // const minergieColor = getMinergieColor(building?.minergie)
  // const idcColor = getIdcColor(building?.heat_consumption_index)

  // const energyHeaderData = useMemo(
  //   () => [
  //     {
  //       title: building.minergie ? (
  //         <Typography
  //           color={minergieColor || '#000000'}
  //           fontSize="1.846rem"
  //           fontWeight={500}
  //         >
  //           {building.minergie}
  //         </Typography>
  //       ) : (
  //         '-'
  //       ),
  //       subTitle: (
  //         <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //           {t('building.energy.label')}
  //           <InfoTooltip
  //             infos={[
  //               {
  //                 text: (
  //                   <Typography
  //                     sx={{
  //                       display: 'flex',
  //                       flexDirection: 'column',
  //                       padding: 1,
  //                       gap: '2px',
  //                     }}
  //                   >
  //                     <span>{t('common.header_title.label')}</span>
  //                     <ul>
  //                       <li style={{ color: '#A62115' }}>
  //                         {t(
  //                           'common.minergie.Minergie (Indice Minergie thermique 38 kWh/m2a)',
  //                         )}
  //                       </li>{' '}
  //                       <li style={{ color: '#265DAA' }}>
  //                         {t(
  //                           'common.minergie.Minergie-P (Indice Minergie thermique 30 kWh/m2a)',
  //                         )}
  //                       </li>{' '}
  //                       <li style={{ color: '#FAD978' }}>
  //                         {t(
  //                           'common.minergie.Minergie-A (Indice Minergie thermique 0 kWh/m2a)',
  //                         )}
  //                       </li>
  //                     </ul>
  //                     <span>
  //                       {t(
  //                         'common.footer_title.Le complément ECO indique que les thématiques de la santé et de l’écologie sont prises en compte',
  //                       )}
  //                     </span>
  //                   </Typography>
  //                 ),
  //               },
  //             ]}
  //           >
  //             <InfoOutlined sx={{ fontSize: 12, marginLeft: 0.5 }} />
  //           </InfoTooltip>
  //         </Box>
  //       ),
  //     },
  //     {
  //       title: building.heat_consumption_index ? (
  //         <Typography
  //           color={idcColor || '#000000'}
  //           fontSize="1.846rem"
  //           fontWeight={500}
  //         >
  //           {building.heat_consumption_index}
  //         </Typography>
  //       ) : (
  //         '-'
  //       ),
  //       subTitle: (
  //         <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //           {t('building.energy.IDC')}
  //           <InfoTooltip
  //             infos={[
  //               { text: t('common.description.idc').replace(/\./g, '.\n') },
  //             ]}
  //           >
  //             <InfoOutlined sx={{ fontSize: 12, marginLeft: 0.5 }} />
  //           </InfoTooltip>
  //         </Box>
  //       ),
  //     },
  //     {
  //       title: (
  //         <Typography
  //           color={solarAverageColor || '#000000'}
  //           fontSize="1.846rem"
  //           fontWeight={500}
  //         >
  //           {solarAverage}
  //         </Typography>
  //       ),
  //       subTitle: (
  //         <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //           {t('building.energy.Solar Potential')}
  //           <InfoTooltip
  //             infos={[
  //               {
  //                 text: (
  //                   <Typography
  //                     sx={{
  //                       display: 'flex',
  //                       flexDirection: 'column',
  //                       padding: 1,
  //                       gap: '2px',
  //                     }}
  //                   >
  //                     <span>{t('land.sunlight.aptitudeSolaire-desc1')}</span>
  //                     <span>{t('land.sunlight.aptitudeSolaire-desc2')}</span>
  //                     <span>
  //                       {t('land.sunlight.aptitudeSolaire-desc-weak')}
  //                     </span>
  //                     <span>{t('land.sunlight.aptitudeSolaire-desc-mid')}</span>
  //                     <span>
  //                       {t('land.sunlight.aptitudeSolaire-desc-good')}
  //                     </span>
  //                     <span>
  //                       {t('land.sunlight.aptitudeSolaire-desc-very-good')}
  //                     </span>
  //                     <span>
  //                       {t('land.sunlight.aptitudeSolaire-desc-excellent')}
  //                     </span>
  //                   </Typography>
  //                 ),
  //               },
  //             ]}
  //           >
  //             <InfoOutlined
  //               sx={{ fill: '#969696', fontSize: 12, marginLeft: 0.5 }}
  //             />
  //           </InfoTooltip>
  //         </Box>
  //       ),
  //     },
  //   ],

  //   [t, building],
  // )

  return (
    <>
      <Box
        position="sticky"
        pt={2}
        top={0}
        zIndex={10}
        bgcolor="background.paper"
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: '4px',
            textTransform: 'uppercase',
          }}
          fontSize="1.7rem"
          fontWeight={500}
        >
          {t('common.search.details.energy')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#969696',
            paddingBottom: '10px',
          }}
          fontSize="1rem"
        >
          {t('common.energy.header_description')}
        </Typography>
        <Divider />
      </Box>
      {/* <Box pt={1}>
        <ContentTitle m={1} data={energyHeaderData} gap={3} />
      </Box> */}
      <Box pt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <LocalFireDepartmentIcon
                  fontSize="large"
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Box>
                  <Typography
                    variant="h3"
                    fontSize="2.5rem"
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                    fontWeight={600}
                  >
                    {t(`common.${building?.power_source_heater}`)}
                  </Typography>
                  <Typography
                    fontSize="1rem"
                    sx={{
                      color: '#969696',
                    }}
                    fontWeight={500}
                  >
                    {t("common.Source d'énergie chauffage")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <SolarPowerIcon
                  fontSize="large"
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Box>
                  <Typography
                    variant="h3"
                    fontSize="2.5rem"
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                    fontWeight={600}
                  >
                    {totalPower ? `${totalPower} kW` : '-'}
                  </Typography>
                  <Typography
                    fontSize="1rem"
                    sx={{
                      color: '#969696',
                    }}
                    fontWeight={500}
                  >
                    {t('common.Installation solaire actuelle')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default EnergyHeader
