/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import { StatusContainer, format, useI18n } from '@popety_io/popety-io-lib'
import Typography from '@mui/material/Typography'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Box from '@mui/material/Box'

const ListingHeader = ({ listing }: { listing: any }) => {
  const { t } = useI18n()

  const calculateDate = (date2: any) => {
    try {
      const newdate1: any = new Date(Date.now())
      const newdate2: any = new Date(date2)

      // Calculate the time difference in milliseconds
      const differenceInMilliseconds = Math.abs(newdate2 - newdate1)

      // Convert the time difference to days
      const differenceInDays = Math.floor(
        differenceInMilliseconds / (24 * 60 * 60 * 1000),
      )

      return differenceInDays
    } catch (e) {
      return 0
    }
  }

  const listingDuration =
    listing?.listing_duration === 0 ? '0' : listing?.listing_duration

  const translatedListingDuration =
    listing?.ad_status && listing.listing_timestamp
      ? t(
          calculateDate(listing.listing_timestamp) > 1
            ? 'listing.daysAgo'
            : 'listing.dayAgo',
          {
            days: calculateDate(listing.listing_timestamp),
          },
        )
      : !listing?.ad_status && listingDuration
        ? t(listingDuration > 1 ? 'listing.daysAgo' : 'listing.dayAgo', {
            days: listingDuration,
          })
        : 'N/A'

  const regex = /^[\s,]*$/
  const addresses = listing?.address
    ?.split(';')
    ?.filter((item: string) => !regex.test(item))

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        flexDirection: 'column',
        paddingBottom: 1,
        width: '100%',
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            width: '100%',
          }}
        >
          <Typography fontSize="1.4rem" fontWeight={500}>
            {listing?.title}
          </Typography>
        </Box>
        {(addresses?.lenngth ||
          listing?.postal_codes_names?.length ||
          listing?.postal_code_name) && (
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              marginLeft: '-4px',
            }}
          >
            <LocationOnIcon
              sx={{
                color: (theme) => theme.palette.primary.main,
              }}
            />
            {addresses?.length ? (
              <Box>
                {addresses?.map((address: string) => (
                  <Typography
                    fontSize="1rem"
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {address}
                  </Typography>
                ))}
              </Box>
            ) : (
              <Typography
                fontSize="1rem"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {listing?.postal_codes_names?.[0] || listing?.postal_code_name}
              </Typography>
            )}
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
          width: '100%',
        }}
      >
        <StatusContainer>
          {listing?.ad_status ? (
            <span className="status active">
              {t('land.listing.status.active')}
            </span>
          ) : (
            <span className="status expired">
              {t('land.listing.status.expired')}
            </span>
          )}
        </StatusContainer>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            color="rgb(141, 155, 163)"
            sx={{ display: 'flex', whiteSpace: 'nowrap' }}
          >
            {t('common.publishedOn')}
            {' : '}
          </Typography>
          <span style={{ color: '#000000', marginLeft: 2 }}>
            {format(listing?.listing_timestamp, 'P')}
          </span>
        </Box>
        <Box
          sx={{
            marginLeft: 'auto',
          }}
        >
          <Typography component="i" color="rgb(141, 155, 163)">
            {t('land.listing.online')}
            <span style={{ color: '#000000' }}>
              {' : '}
              {translatedListingDuration}
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ListingHeader
