const buildingSelector = (state: any) => state.buildingSummary.building

const buildingRegionSelector = (state: any) =>
  state.buildingSummary.building?.region_name

const buildingLoadingSelector = (state: any) => state.buildingSummary.loading

const buildingAnalysisHistorySelector = (state: any) =>
  state.buildingSummary.buildingAnalysisHistory

const loadBuildingAnalysisHistorySelector = (state: any) =>
  state.buildingSummary.loadingHistory

export {
  buildingLoadingSelector,
  buildingSelector,
  buildingRegionSelector,
  buildingAnalysisHistorySelector,
  loadBuildingAnalysisHistorySelector,
}
