/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { useRouter, useI18n } from '@popety_io/popety-io-lib'
import { estimateSelector } from '../../../Estimate/redux'
import {
  getGeojsonByNpaName,
  getGeojsonCity,
  getGeojsonDistrict,
  landSelector,
} from '../../redux'
import { buildingSelector } from '../../../BuildingDetails/redux'

const queries = ['city_history', 'district_history', 'radius', 'npa_history']

const LocationsFilter = () => {
  const dispatch = useDispatch()
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()

  const land: any = useSelector(landSelector)
  const building = useSelector(buildingSelector)
  const estimate: any = useSelector(estimateSelector)
  const isMarket = query.tab === 'market'
  const isListing = query.tab === 'listing'
  // const isEnergy = query.tab === 'energy'

  const cityName = building.city_name || land.city_name || estimate.city_name

  const districtName =
    building.district_name || land.district_name || estimate.district_name

  const postalCode =
    building.address_postal_code_name ||
    land.address_postal_code_name ||
    estimate.address_postal_code_name

  const location = estimate?.id
    ? cityName
    : (query.radius &&
        `${t('land.history.withinRadius')} ${
          Number(query.radius) >= 1000
            ? Number(query.radius) / 1000
            : Number(query.radius)
        } ${Number(query.radius) >= 1000 ? 'km' : 'm'}`) ||
      query.npa_history ||
      query.city_history ||
      query.district_history ||
      (isListing || isMarket
        ? postalCode || null
        : `${t('land.history.withinRadius')} ${500} m`)

  const locationValue = estimate?.id
    ? cityName
    : (query.radius && Number(query.radius)) ||
      query.npa_history ||
      query.city_history ||
      query.district_history ||
      (isListing || isMarket ? postalCode || null : 500)

  const [value, setValue] = useState(
    location
      ? { label: location, value: locationValue, group: location }
      : undefined,
  )

  const locationOptions = [
    ...[postalCode].map((val) => ({
      label: val,
      value: val,
      group: t('common.location.npas'),
    })),
    ...[cityName].map((val) => ({
      label: val,
      value: val,
      group: t('common.city'),
    })),
    ...[districtName].map((val) => ({
      label: val,
      value: val,
      group: t('common.location.zone'),
    })),
    // hide if isMarket or isListing
    ...[100, 300, 500, 1000, 2000, 5000]
      .map((value) => ({
        label: `${t('land.history.withinRadius')} ${
          value >= 1000 ? value / 1000 : value
        } ${value >= 1000 ? 'km' : 'm'}`,
        value,
        group: t('land.history.radius'),
      }))
      .filter(() => !isMarket && !isListing),
  ].filter(Boolean)

  const handleChangeLocation = (e: any, newValue: any) => {
    const val = newValue?.value || newValue
    let title = 'radius'

    if (newValue?.group === t('common.city')) {
      title = 'city_history'
    } else if (newValue?.group === t('common.location.zone')) {
      title = 'district_history'
    } else if (newValue?.group === t('common.location.npas')) {
      title = 'npa_history'
    }

    setValue(newValue)

    const deleteTitle = queries
      .filter((qry) => qry !== title)
      .map((ttl) => [ttl, undefined])

    const query = {
      [title]: val,
      ...Object.fromEntries(deleteTitle as any),
    }

    updateQuery(query)
  }

  useEffect(() => {
    if (query.city_history) {
      dispatch(getGeojsonCity(query.city_history) as any)
    } else if (query.district_history) {
      dispatch(getGeojsonDistrict(query.district_history) as any)
    } else if (query.npa_history) {
      dispatch(getGeojsonByNpaName(query.npa_history) as any)
    }
  }, [query.city_history, query.district_history, query.npa_history])

  useEffect(() => {
    const locationScope =
      (query.radius && Number(query.radius)) ||
      query.city_history ||
      query.district_history ||
      query.npa_history

    if (!locationScope && !estimate?.id && !isListing && !isMarket) {
      updateQuery({
        radius: 500,
      })
    }

    if (!estimate?.id && (isListing || isMarket)) {
      updateQuery({ npa_history: postalCode, radius: undefined })
    }
    if (estimate.id && !locationScope) {
      updateQuery({ city_history: cityName })
    }
  }, [postalCode, cityName, isListing, isMarket])

  useEffect(() => {
    setValue(
      location
        ? { label: location, value: locationValue, group: location }
        : undefined,
    )
  }, [location, locationValue])

  return (
    <Grid item xs={12} sm={6} md={3} id="location-filter">
      <Autocomplete
        disableClearable
        groupBy={(option) => option.group}
        filterSelectedOptions
        getOptionLabel={(option: any) => option.label || option.value}
        isOptionEqualToValue={(option: any, val: any) =>
          option.value === (val?.value ?? val) && option.group === val?.group
        }
        selectOnFocus
        autoHighlight
        value={
          value || {
            label: location || '',
            value: locationValue || '',
            group: location,
          }
        }
        renderInput={(params: any) => (
          <TextField
            {...params}
            label={t('common.selectLocation')}
            variant="outlined"
            size="small"
            margin="none"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: { autoComplete: 'off' },
            }}
          />
        )}
        onChange={handleChangeLocation}
        options={locationOptions}
        fullWidth
      />
    </Grid>
  )
}

export default LocationsFilter
