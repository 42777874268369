import { useMemo } from 'react'
import { useRouter } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import {
  estimateSelector,
  estimateLandsSelector,
  estimateEditedSelector,
  comparablesGeojsonSelector,
} from '../../redux'

import {
  // transactionGeoPolygonSelector,
  poisIsochroneLayersSelector,
} from '../../../LandDetails'

import {
  getPlotRadius,
  getPlotLocation,
  getDasharrayLayer,
} from '../../../LandDetails/LandDetailsMap/utils'
import { getMultipleLandLayersOultined } from '../../../LandDetails/redux/summary/land.layers'
// import { getBuildingLayers } from '../../../LandDetails/redux/building/building.layers'
import { getBuildingLayer } from '../../../BuildingDetails/BuildingDetailsMap/BuildingDetailsMap.utils'
import { getGeoLayers } from '../../../LandDetails/redux/geo/geo.layers'
import { roofPolygonSelector } from '../../../BuildingDetails'

const useEstimateMapLayers = () => {
  const { query } = useRouter()
  const location = useLocation()

  const { radius, dimension = '3D', egid } = query
  const isBuildingDetails = location.pathname === '/estimate'

  const estimate: any = useSelector(estimateSelector)
  const estimateEdited: any = useSelector(estimateEditedSelector)

  const livabilityIsochrone = useSelector(poisIsochroneLayersSelector)
  const lands = useSelector(estimateLandsSelector)
  const landWithMeters = getGeoLayers(lands?.[0], [], false)
  const roofPolygon = useSelector(roofPolygonSelector)
  const comparablesGeojson = useSelector(comparablesGeojsonSelector)

  const radiusLayer = useMemo(() => {
    if (!estimate?.geo_center) {
      return null
    }
    if (isBuildingDetails && !radius) {
      return null
    }

    return getPlotRadius(estimate.geo_center, radius)
  }, [isBuildingDetails, estimate?.geo_center, radius])

  const locationLayer = getPlotLocation(estimate.geo_polygon)

  const landsLayers: any = useMemo(() => {
    return getMultipleLandLayersOultined(lands)
  }, [lands])

  const comparablesLayer = useMemo(() => {
    return getDasharrayLayer('dasharray-layer', comparablesGeojson)
  }, [comparablesGeojson])

  const buildingLayer = useMemo(() => {
    return getBuildingLayer(
      estimate,
      query?.energyLayer,
      query?.tab,
      query?.buildingHeight,
      egid,
      roofPolygon,
      estimateEdited?.configuration,
      dimension,
      query?.subTab,
    )
  }, [
    estimate,
    query?.energyLayer,
    query?.tab,
    query?.subTab,
    query?.buildingHeight,
    dimension,
    egid,
    query?.sunLightTab,
    query?.subTab,
    estimateEdited?.configuration,
  ])

  const history = [
    query.city_history && locationLayer,
    query.district_history && locationLayer,
    radiusLayer,
    ...landsLayers,
    buildingLayer,
  ].filter(Boolean)

  const comparables = [comparablesLayer, ...landsLayers, buildingLayer].filter(
    Boolean,
  )

  const sourcesByPage: Record<string, any[][]> = {
    summary: [landsLayers],
    parcelle: [landWithMeters],
    building: [buildingLayer],
    energy: [buildingLayer],
    transaction: history,
    fao: history,
    listing: comparables,
    livability: [livabilityIsochrone, landsLayers],
    sunlight: [buildingLayer],
    noise: [landsLayers],
  }

  const layers = sourcesByPage[query.subTab || query.tab] || []

  const data = layers?.flat()?.filter(Boolean)

  return { data }
}

export { useEstimateMapLayers }
