/* eslint-disable max-lines */
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import { Button, ProgressIndicator, useI18n } from '@popety_io/popety-io-lib'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import UploadIcon from '@mui/icons-material/Upload'
import Box from '@mui/material/Box'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import {
  saveEstimatePicture,
  deleteEstimatePicture,
  estimateEditedPicturesSelector,
  estimateIdSelector,
  imagesAreLoadingSelector,
} from '../../redux'
import { SwiperWrapper } from '../EstimateComparables/EstimateComparables.style'

const ImageCarousel = () => {
  const editedPictures = useSelector(estimateEditedPicturesSelector)
  const estimateId = useSelector(estimateIdSelector)
  const imagesAreLoading = useSelector(imagesAreLoadingSelector)

  const fileInputRef: any = useRef(null)

  const dispatch = useDispatch<any>()

  const { t } = useI18n()

  const handleImageUpload = async (event: any) => {
    const { files } = event.target

    if (!files || files.length < 1) return

    for (let i: number = 0; i < files.length; i += 1) {
      await dispatch(saveEstimatePicture({ id: estimateId, file: files[i] }))
    }
  }

  const handleClickUpload = () => {
    if (fileInputRef?.current) {
      fileInputRef?.current?.click()
    }
  }

  const handleImageDrop = async (event: any) => {
    event.preventDefault()

    const { files } = event.dataTransfer

    if (!files || files.length < 1) return

    for (let i: number = 0; i < files.length; i += 1) {
      await dispatch(saveEstimatePicture({ id: estimateId, file: files[i] }))
    }
  }

  return (
    <Box
      sx={{
        marginTop: 2,
      }}
    >
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageUpload}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />

      {imagesAreLoading && <ProgressIndicator />}

      {editedPictures && editedPictures?.length > 0 ? (
        <SwiperWrapper>
          <Swiper
            spaceBetween={5}
            slidesPerView="auto"
            modules={[Navigation, Pagination]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
            {editedPictures.map((image: any, i: number) => (
              <SwiperSlide key={i}>
                <Box
                  sx={{
                    position: 'relative',
                    height: 300,
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: 2,
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={handleImageDrop}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      dispatch(
                        deleteEstimatePicture({
                          id: estimateId,
                          imageUrl: image,
                        }),
                      )
                    }}
                    sx={{
                      position: 'absolute',
                      top: 4,
                      right: 4,
                      color: 'white',
                      zIndex: 1,
                      background: 'rgb(0 0 0 / 32%)',
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={handleClickUpload}
                    sx={{
                      position: 'absolute',
                      top: 40,
                      right: 4,
                      color: 'white',
                      zIndex: 1,
                      background: 'rgb(0 0 0 / 32%)',
                    }}
                  >
                    <UploadIcon fontSize="small" />
                  </IconButton>
                  <img
                    src={image}
                    alt="Uploaded"
                    style={{
                      width: 'auto',
                      height: '100%',
                      display: 'block',
                    }}
                  />
                </Box>
              </SwiperSlide>
            ))}
            <Box className="swiper-button-prev">
              <ArrowBackIosNewIcon />
            </Box>
            <Box className="swiper-button-next">
              <ArrowForwardIosIcon />
            </Box>
          </Swiper>
        </SwiperWrapper>
      ) : (
        <Paper
          sx={{
            height: 300,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleImageDrop}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {t('estimate.summary.upload_images_description')}

            <Button onClick={handleClickUpload}>
              {t('estimate.summary.upload_images')}
            </Button>
          </Box>
        </Paper>
      )}
    </Box>
  )
}

export default ImageCarousel
