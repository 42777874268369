import { Button, useI18n, ButtonProps } from '@popety_io/popety-io-lib'
import { Grid } from '@mui/material'

import { useSelector } from 'react-redux'
import { userSelector } from '../../../pages/Login'

export type OpenDetailsButtonProps = ButtonProps & {
  feature: any
  planDisplay?: string
  building?: boolean
}

const OpenDetailsButton = ({
  feature,
  planDisplay = 'now',
  building = !!feature.egid,
  ...other
}: OpenDetailsButtonProps) => {
  const { t } = useI18n()
  const user = useSelector(userSelector)

  const allFeature = feature?.egid ? feature?.buildings : feature?.lands

  const isGrouping = allFeature?.length > 1

  const isEstimate = user?.roles.includes('User_estimate')
  const isLanddev = user?.roles.includes('User_landdev')

  const id =
    allFeature?.map((f: any) => f.land_id)?.join(',') ||
    feature.land_id ||
    feature?.id

  const handleClick = () => {
    if (building) {
      window.open(
        `/land-detail/building?buildingId=${id}&landUsePlanStatus=${
          planDisplay || 'now'
        }`,
      )
    } else {
      window.open(
        `/land-detail/plot?landId=${id}&landUsePlanStatus=${
          planDisplay || 'now'
        }`,
      )
    }
  }

  const hadnleOpenEstimateClick = () => {
    if (
      building &&
      feature?.buildings_administrative?.length &&
      feature?.buildings_administrative[0]?.addresses?.length
    ) {
      const addressId =
        feature?.buildings_administrative[0]?.addresses[0]?.address_id

      window.open(
        `/estimate?tab=home&addressId=${addressId}&lat=${feature?.geo_center?.lat}&lon=${feature?.geo_center?.lon}&openNewReport=true`,
      )
    } else {
      let query = ''

      const landId = feature?.id || feature?.land_id
      const buildingId = feature?.buildings?.[0]?.id
      const addressId = feature?.address_id

      if (feature?.address_number === 1) {
        query = `buildingId=${buildingId}`
      } else {
        query = `landId=${landId}`
      }

      query = `${query}&addressId=${addressId}`

      window.open(
        `/estimate?tab=home&${query}&lat=${feature?.geo_center?.lat}&lon=${feature?.geo_center?.lon}&openNewReport=true`,
      )
    }
  }

  const text = t('common.open.analysis')

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item container xs={isGrouping ? 12 : 6}>
        <Button
          sx={{ position: 'sticky', bottom: 0, width: '100%', margin: '4px' }}
          className="OpenDetailsButton"
          {...other}
          color="primary"
          variant="contained"
          disableElevation
          onClick={handleClick}
          disabled={!isLanddev}
          // disabled={building}
        >
          {text}
        </Button>
      </Grid>
      {!isGrouping && (
        <Grid item container xs={6}>
          <Button
            sx={{ position: 'sticky', bottom: 0, width: '100%', margin: '4px' }}
            className="OpenDetailsButton"
            {...other}
            color="primary"
            variant="contained"
            disableElevation
            disabled={!isEstimate}
            onClick={hadnleOpenEstimateClick}
          >
            {t('common.open.estimate')}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default OpenDetailsButton
