import Box from '@mui/material/Box'
import { LeftMenu, useRouter, useI18n } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import { estimateSelector } from '../../redux'

import useAutoCloseSidebar from '../../../../hooks/useAutoCloseSidebar'
import { getEstimateMenuData } from './EstimateMenu.data'

const EstimateMenu = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()
  const estimate = useSelector(estimateSelector)
  const { onCloseSidebar } = useAutoCloseSidebar()

  const isHomePage = query?.tab === 'home'

  const menus = getEstimateMenuData(estimate, isHomePage, t)

  const data = menus.map(({ page: _, ...menu }: any, i) => ({
    ...menu,
    active: !query.tab ? i === 0 : query?.tab === menu?.key,
    onClick: () => {
      updateQuery({
        tab: menu.key,
        fs: null,
        transactionCenter: null,
        title: null,
        faoCenter: null,
        transactionOpened: null,
        faoOpened: null,
        listingOpened: null,
        listingCenter: null,
        subTab: null,
        listingId: null,
        faoId: null,
        transactionId: null,
      })
      if (menu?.subMenus?.length) {
        updateQuery({
          subTab: menu.subMenus[0].key,
        })
      }
      onCloseSidebar()
    },
    subMenus: menu.subMenus?.map((subMenu: any) => ({
      ...subMenu,
      active: query?.subTab === subMenu?.key,
      onClick: () => {
        updateQuery({
          tab: menu.key,
          subTab: subMenu.key,
          fs: null,
          transactionCenter: null,
          title: null,
          faoCenter: null,
          transactionOpened: null,
          faoOpened: null,
          listingOpened: null,
          listingCenter: null,
        })
        onCloseSidebar()
      },
    })),
  }))

  return (
    <Box width={107} height="100%">
      <LeftMenu data={data as any} />
    </Box>
  )
}

export default EstimateMenu
