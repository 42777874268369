/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Modal,
  useMediaQuery,
  useI18n,
  Snackbar,
} from '@popety_io/popety-io-lib'
import { ThunkDispatch } from '@reduxjs/toolkit'
import {
  addReminder,
  loadingReminderDataSelector,
  reminderDataAddSelector,
  reminderDataUpdateSelector,
  updateReminder,
} from '../../pages/Dashboard/redux'
import ReminderList from './ReminderList'
import ReminderDetail from './ReminderDetail'

interface Props {
  onClose: () => void
  open: boolean
  landId: string | string[]
  action?: { id?: string; alarm_date?: string }
}

const RememberDialog = ({
  onClose,
  open,
  landId,
  action = {},
}: Props): JSX.Element => {
  const { t } = useI18n()
  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const landIds = Array.isArray(landId) ? landId : [landId]
  const loadingReminder = useSelector(loadingReminderDataSelector)
  const reminderDataAdd = useSelector(reminderDataAddSelector)
  const reminderDataUpdate = useSelector(reminderDataUpdateSelector)
  const [date, setDate] = useState<Date | undefined>(
    action?.alarm_date ? new Date(action.alarm_date) : undefined,
  )
  const [subject, setSubject] = useState('')
  const [reminderSelected, setReminderSelected] = useState<any>(
    action?.id ? action : null,
  )
  const [isSuccess, setIsSuccess] = useState<boolean>(true)
  const [isCreate, setIsCreate] = useState<boolean>(false)
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()

  const handleAcceptModal = () => {
    if (reminderSelected) {
      if (action?.id || reminderSelected?.id) {
        landIds.forEach((id) => {
          dispatch(
            updateReminder({
              id: reminderSelected?.id,
              landId: id,
              dateAlarm:
                date || new Date(reminderSelected?.alarm_date).toISOString(),

              subject,
            }),
          )
            .then(() => {
              setReminderSelected(null)
              setIsSuccess(true)
              setIsCreate(false)
            })
            .catch(() => {
              setIsSuccess(false)
              setIsCreate(false)
            })
        })
      } else {
        landIds.forEach((id) => {
          dispatch(addReminder({ landId: id, date, subject }))
            .then(() => {
              setReminderSelected(null)
              setIsSuccess(true)
              setIsCreate(true)
            })
            .catch(() => {
              setIsSuccess(false)
              setIsCreate(true)
            })
        })
      }
      // onClose()
    } else {
      setReminderSelected({})
    }
  }

  const handleClose = () => {
    setReminderSelected(null)
    onClose()
  }

  const handleCancel = () => {
    if (reminderSelected) {
      setReminderSelected(null)
    }
  }

  useEffect(() => {
    if (action?.alarm_date) {
      setDate(new Date(action.alarm_date))
    }
  }, [action])

  useEffect(() => {
    if (reminderSelected?.alarm_date) {
      setDate(new Date(reminderSelected.alarm_date))
    } else {
      setDate(new Date())
    }
  }, [reminderSelected])

  return (
    <Modal
      okText={
        reminderSelected ? t('common.save') : t('common.notes.addReminder')
      }
      cancelText={reminderSelected ? t('common.cancel') : ''}
      onOk={handleAcceptModal}
      onCancel={handleCancel}
      open={open}
      onClose={handleClose}
      fullScreen={!onDesktop}
      title={t('common.info.reminderIcon')}
      disableGutters
      loading={loadingReminder}
      maxWidth="lg"
    >
      {!reminderSelected && (
        <ReminderList setReminderSelected={setReminderSelected} />
      )}
      {reminderSelected && (
        <ReminderDetail
          reminder={reminderSelected}
          subject={subject}
          setSubject={setSubject}
          date={date}
          setDate={setDate}
        />
      )}
      <Snackbar
        open={!loadingReminder && (reminderDataUpdate || reminderDataAdd)}
      >
        {isCreate ? t('common.reminder.created') : t('common.reminder.updated')}
      </Snackbar>
      <Snackbar open={!loadingReminder && !isSuccess} error={!isSuccess}>
        {t('common.error.500')}
      </Snackbar>
    </Modal>
  )
}

export default RememberDialog
