/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { parseGeoJSON } from '@popety_io/popety-io-lib'

import { recalculateUnderexploitedScore, handleBuildings } from './extraActions'

import summaryService from './summaryService'

export const summaryMapLayers = [
  'land',
  'authorizedLimit',
  'restrictions',
  'buildings',
]

const initialState = {
  loading: false,
  land: undefined,
  selectedLayers: summaryMapLayers,
  cityGeoCenter: {},
  cityGeojson: {},
  landAnalysisHistory: [],
  loadingHistory: false,
  groupLandAnalysisHistory: [],
  loadingGroupLandHistory: false,
}

export const getLandSummary = createAsyncThunk(
  'summary/getLandSummary',
  summaryService.getLandSummary,
)

export const getLandAdditionalData = createAsyncThunk(
  'summary/getLandAdditionalData',
  summaryService.getLandAdditionalData,
)

export const getCityGeojson = createAsyncThunk(
  'summary/getCityGeojson',
  summaryService.getCityGeojson,
)

export const getLandAnalysisHistory = createAsyncThunk(
  'summary/getLandAnalysisHistory',
  summaryService.getLandAnalysisHistory,
)

export const getGroupLandAnalysisHistory = createAsyncThunk(
  'summary/getGroupLandAnalysisHistory',
  summaryService.getGroupLandAnalysisHistory,
)

const summary = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    updateSummaryMapLayers: (state, action) => {
      state.selectedLayers = action.payload
    },
    recalculateScore: recalculateUnderexploitedScore,
    handleEditedBuildings: handleBuildings,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLandSummary.pending, (state) => {
        state.loading = true
      })
      .addCase(getLandSummary.rejected, (state) => {
        state.loading = false
        state.land = undefined
      })
      .addCase(getLandSummary.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.land = data
        state.loading = false
      })
      .addCase(getCityGeojson.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.cityGeoCenter = parseGeoJSON(data?.[0]?.geojson_center)
        state.cityGeojson = parseGeoJSON(data?.[0]?.geojson_polygon)
      })
      .addCase(getLandAdditionalData.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          const rdppfs = data?.rdppfs?.map((rdppf, i) => ({
            ...rdppf,
            title: `Restr. ${i + 1}`,
          }))

          state.land = { ...state.land, ...data, rdppfs }
        }
      })
      .addCase(getLandAnalysisHistory.pending, (state) => {
        state.loadingHistory = true
      })
      .addCase(getLandAnalysisHistory.rejected, (state) => {
        state.loadingHistory = false
      })
      .addCase(getLandAnalysisHistory.fulfilled, (state, action) => {
        const { data } = action.payload || []

        state.loadingHistory = false
        state.landAnalysisHistory = data
      })
      .addCase(getGroupLandAnalysisHistory.pending, (state) => {
        state.loadingGroupLandHistory = true
      })
      .addCase(getGroupLandAnalysisHistory.rejected, (state) => {
        state.loadingGroupLandHistory = false
      })
      .addCase(getGroupLandAnalysisHistory.fulfilled, (state, action) => {
        const { data } = action.payload || []

        state.loadingGroupLandHistory = false
        state.groupLandAnalysisHistory = data.data || []
      })
  },
})

export const summaryReducer = {
  summary: summary.reducer,
}

export const {
  recalculateScore,
  updateSummaryMapLayers,
  handleEditedBuildings,
} = summary.actions
