import {
  createSlice,
  PayloadAction,
  SerializedError,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { SearchHistory } from '../../components/LandSearch/LandSearch.util'
import searchHistoryService from './searchHistoryService'

interface SearchHistoryState {
  history: SearchHistory[] | []
  isLoading: boolean
  error: SerializedError | null
}

const initialState: SearchHistoryState = {
  history: [],
  isLoading: false,
  error: null,
}

export const getLandSearchHistory = createAsyncThunk(
  'searchHistory/getLandSearchHistory',
  async () => {
    const response = await searchHistoryService.getLandSearchHistory()

    return response
  },
)

export const setSearchHistory = createAsyncThunk(
  'searchHistory/setSearchHistory',
  async (data: any) => {
    const response = await searchHistoryService.setSearchHistory(data)

    return response
  },
)

const searchHistory = createSlice({
  name: 'searchHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLandSearchHistory.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(
        getLandSearchHistory.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false
          state.history = action.payload.data || {}
        },
      )
      .addCase(setSearchHistory.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(setSearchHistory.fulfilled, (state) => {
        state.isLoading = false
      })
  },
})

export const searchHistoryReducer = {
  searchHistory: searchHistory.reducer,
}
