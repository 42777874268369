import { groupBy } from '@popety_io/popety-io-lib'
import { createSelector } from '@reduxjs/toolkit'

import { getUserId } from '../../../../services'

const userId = getUserId()

const tagsByLandIdSelector = (state) => state.tag.tagsByLandId

const dashboardTagsSelector = (state) => state.tag.dashboardTags

const companyDashboardTagsSelector = (state) => state.tag.companyDashboardTags

const refetchFlagSelector = (state) => state.tag.refetchFlag

const hasTagsSelector = createSelector(
  tagsByLandIdSelector,
  (tag) => Object.keys({ ...tag }).length,
)

const tagsSelector = createSelector(tagsByLandIdSelector, (tagsByLandId) =>
  Object.values({ ...tagsByLandId })
    .flat()
    .filter((tg) => tg?.value && tg.active),
)

// DONT DELETE IT
const tagsByTagsIdSelector = createSelector(tagsSelector, (tags) =>
  Object.values(groupBy('tag_id', tags)).map((tag) => {
    return {
      color: tag?.[0]?.color,
      land_id: tag.map((t) => Number(t.land_id)),
    }
  }),
)

const filterTagsSelector = createSelector(tagsSelector, (tags) =>
  tags.filter(
    (value, index, self) =>
      index === self.findIndex((tg) => tg.land_id === value.land_id),
  ),
)

const uniqueTagsSelector = createSelector(tagsSelector, (tags) =>
  tags.filter(
    (value, index, self) =>
      index === self.findIndex((tg) => tg.value === value.value),
  ),
)

const sharedTagsSelector = createSelector(tagsSelector, (tags) =>
  tags.filter((t) => String(t?.author) !== userId).map((t) => t.tag_id),
)

const myTagsSelector = createSelector(tagsSelector, (tags) =>
  tags.filter((tg) => String(tg.user_id) === userId),
)

const myActiveTagsSelector = createSelector(myTagsSelector, (tags) =>
  tags.filter((tg) => tg?.active),
)

const activeTagsSelector = createSelector(tagsSelector, (tags) =>
  tags.filter((tg) => tg?.active),
)

const listTagsSelector = createSelector(
  (state) => state.tag.listTags,
  (listTags) =>
    listTags.map((tag) => ({ ...tag, tag_id: tag.id, active: true })),
)

const legendTagsSelector = (state) => state.tag.legendTags

const tagsAreLoadingSelector = (state) => state.tag.loading

// const landTagsSelector = (state) => state.tag.landTags

export {
  tagsByLandIdSelector,
  hasTagsSelector,
  sharedTagsSelector,
  myTagsSelector,
  myActiveTagsSelector,
  tagsSelector,
  activeTagsSelector,
  uniqueTagsSelector,
  filterTagsSelector,
  tagsByTagsIdSelector,
  dashboardTagsSelector,
  listTagsSelector,
  companyDashboardTagsSelector,
  refetchFlagSelector,
  legendTagsSelector,
  tagsAreLoadingSelector,
  // landTagsSelector,
}
