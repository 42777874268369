import Box, { BoxProps } from '@mui/material/Box'
import { Slide } from '@mui/material'
import React from 'react'
import EventHistoryHeader from './EventHistoryHeader'
import EventHistoryImages from './EventHistoryImages'
import EventHistoryDetails from './EventHistoryDetails'
import EventHistoryDescription from './EventHistoryDescription'
import EventHistoryFeatures from './EventHistoryFeatures'
import EventHistoryMainInformation from './EventHistoryMainInformation'

export type ListingListProps = {
  event: Record<string, any>
  setEvent: React.Dispatch<React.SetStateAction<Record<string, any>>>
} & BoxProps

const EventHistoryDetail = ({ event, ...other }: ListingListProps) => {
  return (
    <Box width="100%" {...other}>
      <Slide
        direction="left"
        timeout={1000}
        in={!!(event?.id || event?._id)}
        style={{
          width: '100%',
        }}
      >
        <Box>
          <Box
            data-cy="land-detail-accordionDetails"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <EventHistoryHeader event={event} />
            <EventHistoryImages event={event} />
            <EventHistoryMainInformation event={event} />
            <EventHistoryDetails event={event} />
            <EventHistoryFeatures event={event} />
            <EventHistoryDescription event={event} />
          </Box>
        </Box>
      </Slide>
    </Box>
  )
}

export default EventHistoryDetail
