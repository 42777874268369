/* eslint-disable max-lines */
import { Map, useRouter } from '@popety_io/popety-io-lib'
import { useEffect, useRef, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  sunDateSelector,
  showSunExposureSelector,
  sunlightTimesSelector,
  customSunlightBuildingSelector,
  landSelector,
} from '../../redux'
import SunDirection from '../SunDirection'
import SunExposure from '../SunExposure/SunExposure'
import { LINES } from '../SunDirection/SunDirection.util'
import {
  buildingSelector,
  configurationSelector,
  roofPolygonSelector,
} from '../../../BuildingDetails/redux'
import { getBuildingLayer } from '../../../BuildingDetails/BuildingDetailsMap/BuildingDetailsMap.utils'
import { getBuildingTiles } from './BuildingTile.util'
import { buildShadeMap, generateProfile } from './Shadow.util'

const BuildingShadow = ({ map, loaded }: { map: Map; loaded?: boolean }) => {
  const shadeMapRef = useRef<any>()
  const sunlightTimes = useSelector(sunlightTimesSelector)
  const customSunlightBuilding = useSelector(customSunlightBuildingSelector)

  const { query } = useRouter()
  const sunDate = useSelector(sunDateSelector)
  const showSunExposure = useSelector(showSunExposureSelector)
  const land: any = useSelector(landSelector)
  const building = useSelector(buildingSelector)
  const [center, setCenter] = useState({
    lat: land?.geo_center?.lat,
    lng: land?.geo_center?.lon,
  })
  const roofPolygon = useSelector(roofPolygonSelector)
  const configuration = useSelector(configurationSelector)

  const location = useLocation()

  const isSunlightTab = query.tab === 'sunlight' || query.subTab === 'sunlight'
  const issunExposerGraphTab = query.sunLightTab === 'sunExposerGraph'

  const legendIsDisplayed = query.displaySunLegend === 'true'

  const buildingLayer = useMemo(() => {
    if (location.pathname === '/land-detail/building') {
      return getBuildingLayer(
        building,
        query?.energyLayer,
        query?.tab,
        query?.buildingHeight,
        query?.egid,
        roofPolygon,
        configuration,
        query?.dimension,
        query?.subTab,
      )
    }

    return undefined
  }, [building, query?.tab, query?.subTab, query?.buildingHeight])

  useEffect(() => {
    if (map) {
      map.on('moveend', () => {
        setCenter({ ...map.getCenter() })
      })
    }
  }, [map])

  const addShadeMap = () => {
    try {
      if (building?.id) {
        getBuildingTiles({ map, isSunlightTab, buildingId: building.id })
      } else {
        getBuildingTiles({ map, isSunlightTab })
      }

      if (!isSunlightTab) {
        shadeMapRef.current?.remove()

        return
      }

      if (shadeMapRef.current) {
        shadeMapRef.current?.remove()
      }

      shadeMapRef.current = buildShadeMap({
        map,
        sunDate,
        customSunlightBuilding,
        buildingDetails:
          buildingLayer && buildingLayer.length ? [buildingLayer[0]] : [],
      })

      shadeMapRef.current?.setTerrainSource({
        getSourceUrl: (params: { x: number; y: number; z: number }) => {
          const { x, y, z }: any = params

          return `https://pub-042f8c71c32b4422a6a445f9ecaf6a55.r2.dev/${z}/${x}/${y}.webp`
        },
        getElevation: (params: { r: number; g: number; b: number }) => {
          const { r, g } = params

          return ((r * 256 + g) / 2 ** 16) * 4808
        },
        tileSize: 512,
        maxZoom: 17,
        _overzoom: 18,
      })

      shadeMapRef.current?.setSunExposure(false)

      shadeMapRef.current?.setSunExposure(showSunExposure, {
        startDate: sunlightTimes?.sunrise,
        endDate: sunlightTimes?.sunset,
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (
      !map ||
      !shadeMapRef ||
      !issunExposerGraphTab ||
      !query.mapLg ||
      !legendIsDisplayed
    )
      return

    generateProfile({
      map,
      shadeMapRef,
      mapCenter: center,
    })
  }, [
    issunExposerGraphTab,
    map,
    loaded,
    query.mapLg,
    center,
    shadeMapRef,
    legendIsDisplayed,
  ])

  useEffect(() => {
    if (!map || !loaded) return

    addShadeMap()

    map.on('style.load', addShadeMap)

    return () => {
      map.off('style.load', addShadeMap)
    }
  }, [isSunlightTab, loaded, customSunlightBuilding, building, buildingLayer])

  useEffect(() => {
    if (shadeMapRef.current) {
      shadeMapRef.current.setDate(new Date(sunDate))

      // force shaedMap exposure update
      if (showSunExposure) {
        shadeMapRef.current?.setSunExposure(false)

        shadeMapRef.current?.setSunExposure(true, {
          startDate: sunlightTimes.sunrise,
          endDate: sunlightTimes.sunset,
        })
      }
    }
  }, [sunDate, sunlightTimes])

  useEffect(() => {
    if (!isSunlightTab && map) {
      try {
        if (map?.getSource('tileSource')) {
          map.removeSource('tileSource')
        }
        if (map?.getLayer('tileSource')) {
          map.removeLayer('tileSource')
        }

        for (const line of LINES) {
          if (map?.getLayer(line.id)) {
            map.removeLayer(line.id)
          }
          if (map?.getSource(line.id)) {
            map.removeSource(line.id)
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
  }, [query.tab, query?.subTab])

  if (!isSunlightTab || !map || !loaded) return null

  return (
    <>
      <SunDirection map={map} />
      <SunExposure map={map} shadeMap={shadeMapRef.current} />
    </>
  )
}

export default BuildingShadow
