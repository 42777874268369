import { toQueryParams } from '@popety_io/popety-io-lib'

const getRadiusQuery = ({ feature }: { feature: Record<string, any> }) => {
  try {
    let address = feature?.address_full

    if (feature?.egid) {
      address = feature?.addresses?.[0]
    }

    const lat = feature?.geo_center?.lat
    const lon = feature?.geo_center?.lon

    const search = {
      ...(lat !== undefined && { lat }),
      ...(lon !== undefined && { lon }),
      type: 'Radius',
      address,
      radius: 0.1,
      open: true,
    }

    return toQueryParams(search, (v) => v != null)
  } catch (error) {
    console.error(error)

    return undefined
  }
}

export { getRadiusQuery }
