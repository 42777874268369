import { DEFAULT_MAPSTYLE } from '../../../../config'

export interface IBuildOption extends Record<string, any> {
  value: string
  active: boolean
  title: string
  src: string
}

export const buildOptions = (
  t: Translator,
  mapStyle: string,
  layout?: boolean,
) =>
  [
    {
      value: layout
        ? 'popety/cl3a7v8hy001y14pejeo2af8o'
        : 'popety/cl3jyrrml000614n6aelcet8c',
      active:
        mapStyle === 'popety/cl3jyrrml000614n6aelcet8c' ||
        mapStyle === DEFAULT_MAPSTYLE ||
        mapStyle === 'popety/cl3a7v8hy001y14pejeo2af8o',
      title: t('search.basemap.default'),
      src: '/assets/img/default_map.png',
      label: 'default',
    },
    {
      value: layout
        ? 'popety/clfi67w71001601mpthh1rl52'
        : 'popety/cl449l1ah000414umwqgcr0pd',
      active:
        mapStyle === 'popety/cl449l1ah000414umwqgcr0pd' ||
        mapStyle === 'popety/clfi67w71001601mpthh1rl52',
      title: t('search.basemap.satellite'),
      src: '/assets/img/satellite.png',
      label: 'satellite',
    },
    {
      value: 'popety/cl9mkt6w3002714rsqw7qsz7g',
      active: mapStyle === 'popety/cl9mkt6w3002714rsqw7qsz7g',
      title: t('search.basemap.ofs'),
      src: '/assets/img/OFS.png',
      style:
        mapStyle === 'popety/cl9mkt6w3002714rsqw7qsz7g'
          ? {
              boxShadow:
                '0px 2px 4px -1px #00ade6, 0px 4px 5px 0px #00ade6, 0px 1px 10px 0px #00ade6',
            }
          : { boxShadow: 'none' },
      badge: true,
      label: 'ofs',
    },
  ].filter(Boolean) as Array<IBuildOption>
