/* eslint-disable max-lines */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TransactionIcon from '@mui/icons-material/CurrencyExchange'
import FaoIcon from '@mui/icons-material/Construction'
import ListingIcon from '@mui/icons-material/LocalOffer'

import { useI18n } from '@popety_io/popety-io-lib'
import { regionsByNameSelector } from '../../LocationsInput'
import {
  getLandFaos,
  getLandTransactions,
  landFaoCountSelector,
  landTransactionCountSelector,
} from '../redux'
import {
  DivBody,
  DivContainer,
  HistoryLink,
  SinceSpan,
  StyledSpan,
} from './History.style'

export type HistoryProps = {
  data?: {
    id?: string
    transaction_number?: number
    building_permit_number?: number
    transaction_last_year?: number | null
    building_permit_last_year?: number | null
    lands_building_permit_number?: number
    listing_rent_number: number | null
    region_name: string
    geoPolygon?: any
    lands?: any[]
    egid?: number
    land_id?: number
    click?: boolean
    listing_sale_number?: number
    lands_transaction_number?: number
    transactionsIds?: number[]
    buildingPermitsIds?: number[]
    listingsIds?: number[]
    building_permits?: number[]
    transactions?: number[]
    listings?: number[]
  }
  sx?: any
}

const History = ({ data, ...other }: HistoryProps) => {
  const { t } = useI18n()

  const building = !!data?.egid

  const regionsByName = useSelector(regionsByNameSelector)
  const region = data?.region_name as string

  const first_fao_date = regionsByName[region]?.first_fao_date
  const first_transaction_date = regionsByName[region]?.first_transaction_date

  const landId: any = data?.land_id
  const ids = data?.land_id

  const isGrouped = building ? false : Array.isArray(landId)

  // Certaines mises à l'enquete peuvent concerner plusieurs lands, or l'api ne fait qu'additionner ces nombres,
  // ce qui peut etre faux dans le cas de certains regroupement parcelaires. D'ou on fetch les histories pour
  // les regroupement parcelaire pour etre sur d'avoir le total exact

  const groupedFaos = useSelector(landFaoCountSelector)
  const groupedTransactions = useSelector(landTransactionCountSelector)

  const faos = isGrouped
    ? groupedFaos
    : data?.building_permit_number || data?.lands_building_permit_number || 0

  const transactions = isGrouped
    ? groupedTransactions
    : data?.transaction_number || data?.lands_transaction_number || 0

  const dispatch = useDispatch<any>()

  useEffect(() => {
    if (isGrouped && landId?.length && !building) {
      dispatch(getLandFaos({ landId }))
      dispatch(getLandTransactions({ landId }))
    }
  }, [landId, isGrouped])

  const buildingPermitsIds =
    data?.buildingPermitsIds || data?.building_permits?.map((b: any) => b?.id)

  const transactionsIds =
    data?.transactionsIds || data?.transactions?.map((b: any) => b?.id)

  const rentListingsIds = data?.listingsIds?.length
    ? data?.listingsIds
    : data?.listings
        ?.filter((b: any) => (b?.id || b?.listing_id) && b.deal_type === 'rent')
        ?.map((b: any) => b?.id || b?.listing_id)

  const purchaseListingsIds =
    data?.listingsIds ||
    data?.listings
      ?.filter(
        (b: any) => (b?.id || b?.listing_id) && b.deal_type === 'purchase',
      )
      ?.map((b: any) => b?.id || b?.listing_id)

  return (
    <DivContainer {...other}>
      <StyledSpan sx={{ paddingBottom: '10px' }}>
        {t('common.search.details.history')}
      </StyledSpan>
      <DivBody>
        {(first_transaction_date?.first_transaction_date ||
          first_transaction_date) && (
          <HistoryLink
            $active={!!transactions}
            target="_blank"
            rel="noopener noreferrer"
            href={`/transaction-history?${
              transactionsIds ? `id=${transactionsIds}` : `landId=${ids}`
            }`}
          >
            <div className="iconText">
              <TransactionIcon />
              <span>{transactions || 0}</span>
            </div>
            <span className="textAlign">
              {t('common.search.details.transaction')}
            </span>
            <SinceSpan>
              {t('common.search.details.since')}{' '}
              {new Date(
                first_transaction_date?.first_transaction_date ||
                  first_transaction_date,
              )?.getFullYear()}
            </SinceSpan>
          </HistoryLink>
        )}
        <HistoryLink
          $active={!!faos}
          target="_blank"
          rel="noopener noreferrer"
          href={`/fao-history?${
            buildingPermitsIds ? `id=${buildingPermitsIds}` : `landId=${ids}`
          }`}
        >
          <div className="iconText">
            <FaoIcon />
            <span>{faos || 0}</span>
          </div>
          <span className="textAlign">{t('common.search.details.fao')}</span>
          {(first_fao_date || first_fao_date?.first_fao_date) && (
            <SinceSpan>
              {t('common.search.details.since')}{' '}
              {new Date(first_fao_date).getFullYear()}
            </SinceSpan>
          )}
        </HistoryLink>
        <HistoryLink
          $active={!!data?.listing_rent_number}
          target="_blank"
          rel="noopener noreferrer"
          href={`/listing-history?${
            rentListingsIds ? `id=${rentListingsIds}` : `landId=${ids}`
          }&dealType=rent`}
        >
          <div className="iconText">
            <ListingIcon />
            <span>{data?.listing_rent_number || 0}</span>
          </div>
          <span className="textAlign">
            {t('common.search.listingAnnouncement')}
          </span>
          <SinceSpan>{t('common.search.details.since')} 2005</SinceSpan>
        </HistoryLink>{' '}
        <HistoryLink
          $active={!!data?.listing_sale_number}
          target="_blank"
          rel="noopener noreferrer"
          href={`/listing-history?${
            purchaseListingsIds ? `id=${purchaseListingsIds}` : `landId=${ids}`
          }&dealType=purchase`}
        >
          <div className="iconText">
            <ListingIcon />
            <span>{data?.listing_sale_number || 0}</span>
          </div>
          <span className="textAlign">
            {t('common.search.saleAnnouncement')}
          </span>
          <SinceSpan>{t('common.search.details.since')} 2005</SinceSpan>
        </HistoryLink>
      </DivBody>
    </DivContainer>
  )
}

export default History
