/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import { useState, useEffect, Suspense, useMemo, useRef } from 'react'
import Box from '@mui/material/Box'
import {
  FullscreenButton,
  ProgressIndicator,
  useRouter,
  useI18n,
  fetchOnce,
} from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import { userSelector } from '../Login'
import { regionsByNameSelector } from '../../components/LocationsInput'
import { getCurrentUserDetails } from '../Account/redux'
import {
  getLandSummary,
  landIdStringSelector,
  landRegionSelector,
} from './redux'
import LandDetailsMap from './LandDetailsMap'
import LandSearch from './components/LandSearch'
import { getLandDetailsMenuData } from './components/LandDetailsMenu'
import useGetLandDetailsData from './LandDetails.data'

import {
  LandDetailsRoot,
  LeftContainer,
  RightContainer,
} from './LandDetails.style'

const LandDetails = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()
  const landIdStr = useSelector(landIdStringSelector)
  const [openSearch, setOpenSearch] = useState(!!query?.openNewAnalysis)

  const leftContainerRef = useRef<HTMLDivElement>()
  const rightContainerRef = useRef<HTMLDivElement>()

  const dispatch: any = useDispatch()

  const landRegion = useSelector(landRegionSelector)
  const regionsByName = useSelector(regionsByNameSelector)

  const firstTransactionDate = regionsByName[landRegion]?.first_transaction_date
  const firstFaoDate = regionsByName[landRegion]?.first_fao_date
  const user = useSelector(userSelector)
  const isHomePage = query?.tab === 'home' || query?.tab === 'grouping'
  const location = useLocation()

  useGetLandDetailsData()

  const canAnalyse = useMemo(() => {
    return (
      user.can_exceed_max_analysis ||
      Number(user.analysis_counter) < Number(user?.analysis_nb) ||
      (user?.listsAnalysis && user?.listsAnalysis?.includes(landIdStr))
    )
  }, [user])

  const pages = getLandDetailsMenuData(
    t,
    firstTransactionDate,
    firstFaoDate,
    user?.company_id === '1' ||
      user?.company_id === '167' ||
      user?.company_id === '419' /* POPETY company id */,
    canAnalyse,
    isHomePage,
  )

  const content = useMemo(() => {
    if (!query || !pages?.length) return

    if (query?.subTab) {
      return pages
        ?.find((p: any, i: number) =>
          !query?.tab
            ? i === 0
            : p?.key === query?.tab ||
              p?.subMenus?.find((s: any) => s?.key === query?.tab),
        )
        ?.subMenus?.find((s: any) => s?.key === query?.subTab)?.page
    }

    const page: any = pages
      .filter((menu: any) => menu?.show || menu?.key === 'history')
      .find((p: any, i: number) =>
        !query.tab
          ? i === 0
          : isHomePage
            ? p?.key === query?.tab && location.pathname === p?.path
            : p?.key === query?.tab,
      )

    if (page?.subMenus?.length && !query?.subTab) {
      updateQuery({
        subTab: page?.subMenus?.[0]?.key,
      })
    }

    return page?.page
  }, [query?.tab, query?.subTab, pages])

  const fullscreen = query.fs === '1'

  const handleClose = () => {
    updateQuery({ openNewAnalysis: undefined })
  }

  const handleToggle = () => {
    updateQuery({ fs: !fullscreen ? '1' : undefined })
  }

  useEffect(() => {
    setOpenSearch(!!query?.openNewAnalysis)
  }, [query?.openNewAnalysis])

  useEffect(() => {
    if (query.landId) {
      fetchOnce(
        'landDetailAnalysis',
        {
          landId: query.landId,
          canAnalyse,
        },
        () => dispatch(getLandSummary({ landId: query.landId, canAnalyse })),
      )
    }
  }, [query.landId, canAnalyse])

  useEffect(() => {
    fetchOnce('getCurrentUserDetails', user?.id, () => {
      dispatch(getCurrentUserDetails())
    })
  }, [user?.id])

  useEffect(() => {
    if (leftContainerRef.current) {
      const rect = leftContainerRef.current.getBoundingClientRect()

      if (rightContainerRef.current) {
        rightContainerRef.current.style.top = `${rect.top}px`
      }
    }
  }, [landIdStr])

  return (
    <LandDetailsRoot bgcolor="background.paper">
      <LandSearch
        showCloseIcon={!!landIdStr}
        open={openSearch}
        onClose={handleClose}
      />
      <Box
        height="100%"
        display="flex"
        justifyContent={{ xs: 'flex-end', lg: 'flex-start' }}
        flexDirection={{ xs: 'column-reverse', lg: 'row' }}
        ref={leftContainerRef}
      >
        <LeftContainer
          bgcolor="background.paper"
          width={{ xs: '100%', lg: '58%' }}
          display={fullscreen ? 'none' : undefined}
          data-cy="land-detail-left-container"
        >
          <Suspense fallback={<ProgressIndicator circular />}>
            {content}
          </Suspense>
        </LeftContainer>
        <RightContainer
          width={{
            xs: '100%',
            lg: fullscreen ? 'calc(100% - 105px)' : '42%',
          }}
          ref={rightContainerRef}
          // position={{ xs: 'static', lg: 'fixed' }}
        >
          <Box position="relative" height="100%">
            {content && (
              <FullscreenButton
                fullScreen={fullscreen}
                onClick={handleToggle}
              />
            )}
            <LandDetailsMap fullscreen={fullscreen} />
          </Box>
        </RightContainer>
      </Box>
    </LandDetailsRoot>
  )
}

export default LandDetails
