/* eslint-disable max-lines */
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  SwipeableTabs,
  useI18n,
  useRouter,
  Driver,
  fetchOnce,
} from '@popety_io/popety-io-lib'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import { Badge, Divider } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

import HistoryAroundFilters from '../components/HistoryAroundFilters'
import {
  faosAroundCountSelector,
  faosAroundSelector,
  landFaosSelector,
  faoStatsSelector,
  faosPercentSelector,
  landFaosLoadingSelector,
} from '../redux'
import { userSelector } from '../../Login'
import { updateTutorials } from '../../Account'
import { faosTileDetailsSelector } from '../../FaoHistory'
import getDriverInstructions from './driverInstructions'
import FaoList from './FaoList'
import FaoStats from './FaoStats'
import FaoDetail from './FaoDetail'

interface IProps {
  show?: boolean
}

const Fao = ({ show = true }: IProps) => {
  const { query, updateQuery } = useRouter()
  const dispatch = useDispatch<any>()

  const faos = useSelector(landFaosSelector)
  const faosAround = useSelector(faosAroundSelector)
  const faosAroundCount = useSelector(faosAroundCountSelector)
  const faosStatsData = useSelector(faoStatsSelector)
  const faosPercent = useSelector(faosPercentSelector)
  const loading = useSelector(landFaosLoadingSelector)
  const user = useSelector(userSelector)
  const faosTilesDetail = useSelector(faosTileDetailsSelector)

  const [opened, setOpened] = useState<string>()
  const [displayDriver, setDisplayDriver] = useState(false)
  const [fao, setFao] = useState<Record<string, any>>({})
  const [swipeableTabs, setSwipeableTabs] = useState<any>(0)

  const { t } = useI18n()

  useEffect(() => {
    if (faos?.length) {
      setOpened(`fao${faos?.[0]?.id}`)
    }
  }, [faos])

  // const tabs = ['fao', 'stats']
  //   .map((value) => ({
  //     label: t(`land.history.tab.${value}`),
  //     key: value,
  //   }))
  //   .filter((value) => show || (!show && value.key !== 'stats'))

  const tabFao = [
    {
      label: t('land.fao.title'),
      count: faos?.length,
    },
    { label: t('land.faoAround'), count: faosAroundCount },
    { label: t('land.history.tab.stats') },
  ]
    .slice(show ? 0 : 1)
    .map((value) => ({
      label: (
        <Badge
          badgeContent={value.count}
          color="primary"
          sx={{
            '& .MuiBadge-badge': { top: -2 },
            paddingTop: 1,
          }}
          showZero
        >
          {value.label}
        </Badge>
      ),
      key: value.label,
    }))

  const handleChange = (fao: Record<string, any>, isExpanded?: boolean) => {
    const id = fao.id ? fao.id : fao.fao_id
    const uid = isExpanded ? `fao${id}` : undefined

    setOpened(uid)
    setFao(fao || {})
    updateQuery({
      faoOpened: uid,
    })
  }

  const handleTabChangeParent = (tab: any) => {
    setFao({})
    setSwipeableTabs(tab)
  }

  const handleBackListFao = () => {
    setFao({})
    updateQuery({
      faoId: null,
    })
  }

  useEffect(() => {
    if (loading) return
    if (swipeableTabs === 2) return
    if (swipeableTabs === 1 && faosAroundCount) return
    if (swipeableTabs === 0 && faos?.length) return

    let tab = swipeableTabs

    if (faos?.length) {
      tab = 0
    } else if (!faos?.length && faosAroundCount) {
      tab = 1
    }

    setSwipeableTabs(tab)

    setSwipeableTabs(tab)
  }, [faos?.length, faosAroundCount, loading])

  useEffect(() => {
    if (query?.faoOpened) {
      setOpened(query?.faoOpened)
    }
  }, [query?.faoOpened])

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedFaoDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedFaoDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  useEffect(() => {
    if (query?.faoId) {
      const faoSelected = faosTilesDetail?.find(
        (item: any) => String(item?._id || item?.id) === String(query?.faoId),
      )

      if (faoSelected) {
        setFao(faoSelected)

        const { faoId } = query

        if (faos?.find((item: any) => String(item.id) === String(faoId))) {
          setSwipeableTabs(0)
        } else if (
          faosAround?.find((item: any) => String(item.id) === String(faoId))
        ) {
          setSwipeableTabs(1)
        } else {
          setSwipeableTabs(0)
        }
      }
      if (faoSelected && faoSelected?.location) {
        const faoCenter = faoSelected?.location
          ?.substring(6)
          ?.replace(/^\((.+)\)$/, '$1')
          ?.replace(' ', ',')

        updateQuery({ faoCenter, title: faoSelected.work_description })
      } else {
        updateQuery({ faoCenter: undefined, title: undefined })
      }
    }
  }, [query?.faoId, faosTilesDetail])

  if (!show) {
    return (
      <Box zIndex={10} bgcolor="background.paper">
        <Box
          sx={{
            position: {
              lg: 'sticky',
            },
            top: {
              lg: 0,
            },
            zIndex: 2,
            backgroundColor: 'rgb(255, 255, 255)',
            paddingTop: 2,
          }}
        >
          <Box
            sx={{
              marginBottom: fao?.id ? '0px' : '8px',
            }}
          >
            <Typography variant="h2" fontSize="1.7rem" fontWeight={500}>
              {t('common.building_permit')}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#969696',
                paddingBottom: '10px',
              }}
              fontSize="1rem"
            >
              {t('common.building_permit.header_description')}
            </Typography>
          </Box>
          {!(fao?.id || fao?._id) && <HistoryAroundFilters />}
          {!(fao?.id || fao?._id) && (
            <Divider
              sx={{
                marginTop: 2,
              }}
            />
          )}
          {fao?.id || fao?._id ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                cursor: 'pointer',
              }}
              onClick={handleBackListFao}
            >
              <ArrowBackIosIcon
                sx={{
                  width: '0.8em',
                  height: '0.8em',
                }}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '1.1rem',
                }}
              >
                {t('estimate.comparables.retour à la liste')}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        {loading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {Array.from({ length: 10 }).map((_, i) => (
              <Box
                key={i}
                sx={{
                  width: '100%',
                  height: '45px',
                  mt: 1.5,
                }}
              >
                <Skeleton
                  variant="rounded"
                  sx={{
                    fontSize: '1rem',
                    borderRadius: 2,
                    height: '45px',
                    width: '100%',
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <>
            <FaoDetail fao={fao} setFao={setFao} />
            <FaoList
              faos={faosAround}
              opened={opened}
              onChange={handleChange as any}
              faoSelected={fao}
            />
          </>
        )}
      </Box>
    )
  }

  return (
    <Box px={1}>
      <SwipeableTabs
        divider={!(fao?.id || fao?._id)}
        fixedTabs
        tabs={tabFao}
        value={swipeableTabs}
        handleTabChange={handleTabChangeParent}
        header={
          <Box position="sticky" top={0} zIndex={10} bgcolor="background.paper">
            <Box>
              <Typography variant="h2" fontSize="1.7rem" fontWeight={500}>
                {t('common.building_permit')}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: '#969696',
                  paddingBottom: '10px',
                }}
                fontSize="1rem"
              >
                {t('common.building_permit.header_description')}
              </Typography>
            </Box>
          </Box>
        }
        filter={
          !(fao?.id || fao?._id) && swipeableTabs !== 0 ? (
            <Box
              pt={2}
              pb={1}
              zIndex={10}
              bgcolor="background.paper"
              sx={{
                position: {
                  lg: 'sticky',
                },
                top: {
                  lg: 0,
                },
              }}
            >
              <HistoryAroundFilters />
            </Box>
          ) : (
            <></>
          )
        }
        otherHeaderComponent={
          fao?.id || fao?._id ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                cursor: 'pointer',
                marginTop: 1,
              }}
              onClick={handleBackListFao}
            >
              <ArrowBackIosIcon
                sx={{
                  width: '0.8em',
                  height: '0.8em',
                }}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '1.1rem',
                }}
              >
                {t('estimate.comparables.retour à la liste')}
              </Typography>
            </Box>
          ) : (
            <></>
          )
        }
      >
        {loading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {Array.from({ length: 10 }).map((_, i) => (
              <Box
                key={i}
                sx={{
                  width: '100%',
                  height: '45px',
                  mt: 1.5,
                }}
              >
                <Skeleton
                  variant="rounded"
                  sx={{
                    fontSize: '1rem',
                    borderRadius: 2,
                    height: '45px',
                    width: '100%',
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box>
            <FaoDetail fao={fao} setFao={setFao} />
            <FaoList
              faos={faos}
              opened={opened}
              onChange={handleChange as any}
              faoSelected={fao}
              show={show}
            />
          </Box>
        )}
        {loading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {Array.from({ length: 10 }).map((_, i) => (
              <Box
                key={i}
                sx={{
                  width: '100%',
                  height: '45px',
                  mt: 1.5,
                }}
              >
                <Skeleton
                  variant="rounded"
                  sx={{
                    fontSize: '1rem',
                    borderRadius: 2,
                    height: '45px',
                    width: '100%',
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box>
            <FaoDetail fao={fao} setFao={setFao} />
            <FaoList
              faos={faosAround}
              opened={opened}
              onChange={handleChange as any}
              faoSelected={fao}
              show={show}
            />
          </Box>
        )}
        {loading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {Array.from({ length: 10 }).map((_, i) => (
              <Box
                key={i}
                sx={{
                  width: '100%',
                  height: '45px',
                  mt: 1.5,
                }}
              >
                <Skeleton
                  variant="rounded"
                  sx={{
                    fontSize: '1rem',
                    borderRadius: 2,
                    height: '45px',
                    width: '100%',
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : (
          !(fao?.id || fao?._id) && (
            <FaoStats faosStatsData={faosStatsData} faosPercent={faosPercent} />
          )
        )}
      </SwipeableTabs>
      {!loading && (
        <Driver
          instructions={getDriverInstructions(t)}
          display={displayDriver}
        />
      )}
    </Box>
  )
}

export default Fao
