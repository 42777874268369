/* eslint-disable max-lines */
import React, { useEffect } from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import SellIcon from '@mui/icons-material/Sell'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent'
import { Notes, Star, Visibility } from '@mui/icons-material'
import { StyledEngineProvider } from '@mui/material/styles'
import { format } from 'date-fns'
import AddReminderIcon from '@mui/icons-material/AddAlarm'
import CircularProgress from '@mui/material/CircularProgress'
import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  FilledInput,
  Select,
  Skeleton,
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useI18n, useRouter } from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'
// eslint-disable-next-line import/no-cycle
import {
  getActivityHistoryLand,
  loadingActivitySelector,
} from '../../pages/Dashboard'
import {
  getActivityHistoryBuilding,
  loadingActivityBuildingSelector,
} from '../../pages/BuildingDashboard'

enum LAND_ACTIVITY {
  NOTE = 'Note',
  FAVORITE = 'Favorite',
  ANALYSIS = 'Analysis',
  REMINDER = 'Reminder',
  TAG = 'Tag',
  GROUP = 'Group',
  PROSPECT = 'Prospect',
  ESTIMATE = 'Estimate',
}

export enum CATEGORY_LAND_ACTIVITY {
  DELETE = 'Delete',
  CREATE = 'Create',
}

type IActivityProp = {
  activities: any[]
  countCategories: any
  buildingId?: any
  addressIds?: any
  landId?: string | string[]
  countActivity: any
}

const ActivityTimeline = ({
  activities,
  countCategories,
  buildingId,
  addressIds,
  landId,
  countActivity,
}: IActivityProp) => {
  const { t } = useI18n()
  const ref = React.useRef(null)
  const dispatch = useDispatch()
  const { query } = useRouter()
  const [indexSlice, setIndexSlice] = React.useState(100)
  const [loading, setLoading] = React.useState(false)
  const quantity = activities?.length || 0
  const location = useLocation()
  const isAnalysis = location.pathname?.includes('/land-detail/')
  const isLand =
    (query.type !== 'building' && !isAnalysis) ||
    location.pathname?.includes('plot')
  const [checked, setChecked] = React.useState<any>(
    isLand
      ? [
          { label: t('common.tags'), key: 'tags' },
          { label: t('common.details.notes'), key: 'notes' },
          { label: t('common.favorites'), key: 'favorites' },
          { label: t('common.Reminders'), key: 'reminders' },
        ]
      : [
          { label: t('common.tags'), key: 'tags' },
          { label: t('common.details.notes'), key: 'notes' },
          { label: t('common.favorites'), key: 'favorites' },
        ],
  )
  const loadingActivity = useSelector(loadingActivitySelector)
  const loadingActivityBuilding = useSelector(loadingActivityBuildingSelector)

  React.useEffect(() => {
    if (isLand) {
      setChecked([
        { label: t('common.tags'), key: 'tags' },
        { label: t('common.details.notes'), key: 'notes' },
        { label: t('common.favorites'), key: 'favorites' },
        { label: t('common.Reminders'), key: 'reminders' },
      ])
    } else {
      setChecked([
        { label: t('common.tags'), key: 'tags' },
        { label: t('common.details.notes'), key: 'notes' },
        { label: t('common.favorites'), key: 'favorites' },
      ])
    }
  }, [isLand])

  const handleScroll = () => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current

      if (
        scrollTop + clientHeight + 2 >= scrollHeight &&
        indexSlice < quantity
      ) {
        setLoading(true)

        return new Promise((r) => {
          setTimeout(() => {
            r(setIndexSlice((prev) => prev + 20))
          }, 200)
        }).then(() => {
          setLoading(false)
        })
      }
    }
  }

  useEffect(() => {
    const abort = new AbortController()
    const { signal } = abort

    if (isLand) {
      dispatch(
        getActivityHistoryLand({
          landId,
          addressIds,
          filterCategories: checked.map((item: any) => item.key),
          signal,
        }) as any,
      )
    } else {
      dispatch(
        getActivityHistoryBuilding({
          buildingId,
          addressIds,
          filterCategories: checked.map((item: any) => item.key),
          signal,
        }) as any,
      )
    }

    // return () => {
    //   abort.abort()
    // }
  }, [checked, landId, buildingId])

  const checkMarks = [
    {
      label: t('common.analysis'),
      count: countCategories?.analysis,
      key: 'analysis',
    },
    { label: t('common.tags'), count: countCategories?.tags, key: 'tags' },
    {
      label: t('common.details.notes'),
      count: countCategories?.notes,
      key: 'notes',
    },
    {
      label: t('common.favorites'),
      count: countCategories?.favorites,
      key: 'favorites',
    },
    {
      label: t('common.Estimates'),
      count: countCategories?.estimates,
      key: 'estimates',
    },
    isLand && {
      label: t('common.Reminders'),
      count: countCategories?.reminders,
      key: 'reminders',
    },
    isLand && {
      label: t('common.plotsGrouping'),
      count: countCategories?.groups,
      key: 'groups',
    },
  ].filter(Boolean)

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event

    const newValue = value.map((val: string) =>
      checkMarks.find((mark: any) => mark.label === val),
    )

    setChecked(newValue)
  }

  return (
    <div
      id="act"
      style={{
        maxHeight: countActivity > 0 ? 600 : 0,
        display: 'flex',
        justifyContent: 'center',
      }}
      ref={ref}
      onScroll={handleScroll}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 300,
        }}
      >
        {countActivity > 0 ? (
          <Box
            sx={{
              display: 'flex',
              position: 'sticky',
              top: 0,
              zIndex: 1000,
              background: '#fff',
              width: '100%',
            }}
          >
            <FormControl
              sx={{
                m: 1,
                width: '100%',
                maxWidth: 300,
              }}
            >
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                fullWidth
                value={checked.map((item: any) => item.label)}
                MenuProps={{ disableScrollLock: true }}
                sx={{ background: 'white', ':hover': { background: 'white' } }}
                onChange={handleChange as any}
                input={
                  <FilledInput
                    fullWidth
                    sx={{
                      transition: 'none',
                      background: 'white',
                      zIndex: 1000,
                    }}
                  />
                }
                renderValue={(selected: string[]) => selected.join(', ')}
              >
                {checkMarks.map((mark: any, indexMark: number) => (
                  <MenuItem key={indexMark} value={mark.label}>
                    <Checkbox
                      checked={checked.some(
                        (item: any) => item.key === mark.key,
                      )}
                    />
                    <ListItemText primary={`${mark.label} (${mark.count})`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ) : (
          ''
        )}
        <StyledEngineProvider>
          <Timeline
            id="activity-timeline"
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
            }}
            onScroll={handleScroll}
          >
            {loadingActivity || loadingActivityBuilding ? (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {Array.from({ length: 10 }).map((_, i) => (
                  <Box
                    key={i}
                    sx={{
                      width: '268px',
                      height: '45px',
                      mt: 1.5,
                    }}
                  >
                    <Skeleton
                      variant="rounded"
                      sx={{
                        fontSize: '1rem',
                        borderRadius: 2,
                        height: '45px',
                        width: '100%',
                      }}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              activities
                ?.slice(0, indexSlice)
                .map((activity: any, indexActivity: number) => {
                  if (
                    activity?.category === LAND_ACTIVITY.NOTE &&
                    checked.find((item: any) => item.key === 'notes')
                  ) {
                    return (
                      <TimelineItem key={indexActivity}>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                          style={{ fontSize: '10px' }}
                        >
                          {activity?.timestamp
                            ? format(
                                new Date(activity?.timestamp),
                                'dd.LL.yyyy',
                              )
                            : ''}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              backgroundColor:
                                activity?.type === CATEGORY_LAND_ACTIVITY.DELETE
                                  ? '#E63900'
                                  : '#8ED1A5',
                            }}
                          >
                            <Notes style={{ width: 12, height: 12 }} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <div>
                            <span style={{ fontSize: '12px', fontWeight: 500 }}>
                              {t('common.details.notes')}
                            </span>
                          </div>
                          <div style={{ color: '#969696', fontSize: '12px' }}>
                            {activity?.type === CATEGORY_LAND_ACTIVITY.DELETE
                              ? t('common.removed_by')
                              : t('common.added_by')}{' '}
                            <span style={{ fontWeight: 600 }}>
                              {activity?.name || ''}
                            </span>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  }
                  if (
                    activity?.category === LAND_ACTIVITY.FAVORITE &&
                    checked.find((item: any) => item.key === 'favorites')
                  ) {
                    return (
                      <TimelineItem key={indexActivity}>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                          style={{ fontSize: '10px' }}
                        >
                          {activity?.timestamp
                            ? format(
                                new Date(activity?.timestamp),
                                'dd.LL.yyyy',
                              )
                            : ''}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              backgroundColor:
                                activity?.type === CATEGORY_LAND_ACTIVITY.DELETE
                                  ? '#E63900'
                                  : '#8ED1A5',
                            }}
                          >
                            <Star style={{ width: 12, height: 12 }} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <div>
                            <span style={{ fontSize: '12px', fontWeight: 500 }}>
                              {t('common.favorites')}
                            </span>
                          </div>
                          <div style={{ color: '#969696', fontSize: '12px' }}>
                            {activity?.type === CATEGORY_LAND_ACTIVITY.DELETE
                              ? t('common.removed_by')
                              : t('common.added_by')}{' '}
                            <span style={{ fontWeight: 600 }}>
                              {activity?.name || ''}
                            </span>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  }

                  if (
                    activity?.category === LAND_ACTIVITY.ANALYSIS &&
                    checked.find((item: any) => item.key === 'analysis')
                  ) {
                    return (
                      <TimelineItem key={indexActivity}>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                          style={{ fontSize: '10px' }}
                        >
                          {activity?.timestamp
                            ? format(
                                new Date(activity?.timestamp),
                                'dd.LL.yyyy',
                              )
                            : ''}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{ backgroundColor: 'rgb(0, 173, 230)' }}
                          >
                            <Visibility style={{ width: 12, height: 12 }} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <div>
                            <span style={{ fontSize: '12px', fontWeight: 500 }}>
                              {t('common.analysis')}
                            </span>
                          </div>
                          <div style={{ color: '#969696', fontSize: '12px' }}>
                            <span> {t('common.by')} </span>
                            <span style={{ fontWeight: 600 }}>
                              {activity?.name || ''}
                            </span>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  }

                  if (
                    activity?.category === LAND_ACTIVITY.TAG &&
                    checked.find((item: any) => item.key === 'tags')
                  ) {
                    return (
                      <TimelineItem key={indexActivity}>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                          style={{ fontSize: '10px' }}
                        >
                          {activity?.timestamp
                            ? format(
                                new Date(activity?.timestamp),
                                'dd.LL.yyyy',
                              )
                            : ''}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              backgroundColor:
                                activity?.type === CATEGORY_LAND_ACTIVITY.DELETE
                                  ? '#E63900'
                                  : '#8ED1A5',
                            }}
                          >
                            <SellIcon style={{ width: 12, height: 12 }} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <div>
                            <span style={{ fontSize: '12px', fontWeight: 500 }}>
                              {t('common.tags')}
                            </span>
                          </div>
                          <div style={{ color: '#969696', fontSize: '12px' }}>
                            <span style={{ fontWeight: 600 }}>
                              {`"${activity?.value || ''}"`}{' '}
                              {activity?.primary
                                ? t('common.primary')
                                : t('common.secondary')}{' '}
                            </span>
                            <span>
                              {activity?.type === CATEGORY_LAND_ACTIVITY.DELETE
                                ? t('common.removed_by')
                                : t('common.added_by')}{' '}
                            </span>
                            <span style={{ fontWeight: 600 }}>
                              {activity?.name || ''}
                            </span>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  }

                  if (
                    activity?.category === LAND_ACTIVITY.REMINDER &&
                    checked.find((item: any) => item.key === 'reminders')
                  ) {
                    return (
                      <TimelineItem key={indexActivity}>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                          style={{ fontSize: '10px' }}
                        >
                          {activity?.timestamp
                            ? format(
                                new Date(activity?.timestamp),
                                'dd.LL.yyyy',
                              )
                            : ''}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              backgroundColor:
                                activity?.type === CATEGORY_LAND_ACTIVITY.DELETE
                                  ? '#E63900'
                                  : '#8ED1A5',
                            }}
                          >
                            <AddReminderIcon
                              style={{ width: 12, height: 12 }}
                            />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <div>
                            <span style={{ fontSize: '12px', fontWeight: 500 }}>
                              {t('common.Reminders')}
                            </span>
                          </div>
                          <div style={{ color: '#969696', fontSize: '12px' }}>
                            {activity?.type === CATEGORY_LAND_ACTIVITY.DELETE
                              ? t('common.removed_by')
                              : t('common.added_by')}{' '}
                            <span style={{ fontWeight: 600 }}>
                              {activity?.name || ''}
                            </span>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  }

                  if (
                    activity?.category === LAND_ACTIVITY.GROUP &&
                    checked.find((item: any) => item.key === 'groups')
                  ) {
                    return (
                      <TimelineItem key={indexActivity}>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                          style={{ fontSize: '10px' }}
                        >
                          {activity?.timestamp
                            ? format(
                                new Date(activity?.timestamp),
                                'dd.LL.yyyy',
                              )
                            : ''}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{ backgroundColor: 'rgb(0, 173, 230)' }}
                          >
                            <AddCircleOutlineOutlinedIcon
                              style={{ width: 12, height: 12 }}
                            />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <div>
                            <span style={{ fontSize: '12px', fontWeight: 500 }}>
                              {t('common.plotsGrouping')}
                            </span>
                          </div>
                          <div style={{ color: '#969696', fontSize: '12px' }}>
                            {activity?.type === CATEGORY_LAND_ACTIVITY.DELETE
                              ? t('common.removed_by')
                              : t('common.performed_by')}{' '}
                            <span style={{ fontWeight: 600 }}>
                              {activity?.name || ''}
                            </span>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  }

                  if (
                    activity?.category === LAND_ACTIVITY.ESTIMATE &&
                    checked.find((item: any) => item.key === 'estimates')
                  ) {
                    return (
                      <TimelineItem key={indexActivity}>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          variant="body2"
                          color="text.secondary"
                          style={{ fontSize: '10px' }}
                        >
                          {activity?.timestamp
                            ? format(
                                new Date(activity?.timestamp),
                                'dd.LL.yyyy',
                              )
                            : ''}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{ backgroundColor: 'rgb(0, 173, 230)' }}
                          >
                            <Visibility style={{ width: 12, height: 12 }} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <div>
                            <span style={{ fontSize: '12px', fontWeight: 500 }}>
                              {t('common.estimate')}
                            </span>
                          </div>
                          <div style={{ color: '#969696', fontSize: '12px' }}>
                            <span> {t('common.by')} </span>
                            <span style={{ fontWeight: 600 }}>
                              {activity?.name || ''}
                            </span>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  }

                  return <></>
                })
            )}
            {loading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '4px',
                }}
              >
                <CircularProgress size={20} />
              </Box>
            )}
          </Timeline>
        </StyledEngineProvider>
      </Box>
    </div>
  )
}

export default React.memo(ActivityTimeline)
