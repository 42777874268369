/* eslint-disable max-lines */
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { Map, useRouter, Modal, useI18n } from '@popety_io/popety-io-lib'
import { useEffect, useRef, useState, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

import {
  updateCustomSunlightBuilding,
  removeCustomSunlightBuilding,
  updateSunlightBuildingsPolygons,
  customSunlightBuildingsPolygonsSelector,
  updateSelectedSunlightBuilding,
} from '../redux'

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

type DrawPolygonControllerProps = {
  map: Map
  loaded?: boolean
}

const DrawPolygonController = ({ map, loaded }: DrawPolygonControllerProps) => {
  const drawRef: any = useRef<MapboxDraw>()

  const { query } = useRouter()
  const { t } = useI18n()

  const [modalHeightState, setModalHeightState] = useState(false)
  const [buildingHeight, setBuildingHeight] = useState<number | undefined>(0)

  const customSunlightBuildingsPolygons = useSelector(
    customSunlightBuildingsPolygonsSelector,
  )

  const dispatch = useDispatch<any>()

  const polygon = query.tab === 'sunlight' || query.subTab === 'sunlight'

  const handleDrawBuilding = () => {
    const data: any = drawRef.current?.getSelected()

    const geoPolygon = {
      type: 'MultiPolygon',
      coordinates: [data?.features[0]?.geometry?.coordinates],
    }

    const buildingsGeoJSON = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: geoPolygon,
          properties: {
            height: buildingHeight,
            color: '#3b9ddd',
          },
        },
      ],
    }

    const buildingLayer = {
      id: data?.features[0]?.id,
      type: 'geojson',
      data: buildingsGeoJSON,
      layers: [
        {
          id: data?.features[0]?.id,
          type: 'fill-extrusion',
          paint: {
            'fill-extrusion-color': ['get', 'color'],
            'fill-extrusion-height': ['get', 'height'],
          },
          hover: true,
        },
      ],
    }

    dispatch(
      updateCustomSunlightBuilding({
        id: data?.features[0]?.id,
        data: buildingLayer,
        height: buildingHeight,
      }),
    )

    dispatch(
      updateSunlightBuildingsPolygons({
        landId: query.landId,
        data: drawRef.current?.getAll(),
      }),
    )

    setModalHeightState(false)
  }

  const handleSelect = () => {
    const data: any = drawRef.current?.getSelected()

    dispatch(updateSelectedSunlightBuilding({ id: data?.features[0]?.id }))
  }

  const handleUpdate = () => {
    handleDrawBuilding()
  }

  const handleCreate = () => {
    setModalHeightState(true)
  }

  const handleDeleteBuilding = (id: string) => {
    dispatch(removeCustomSunlightBuilding({ id, landId: query.landId }))
  }

  useEffect(() => {
    if (drawRef.current || !polygon || !loaded) return

    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: { polygon: true, trash: true },
      // Set mapbox-gl-draw to draw by default.
      // The user does not have to click the polygon control button first.',
      styles: [
        // ACTIVE (being drawn)
        // line stroke
        {
          id: 'gl-draw-line',
          type: 'line',
          filter: [
            'all',
            ['==', '$type', 'LineString'],
            ['!=', 'mode', 'static'],
          ],
          layout: { 'line-cap': 'round', 'line-join': 'round' },
          paint: {
            'line-color': '#3b9ddd',
            'line-dasharray': [0.2, 2],
            'line-width': 2,
          },
        },
        // polygon fill
        {
          id: 'gl-draw-polygon-fill',
          type: 'fill',
          filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
          paint: {
            'fill-color': '#3b9ddd',
            'fill-outline-color': '#3b9ddd',
            'fill-opacity': 0.1,
          },
        },
        // polygon outline stroke
        // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
        {
          id: 'gl-draw-polygon-stroke-active',
          type: 'line',
          filter: [
            'all',
            ['==', '$type', 'Polygon'],
            ['==', 'mode', 'draw_polygon'],
          ],
          layout: { 'line-cap': 'round', 'line-join': 'round' },
          paint: {
            'line-color': '#3b9ddd',
            'line-width': 2,
          },
        },
        // vertex point halos
        {
          id: 'gl-draw-polygon-and-line-vertex-halo-active',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
          ],
          paint: {
            'circle-radius': 7,
            'circle-color': '#3b9ddd',
          },
        },
      ],
    })
  }, [polygon, loaded])

  useEffect(() => {
    if (!polygon || !drawRef.current || !loaded) return

    try {
      map.addControl(drawRef.current)
      map.on('draw.create', handleCreate)
      map.on('draw.delete', (data: any) => {
        handleDeleteBuilding(data.features[0].id)
      })
      map.on('draw.update', handleUpdate)
      map.on('draw.selectionchange', handleSelect)
    } catch (error) {
      console.error(error)
    }

    return () => {
      try {
        map.off('draw.create', handleCreate)
        map.off('draw.delete', (data: any) => {
          handleDeleteBuilding(data.features[0].id)
        })
        map.off('draw.update', handleUpdate)

        map.removeControl(drawRef.current!)
      } catch (error) {
        console.error(error)
      }
    }
  }, [polygon, loaded])

  useEffect(() => {
    if (
      !drawRef.current ||
      !customSunlightBuildingsPolygons ||
      !customSunlightBuildingsPolygons[query.landId] ||
      !polygon
    )
      return
    drawRef.current.add(customSunlightBuildingsPolygons[query.landId])
  }, [customSunlightBuildingsPolygons, loaded, polygon])

  useEffect(() => {
    function handleKeyPress(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        if (buildingHeight && buildingHeight !== 0) {
          handleDrawBuilding()
        }
      }
    }

    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [buildingHeight])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    if (newValue === '') {
      setBuildingHeight(0)
    } else if (!Number.isNaN(parseInt(newValue, 10))) {
      setBuildingHeight(parseInt(newValue, 10))
    }
  }

  return (
    <Modal
      maxWidth="xs"
      disableGutters
      open={modalHeightState}
      onClose={() => {
        setModalHeightState(false)
      }}
      title={t('common.building_height')}
      okText={t('common.draw_building')}
      onOk={handleDrawBuilding}
      okButtonProps={{
        disabled:
          !buildingHeight ||
          Number.isNaN(buildingHeight) ||
          buildingHeight === 0,
      }}
    >
      <Box
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          paddingBottom: 2,
          paddingTop: 2,
          minWidth: 300,
        }}
      >
        <TextField
          size="small"
          id="height"
          label={t('common.building_height_in_meters')}
          value={buildingHeight}
          onChange={handleChange}
          name="height"
          fullWidth
          required
        />
      </Box>
    </Modal>
  )
}

export default DrawPolygonController
