import Box from '@mui/material/Box'
import { Slide } from '@mui/material'
import React from 'react'
import TransactionDetailBody from './TransactionDetailComponent/TransactionDetailBody'

export type ListingListProps = {
  transaction: Record<string, any>
  setTransaction: React.Dispatch<React.SetStateAction<Record<string, any>>>
  onSave?: (listings: any) => void
  transactionsIds?: string[]
  onRemove?: (listing: any) => void
}

const TransactionDetail = ({
  transaction,
  onSave,
  onRemove,
  transactionsIds,
}: ListingListProps) => {
  return (
    <Box
      width="100%"
      sx={{
        position: 'relative',
        display: transaction?.id || transaction?._id ? '' : 'none',
      }}
    >
      <Slide
        direction="left"
        timeout={1000}
        in={!!(transaction?.id || transaction?._id)}
        style={{
          width: '100%',
        }}
      >
        <Box
          data-cy="land-detail-accordionDetails"
          sx={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <TransactionDetailBody
            transaction={transaction}
            transactionsIds={transactionsIds}
            onSave={onSave}
            onRemove={onRemove}
          />
        </Box>
      </Slide>
    </Box>
  )
}

export default TransactionDetail
