import { ApiClient } from '../../../../services'

const client = new ApiClient()

type GetTransactionOpts = {
  landId?: string | number | string[] | number[]
  size?: number
  [k: string]: any
}

const getLandTransactions = ({ landId }: GetTransactionOpts) =>
  client.get(`/land/${landId}/transactions`)

const getTransactionsAround = async ({ ...filters }: GetTransactionOpts) => {
  return client.post(`/land/transactions`, filters)
}

const getTransactionAggs = (filter: Record<string, any>) => {
  return client.post('/transactions/search', { ...filter, size: 1 })
}

const getGeojsonCity = (city: string) => client.get(`/cities/geojson/${city}`)

const getGeojsonDistrict = (district: string | string[]) =>
  client.get(`/district/geojson/${district}`)

const getTransactionsByIds = ({ transactionsIds }: any) => {
  return client.get(`/transactions/${transactionsIds}`)
}

const transactionService = {
  getLandTransactions,
  getTransactionsAround,
  getTransactionAggs,
  getGeojsonCity,
  getGeojsonDistrict,
  getTransactionsByIds,
}

export default transactionService
