const faosSelector = (state) => state.faoHistory.faos

const selectedFaosSelector = (state) => state.faoHistory.selectedFaos

const aggregationsSelector = (state) => state.faoHistory.aggregations

const inquiryStartDatesSelector = (state) =>
  state.faoHistory.aggregations.inquiry_start_dates.inquiry_start_dates

const faoLoadingSelector = (state) => state.faoHistory.loading

const faoBboxSelector = (state) => state.faoHistory.bbox

const countFaoSelector = (state) =>
  state.faoHistory.count || state.faoHistory.total

const faosTileDetailsSelector = (state) => state.faoHistory.faosTileDetails

const faosTileDetailsLoadingsSelector = (state) =>
  state.faoHistory.faosTileDetailsLoading

export {
  faosSelector,
  aggregationsSelector,
  inquiryStartDatesSelector,
  faoLoadingSelector,
  faoBboxSelector,
  countFaoSelector,
  selectedFaosSelector,
  faosTileDetailsSelector,
  faosTileDetailsLoadingsSelector,
}
