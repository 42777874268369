import FaoDetailDescription from './FaoDetailDescription'
import FaoDetailInformation from './FaoDetailInformation'
import FaoDetailFooter from './FaoDetailFooter'
import FaoDetailHeader from './FaoDetailHeader'
import FaoDetailNature from './FaoDetailNature'
import FaoDetails from './FaoDetails'

const FaoDetailBody = ({ fao, children }: { fao: any; children?: any }) => (
  <>
    <FaoDetailHeader fao={fao} />
    {children}
    <FaoDetailNature fao={fao} />
    <FaoDetails fao={fao} />
    <FaoDetailDescription fao={fao} />
    <FaoDetailInformation fao={fao} />
    <FaoDetailFooter fao={fao} />
  </>
)

export default FaoDetailBody
