/* eslint-disable max-lines */
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import mapboxgl from 'mapbox-gl'
import {
  Map,
  useRouter,
  useMediaQuery,
  useI18n,
} from '@popety_io/popety-io-lib'
import { regionsByNameSelector } from '../../../../../components/LocationsInput'
import {
  getTransactionTiles,
  removeTransactionTiles,
} from '../../../../LandDetails/redux/transaction/transaction.layers'
import { estimateSelector } from '../../../redux'
import { getTransactionFilters } from '../../../../LandDetails/Transaction/TransactionAround/utils'
import {
  getTransactionsTileDetails,
  transactionsTileDetailsLoadingSelector,
  transactionsTileDetailsSelector,
} from '../../../../TransactionHistory'
import EstimateTransactionMapTilePopup from './EstimateTransactionMapTilePopup'

const fillStyle = 'layer_fill_transaction'

const EstimateTransactionsController = ({
  map,
  loaded,
}: {
  map: Map
  loaded?: boolean
}) => {
  // const { dispatch, data, loading } = useAsync<any[]>()
  const dispatch = useDispatch<any>()
  const { t } = useI18n()
  const router = useRouter()
  const estimate: any = useSelector(estimateSelector)
  const geoCenter = estimate?.geo_center

  const [feature, setFeature] = useState<any>()
  const [coordinatesCurrent, setCoordinatesCurrent] = useState<any>()
  const [popup, setPopup] = useState<mapboxgl.Popup>()
  const [popupTransaction, setPopupTransaction] = useState<mapboxgl.Popup>()
  const touchScreen = useMediaQuery('(hover: none)')
  const regionsByName = useSelector(regionsByNameSelector)
  const data = useSelector(transactionsTileDetailsSelector)
  const loading = useSelector(transactionsTileDetailsLoadingSelector)

  const containerRef = useRef<any>(null)

  const isTransactionTab = router.query.tab === 'transaction'

  const defaultDate =
    regionsByName[estimate.region_name]?.first_transaction_date

  const isEstimate = !!router?.query?.estimateId

  const filters = getTransactionFilters(
    router.query,
    geoCenter,
    defaultDate,
    isEstimate,
  )
  const query = filters && encodeURIComponent(JSON.stringify(filters))
  const { transactionCenter, title } = router.query

  const handleClickItemPopup = () => {
    if (map && popupTransaction) {
      popupTransaction.remove()
    }
  }

  const handleClickMap = () => {
    if (map && popupTransaction) {
      popupTransaction.remove()
    }
  }

  const handleClick = (e: any) => {
    if (map && popupTransaction) {
      popupTransaction.remove()
    }

    dispatch(
      getTransactionsTileDetails({
        tile: e.features.map(
          (f: { properties: { _key: string } }) => f.properties._key,
        ),
        q: query,
      }),
    )

    const coordinates = e.features[0].geometry.coordinates.slice()

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
    }

    setCoordinatesCurrent(coordinates)
  }

  const handleMouseMove = (e: any) => {
    map.getCanvas().style.cursor = 'pointer'
    if (popup) {
      const coordinates = e.features[0].geometry.coordinates.slice()
      const str =
        e.features[0].properties._count > 1
          ? `${t('transaction.Transaction')}s`
          : t('transaction.Transaction')
      const description = `${e.features[0].properties._count} ${str}`

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(map)
    }
  }

  const handleMouseLeave = () => {
    map.getCanvas().style.cursor = ''

    if (popup) {
      popup.remove()
    }
  }

  const addTransactionTiles = () => {
    try {
      getTransactionTiles({
        map,
        query,
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (touchScreen) setPopup(undefined)
  }, [touchScreen])

  useEffect(() => {
    if (router?.query?.tab !== 'transaction' && map && loaded) {
      removeTransactionTiles({ map })
    }
  }, [router?.query?.tab])

  useEffect(() => {
    setPopup(
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    )
    setPopupTransaction(
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'custom-popup-transaction',
      }),
    )
  }, [])

  useEffect(() => {
    if (!map || !loaded || router?.query?.tab !== 'transaction') return

    addTransactionTiles()

    map.on('style.load', addTransactionTiles)
    map.on('click', fillStyle, handleClick)
    map.on('mousemove', fillStyle, handleMouseMove)
    map.on('mouseleave', fillStyle, handleMouseLeave)
    map.on('click', handleClickMap)

    return () => {
      map.off('style.load', addTransactionTiles)
      map.off('click', fillStyle, handleClick)
      map.off('mousemove', fillStyle, handleMouseMove)
      map.off('mouseleave', fillStyle, handleMouseLeave)
      map.off('click', handleClickMap)
    }
  }, [isTransactionTab, loaded, query, title, transactionCenter])

  useEffect(() => {
    if (data?.length) {
      setFeature(data[0])
    }
  }, [data])

  useEffect(() => {
    if (loading && popupTransaction) {
      popupTransaction.remove()
    }

    if (router.query?.tab !== 'transaction' && popupTransaction) {
      popupTransaction.remove()
      setCoordinatesCurrent(null)
    }

    if (
      !loading &&
      data &&
      coordinatesCurrent &&
      popupTransaction &&
      containerRef.current &&
      router.query?.tab === 'transaction' &&
      map
    ) {
      if (data?.length === 0) {
        popupTransaction.remove()

        return
      }
      if (data?.length === 1) {
        router.updateQuery({
          transactionId: data?.[0]?._id || data?.[0]?.id || null,
        })
      } else {
        popupTransaction
          .setLngLat(coordinatesCurrent)
          .setDOMContent(containerRef.current)
          .addTo(map)
      }
    }
  }, [
    data,
    loading,
    popupTransaction,
    coordinatesCurrent,
    router.query?.tab,
    map,
  ])

  useEffect(() => {
    if (map && loaded && transactionCenter && popup && title) {
      const [lng, lat] = transactionCenter.split(',')

      map.flyTo({ center: [lng, lat], zoom: 17 })
      popup.setLngLat([lng, lat]).setHTML(title).addTo(map)
    } else if (map && loaded && !transactionCenter && popup && !title) {
      popup.remove()
    }
  }, [transactionCenter, map, loaded, popup, title])

  const handleClose = () => {
    setFeature(null)
  }

  // if (!isTransactionTab || !map || !loaded || !data?.length) return null

  return (
    <EstimateTransactionMapTilePopup
      data={data}
      loading={loading}
      handleClose={handleClose}
      feature={feature}
      ref={containerRef}
      onClick={handleClickItemPopup}
    />
  )
}

export default EstimateTransactionsController
