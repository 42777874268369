import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const SwiperWrapper = styled(Box)`
  .swiper-button-prev {
    width: 46px;
    height: 48px;
  }
  .swiper-button-next {
    width: 46px;
    height: 48px;
  }

  .swiper-button-prev::after {
    display: none !important;
  }
  .swiper-button-next::after {
    display: none !important;
  }
`
