/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import formService from '../../../DeveloperMap/redux/form/formService'
import transactionService from '../../../LandDetails/redux/transaction/transactionService'
// import estimateComparablesService from './estimateComparablesService'

const initialState = {
  loading: false,
  geojson: null,
}

export const getGeojsonByMunicipalityName = createAsyncThunk(
  'estimateComparables/getGeojsonByMunicipalityName',
  formService.getGeojsonByMunicipalityName,
)

export const getGeojsonByDistrictName = createAsyncThunk(
  'estimateComparables/getGeojsonByDistrictName',
  transactionService.getGeojsonDistrict,
)

export const getGeojsonByNpaName = createAsyncThunk(
  'estimateComparables/getGeojsonByNpaName',
  formService.getNpaByName,
)

const estimateComparables = createSlice({
  name: 'estimateComparables',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGeojsonByMunicipalityName.pending, (state) => {
        state.loading = true
      })
      .addCase(getGeojsonByMunicipalityName.rejected, (state) => {
        state.loading = false
      })
      .addCase(getGeojsonByMunicipalityName.fulfilled, (state, action) => {
        const { data = {} } = action.payload

        state.loading = false
        if (data.length) {
          state.geojson = JSON.parse(data?.[0]?.geojson_polygon) || []
        }
      })
      .addCase(getGeojsonByDistrictName.pending, (state) => {
        state.loading = true
      })
      .addCase(getGeojsonByDistrictName.rejected, (state) => {
        state.loading = false
      })
      .addCase(getGeojsonByDistrictName.fulfilled, (state, action) => {
        const { data = {} } = action.payload

        state.loading = false
        if (data.length) {
          state.geojson = JSON.parse(data?.[0]?.geojson_polygon) || []
        }
      })
      .addCase(getGeojsonByNpaName.pending, (state) => {
        state.loading = true
      })
      .addCase(getGeojsonByNpaName.rejected, (state) => {
        state.loading = false
      })
      .addCase(getGeojsonByNpaName.fulfilled, (state, action) => {
        const { data = {} } = action.payload

        state.loading = false
        if (data.length) {
          state.geojson = JSON.parse(data?.[0]?.geojson_polygon) || []
        }
      })
  },
})

export const estimateComparablesReducer = {
  estimateComparables: estimateComparables.reducer,
}
