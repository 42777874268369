import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import { Modal, useI18n, ProgressIndicator } from '@popety_io/popety-io-lib'
import { ModalProps } from '@popety_io/popety-io-lib/dist/components/Modal'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { getUserId } from '../../../../services'
import {
  buildingLoadingSelector,
  getBuildingSummary,
} from '../../redux/summary'
import { setBuildingSearchHistory } from '../../redux/buildingSearchHistory'
import { LOCATION_TYPE } from '../../../../config'
import LocationsInput from '../../../../components/LocationsInput'
import { LandSearchRoot, SearchForm } from './BuildingSearch.style'

const BuildingSearch = ({ open, onClose, showCloseIcon }: ModalProps) => {
  const [value, setValue] = useState<any[]>([])

  const dispatch: any = useDispatch()
  const loading = useSelector(buildingLoadingSelector)

  const navigate = useNavigate()
  const { t } = useI18n()

  const handleSubmit = () => {
    if (!value?.length) return

    const addressId = value.map((i: any) => i.id).filter(Boolean)

    dispatch(getBuildingSummary({ addressId }))
      .then(unwrapResult)
      .then((result: any) => {
        const buildingId = result?.data?.id

        if (buildingId) {
          dispatch(
            setBuildingSearchHistory({
              user_id: getUserId(),
              building_id: result?.data?.id,
              location: value[0].location_search,
            }),
          )

          navigate(
            `/land-detail/building?buildingId=${buildingId}&landUsePlanStatus=now`,
          )
        }
      })
  }

  const handleChange = (newLocations: any) => {
    setValue(newLocations.filter(Boolean).slice(-1))
  }

  useEffect(() => {
    if (value?.length && value.length > 0) {
      handleSubmit()
    }
  }, [value])

  return (
    <Modal
      top={50}
      open={open}
      keepMounted
      naked
      blur
      disableGutters
      showCloseIcon={false}
      maxWidth="sm"
      onClose={onClose}
      sx={{ zIndex: '3 !important', '.MuiDialog-paper': { mt: '-15vh' } }}
    >
      {loading && <ProgressIndicator />}

      {!loading && (
        <LandSearchRoot sx={{ width: { xs: '300px', sm: '500px' } }}>
          <Paper className="Title">
            {t('land.search.locationSearch')}

            {showCloseIcon && (
              <IconButton aria-label="close" onClick={onClose} size="small">
                <CloseIcon />
              </IconButton>
            )}
          </Paper>

          <SearchForm component="form" onSubmit={() => {}}>
            <LocationsInput
              onChange={handleChange}
              value={value}
              types={[LOCATION_TYPE.ADDRESS]}
              size={10}
              onKeyDown={handleSubmit}
              searchType="building"
            />
          </SearchForm>
        </LandSearchRoot>
      )}
    </Modal>
  )
}

export default BuildingSearch
