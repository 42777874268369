import { useState, useEffect } from 'react'
import { Map } from '@popety_io/popety-io-lib'

interface Feature {
  type: 'Feature'
  geometry: {
    type: 'Point'
    coordinates: [number, number]
  }
  properties: {
    price?: string
    title?: string
  }
}

const useMapEvents = (
  map: Map | any | null,
  loaded: boolean | undefined,
  isHomePage: boolean,
  SOURCE: string,
) => {
  const [features, setFeatures] = useState<Feature[]>([])

  useEffect(() => {
    if (!map || !loaded || !isHomePage) return

    const handleMoveEnd = () => {
      if (!map.getLayer('unclustered-point')) return
      const newFeatures = map.queryRenderedFeatures({
        layers: ['unclustered-point'],
      }) as any

      setFeatures(newFeatures)
    }

    const handleMouseEnter = () => {
      map.getCanvas().style.cursor = 'pointer'
    }

    const handleMouseLeave = () => {
      map.getCanvas().style.cursor = ''
    }

    const handleClusterClick = (
      e: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined
      },
    ) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      })
      const clusterId = features[0]?.properties?.cluster_id
      const source = map.getSource(SOURCE) as mapboxgl.GeoJSONSource

      source.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return
        map.easeTo({
          center: (features[0].geometry as any).coordinates,
          zoom: zoom!,
        })
      })
    }

    map.on('moveend', handleMoveEnd)
    map.on('mouseenter', 'clusters', handleMouseEnter)
    map.on('mouseleave', 'clusters', handleMouseLeave)
    map.on('mouseenter', 'unclustered-point', handleMouseEnter)
    map.on('mouseleave', 'unclustered-point', handleMouseLeave)
    map.on('click', 'clusters', handleClusterClick)

    return () => {
      map.off('moveend', handleMoveEnd)
      map.off('mouseenter', 'clusters', handleMouseEnter)
      map.off('mouseleave', 'clusters', handleMouseLeave)
      map.off('mouseenter', 'unclustered-point', handleMouseEnter)
      map.off('mouseleave', 'unclustered-point', handleMouseLeave)
      map.off('click', 'clusters', handleClusterClick)
    }
  }, [map, loaded, isHomePage])

  return features
}

export default useMapEvents
