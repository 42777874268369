/* eslint-disable max-lines */
import { useState } from 'react'
import Grid from '@mui/material/Grid'
import { Button, Select, useRouter, useI18n } from '@popety_io/popety-io-lib'
import FaoFilters from './FaoFilters'
import ListingFilters from './ListingFilters'
import TransactionAroundFilters from './TransactionAroundFilters'
import LocationsFilter from './locationsFilter'
import ListingMinMaxFilter from './ListingMinMaxFilter'

const HistoryAroundFilters = () => {
  const { t } = useI18n()
  const [showMore, setShowMore] = useState(false)
  const { query, updateQuery } = useRouter()
  const { tab } = query

  const filters = {
    month: query.month,
    radius: query.radius,
    classification: query.classification,
    ownershipType: query.ownershipType,
    reason: query.reason,
    dealType: query.dealType,
    price: query.price,
    rooms: query.rooms,
    area: query.area,
    propertyCategory: query.propertyCategory,
    npa_history: query.npa_history,
    district_history: query.district_history,
    city_history: query.city_history,
  }

  const hasFilters = !!Object.keys(filters)?.filter((key) => {
    if (key === 'radius' && filters[key] && Number(filters[key]) === 500) {
      return false
    }

    return Boolean(filters[key as keyof typeof filters])
  })?.length

  const monthOptions = [1, 2, 3, 5, 10, 20].map((value) => ({
    label: t(
      `land.history.${value === 1 ? 'lessThanYear' : 'lessThanYear_plural'}`,
      {
        count: value,
      },
    ),
    value,
  }))

  const handleFiltersChange = (name: string) => (newValue: any) => {
    updateQuery({
      [name]: newValue?.value ? newValue.value * 12 : newValue * 12,
    })
  }

  const handleReset = () => {
    const newQuery: any = {}

    Object.keys(filters).forEach((k) => {
      if (k === 'radius') {
        newQuery[k] = 500
      } else {
        newQuery[k] = undefined
      }
    })

    updateQuery(newQuery)
  }

  const handleShowMore = () => {
    setShowMore(!showMore)
  }

  if (tab === 'listing')
    return (
      <Grid
        container
        spacing={2}
        sx={{
          pt: 1,
        }}
      >
        <LocationsFilter />
        <Grid item xs={12} sm={4} md={3}>
          <ListingFilters />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Select
            options={[
              {
                label: t('common.filters.Apartment/House'),
                value: 'Apartment,House',
              },
              { label: t('common.Apartment'), value: 'Apartment' },
              { label: t('common.House'), value: 'House' },
            ]}
            placeholder={t('listing.property_type')}
            value={query.propertyCategory}
            onChange={(newValue: any) =>
              updateQuery({ propertyCategory: newValue?.value })
            }
            sx={{
              width: '100%',
              maxWidth: { xs: '100%', lg: '100%' },
              borderColor: query.propertyCategory ? '#00ace6' : 'grey',
              color: query.propertyCategory ? '#00ace6' : 'grey',
            }}
            variant="outlined"
            label={t('listing.property_type')}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Select
            options={monthOptions}
            placeholder={t('land.history.year')}
            value={query.month ? (query.month / 12).toString() : null}
            onChange={handleFiltersChange('month')}
            sx={{
              width: '100%',
              maxWidth: { xs: '100%', lg: '140px' },
            }}
            variant="outlined"
            label={t('land.history.year')}
          />
        </Grid>
        {showMore && (
          <>
            <ListingMinMaxFilter
              label={t('common.rooms')}
              labelId="rooms"
              unit={t('common.rooms')}
            />
            <ListingMinMaxFilter
              label={t('common.landRadarInfo.area')}
              labelId="area"
              unit="m2"
            />
            <ListingMinMaxFilter
              label={t('common.price')}
              labelId="price"
              unit="CHF"
            />
          </>
        )}
        <Grid item>
          <Button
            onClick={handleShowMore}
            sx={{
              padding: '0px',
              height: '100%',
            }}
          >
            {!showMore ? t('common.moreFilters') : t('common.lessFilter')}
          </Button>
        </Grid>

        <Grid item xs={12} pt="0 !important">
          {hasFilters && showMore && (
            <Button
              variant="text"
              sx={{ ml: -1, textTransform: 'none', color: '#E97B0E' }}
              onClick={handleReset}
            >
              {t('land.history.clearFilters')}
            </Button>
          )}
        </Grid>
      </Grid>
    )

  return (
    <Grid
      container
      spacing={2}
      sx={{
        pt: 1,
      }}
    >
      <LocationsFilter />
      <Grid item xs={12} sm={4} md={2}>
        <Select
          options={monthOptions}
          placeholder={t('land.history.year')}
          value={query.month ? (query.month / 12).toString() : null}
          onChange={handleFiltersChange('month')}
          sx={{
            width: '100%',
            maxWidth: { xs: '100%', lg: '140px' },
          }}
          variant="outlined"
          label={t('land.history.year')}
        />
      </Grid>

      {(tab === 'fao' || tab === 'investigations') && (
        <Grid item xs={12} sm={6} md={3}>
          <FaoFilters />
        </Grid>
      )}

      {tab === 'transaction' && <TransactionAroundFilters />}

      {(tab === 'listing' || tab === 'advertisement') && !query.dwellingId && (
        <Grid item xs={12} sm={4} md={3}>
          <ListingFilters />
        </Grid>
      )}
      {(tab === 'listing' ||
        tab === 'market' ||
        tab === 'advertisement' ||
        tab === 'transaction') &&
        showMore && (
          <ListingMinMaxFilter
            label={t('common.price')}
            labelId="price"
            unit="CHF"
          />
        )}
      {(tab === 'listing' || tab === 'market' || tab === 'advertisement') && (
        <>
          <ListingMinMaxFilter
            label={t('common.rooms')}
            labelId="rooms"
            unit={t('common.rooms')}
          />
          <ListingMinMaxFilter
            label={t('common.landRadarInfo.area')}
            labelId="area"
            unit="m2"
          />
        </>
      )}
      {(tab === 'listing' || tab === 'market') && (
        <Grid item xs={12} sm={4} md={3}>
          <Select
            options={[
              {
                label: t('common.filters.Apartment/House'),
                value: 'Apartment,House',
              },
              { label: t('common.Apartment'), value: 'Apartment' },
              { label: t('common.House'), value: 'House' },
            ]}
            placeholder={t('listing.property_type')}
            value={query.propertyCategory}
            onChange={(newValue: any) =>
              updateQuery({ propertyCategory: newValue?.value })
            }
            sx={{
              width: '100%',
              maxWidth: { xs: '100%', lg: '140px' },
              borderColor: query.propertyCategory ? '#00ace6' : 'grey',
              color: query.propertyCategory ? '#00ace6' : 'grey',
            }}
            variant="outlined"
            label={t('listing.property_type')}
          />
        </Grid>
      )}
      <Grid item>
        <Button
          onClick={handleShowMore}
          sx={{
            padding: '0px',
            height: '100%',
          }}
        >
          {!showMore ? t('common.moreFilters') : t('common.lessFilter')}
        </Button>
      </Grid>

      <Grid item xs={12} pt="0 !important">
        {hasFilters && showMore && (
          <Button
            variant="text"
            sx={{ ml: -1, textTransform: 'none', color: '#E97B0E' }}
            onClick={handleReset}
          >
            {t('land.history.clearFilters')}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default HistoryAroundFilters
