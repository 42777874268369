/* eslint-disable max-lines */
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Skeleton,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import TablePagination from '@mui/material/TablePagination'

import { useDispatch, useSelector } from 'react-redux'
import { fetchOnce, useI18n, useRouter } from '@popety_io/popety-io-lib'
import LandHistoryContainer from '../LandHistoryContainer'
import {
  getGroupLandAnalysisHistory,
  getLandSearchHistory,
  searchHistorySelector,
  groupLandAnalysisHistorySelector,
  searchHistoryIsLoadingSelector,
  loadGroupLandAnalysisHistorySelector,
} from '../../redux'

interface Land {
  archived: boolean
}

const LandHistory: React.FC = () => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const { query, updateQuery } = useRouter()
  const landHistory = useSelector(searchHistorySelector)
  const groupLandHistory = useSelector(groupLandAnalysisHistorySelector)
  const loadingLand = useSelector(searchHistoryIsLoadingSelector)
  const loadingGrouping = useSelector(loadGroupLandAnalysisHistorySelector)
  const [value, setValue] = useState('')
  const { page = 0, size = 25, active, archived } = query
  const isArchived = archived === 'true'
  const isActive = active === 'true'

  const isGrouping = query?.tab === 'grouping'
  const isHome = query?.tab === 'home' || isGrouping

  useEffect(() => {
    updateQuery({ active: true, archived: false })
  }, [isGrouping])

  const selectedHistory = useMemo(
    () => (isGrouping ? groupLandHistory : landHistory) || [],
    [isGrouping, landHistory, groupLandHistory],
  )

  const history = useMemo(() => {
    let items = selectedHistory

    if (value) {
      items = selectedHistory?.filter((item: any) => {
        const filterString = item?.address_full || item?.municipality_name || ''
        const decentFilterString = filterString
          ?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          ?.toLowerCase()
          ?.trim()
        const decentValue = value
          ?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          ?.toLowerCase()
          ?.trim()

        return (
          item?.address_full?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item?.municipality_name
            ?.toLowerCase()
            ?.includes(value?.toLowerCase()) ||
          decentFilterString?.includes(value) ||
          decentFilterString?.includes(decentValue)
        )
      })
    }

    if (!isActive && !isArchived) return []

    return items.filter(
      (land: Land) =>
        (isActive && !land.archived) || (isArchived && land.archived),
    )
  }, [selectedHistory, isActive, isArchived, value])

  useEffect(() => {
    if (isHome && !isGrouping) {
      fetchOnce('getLandSearchHistory', null, () => {
        dispatch(getLandSearchHistory() as any)
      })
    }

    if (isHome && isGrouping) {
      fetchOnce('getGroupLandAnalysisHistory', null, () => {
        dispatch(getGroupLandAnalysisHistory() as any)
      })
    }
  }, [isGrouping, isHome])

  const handleCheckboxChange = useCallback(
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      updateQuery({ [name]: event.target.checked })
    },
    [updateQuery],
  )

  const pagedResult = useMemo(
    () => history?.slice(+page * +size, (+page + 1) * +size) || [],
    [history, page, size],
  )

  const hasPage = pagedResult.length !== history.length

  const handleChangePage = useCallback(
    (e: unknown, newPage: number) => {
      updateQuery({ page: newPage })
    },
    [updateQuery],
  )

  const handleChangePageSize = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateQuery({ size: +event.target.value, page: 0 })
    },
    [updateQuery],
  )

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          background: '#fff',
          width: 'calc(100% + 20px)',
          padding: ' 0 10px',
          marginLeft: '-10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              marginBottom: '4px',
              textTransform: 'uppercase',
            }}
            fontSize="1.5rem"
            fontWeight={500}
          >
            {t('common.Analyse détaillée de parcelle')}
          </Typography>
          <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => handleCheckboxChange('active')(e)}
                  checked={isActive}
                />
              }
              label={t('common.Actifs')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isArchived}
                  onChange={(e) => handleCheckboxChange('archived')(e)}
                />
              }
              label={t('common.Archivés')}
            />
          </FormGroup>
        </Box>
        <TextField
          variant="outlined"
          label={`${t('common.Entrer une commune, une adresse')}`}
          placeholder={`${t('common.Entrer une commune, une adresse')}...`}
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        {hasPage && (
          <TablePagination
            component="div"
            align="left"
            count={history?.length}
            page={+page}
            onPageChange={handleChangePage}
            rowsPerPage={+size}
            onRowsPerPageChange={handleChangePageSize}
            labelRowsPerPage={t('common.rowsPerPage')}
          />
        )}
      </Box>

      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          paddingBottom: '20px',
        }}
      >
        {!loadingLand && !loadingGrouping ? (
          pagedResult?.map((element: any, k: number) => (
            <LandHistoryContainer key={element?.id || k} element={element} />
          ))
        ) : (
          <div>
            {Array.from({ length: 10 }).map((_: any, idx: number) => (
              <Skeleton
                key={idx}
                variant="rectangular"
                width="100%"
                height="110px"
                sx={{
                  borderRadius: '2px',
                  marginBottom: '12px',
                }}
              />
            ))}
          </div>
        )}
        {hasPage && (
          <TablePagination
            component="div"
            align="left"
            count={history?.length}
            page={+page}
            onPageChange={handleChangePage}
            rowsPerPage={+size}
            onRowsPerPageChange={handleChangePageSize}
            labelRowsPerPage={t('common.rowsPerPage')}
          />
        )}
      </Box>
    </Box>
  )
}

export default React.memo(LandHistory)
