/* eslint-disable max-lines */
import { useState } from 'react'
import { Box, IconButton } from '@mui/material'
import { useI18n } from '@popety_io/popety-io-lib'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'

type LandHistoryMenuProps = {
  id?: string | string[]
  archive?: boolean
}

const LandHistoryMenu = ({ id, archive = false }: LandHistoryMenuProps) => {
  const { t } = useI18n()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget as any)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = () => {
    let url = '?tab=summary'

    if (id) {
      const landIds = Array.isArray(id) ? id.join(',') : id

      url += `&landId=${landIds}`
    }

    window.open(url, '_blank')
    setAnchorEl(null)
  }

  return (
    <Box display="flex">
      <IconButton size="small" onClick={handleClick} sx={{ opacity: 1 }}>
        <MoreVertIcon color="primary" />
      </IconButton>
      {anchorEl && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {!archive ? (
            <>
              <MenuItem sx={{ fontSize: '1.1rem' }} onClick={handleOpen}>
                {t('common.open.analysis')}
              </MenuItem>
            </>
          ) : (
            <MenuItem sx={{ fontSize: '1.1rem' }} onClick={handleOpen}>
              {t('common.open.analysis')}
            </MenuItem>
          )}
        </Menu>
      )}
    </Box>
  )
}

export default LandHistoryMenu
