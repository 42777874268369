/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { isAfter, isBefore } from 'date-fns'
import faoService from './faoHistoryService'

const currentYear = new Date().getFullYear()

const initialState = {
  priceData: {},
  comparePriceData: {},
  compareFilter: {},
  selectedFaos: [],
  faos: [],
  aggregations: {
    inquiry_start_dates: {
      inquiry_start_dates: {
        min_as_string: '2005-01-01',
        max_as_string: currentYear.toString(),
      },
    },
    results: { results: { buckets: [] } },
    work_types: { work_types: { buckets: [] } },
    type_names: { type_names: { buckets: [] } },
    regions: { regions: { buckets: [] } },
    zones: { zones: { buckets: [] } },
    cities: { cities: { buckets: [] } },
    landUsePlans: { land_use_principal_type: { buckets: [] } },
  },
  total: 0,
  count: 0,
  landInfoById: {},
  faosTileDetails: [],
  faosTileDetailsLoading: false,
}

export const getFaos = createAsyncThunk(
  'faoHistory/getFaos',
  faoService.getFaos,
)

export const getSelectedFaos = createAsyncThunk(
  'faoHistory/getSelectedFaos',
  faoService.getSelectedFaos,
)

export const getFaosStats = createAsyncThunk(
  'faoHistory/getFaosStats',
  faoService.getFaosStats,
)

export const getComparedFaosStats = createAsyncThunk(
  'faoHistory/getComparedFaoStats',
  faoService.getFaosStats,
)

export const countFaos = createAsyncThunk(
  'faoHistory/countFaos',
  faoService.countFaos,
)

export const getFaosTileDetails = createAsyncThunk(
  'faoHistory/getFaosTileDetails',
  faoService.getFaosTileDetailsRedux,
)

const faoHistory = createSlice({
  name: 'faoHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFaos.pending, (state) => {
        state.loading = true
      })
      .addCase(getFaos.rejected, (state) => {
        state.loading = false
      })
      .addCase(getFaos.fulfilled, (state, action) => {
        const { data } = action.payload

        state.loading = false

        if (data) {
          state.faos = data.result || [{ ...data.data, id: data.id }]

          state.aggregations = {
            ...initialState.aggregations,
            ...data.aggregations,
          }

          state.total = data.total || 0
          state.bbox = data.bbox
        }
      })
      .addCase(getSelectedFaos.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          state.selectedFaos = data.result || [{ ...data.data, id: data.id }]
        }
      })
      .addCase(countFaos.fulfilled, (state, action) => {
        const { data = 0 } = action.payload || {}

        state.count = data
      })
      .addCase(getFaosStats.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) state.priceData = data
      })
      .addCase(getComparedFaosStats.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) state.comparePriceData = data
      })
      .addCase(getFaosTileDetails.pending, (state) => {
        state.faosTileDetailsLoading = true
      })
      .addCase(getFaosTileDetails.fulfilled, (state, action) => {
        const { data } = action.payload || []

        if (data) {
          const sortData = data?.sort((prev, next) => {
            if (
              prev?.inquiry_start_date &&
              next?.inquiry_start_date &&
              isBefore(
                new Date(prev?.inquiry_start_date),
                new Date(next?.inquiry_start_date),
              )
            ) {
              return 1
            }

            if (
              prev?.inquiry_start_date &&
              next?.inquiry_start_date &&
              isAfter(
                new Date(prev?.inquiry_start_date),
                new Date(next?.inquiry_start_date),
              )
            ) {
              return -1
            }

            return 0
          })

          state.faosTileDetails = sortData
        }
        state.faosTileDetailsLoading = false
      })
      .addCase(getFaosTileDetails.rejected, (state) => {
        state.faosTileDetailsLoading = false
      })
  },
})

export const faoReducer = {
  faoHistory: faoHistory.reducer,
}
