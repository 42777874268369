/* eslint-disable no-nested-ternary */
import { useMemo } from 'react'
import Box from '@mui/material/Box'
import { LeftMenu, useRouter, useI18n } from '@popety_io/popety-io-lib'

import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { landRegionSelector } from '../../redux'
import { userSelector } from '../../../Login'
import { regionsByNameSelector } from '../../../../components/LocationsInput'
import useAutoCloseSidebar from '../../../../hooks/useAutoCloseSidebar'
import { getLandDetailsMenuData } from './LandDetailsMenu.data'

const LandDetailsMenu = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()
  const { onCloseSidebar } = useAutoCloseSidebar()

  const user = useSelector(userSelector)
  const landRegion = useSelector(landRegionSelector)
  const regionsByName = useSelector(regionsByNameSelector)

  const firstTransactionDate = regionsByName[landRegion]?.first_transaction_date
  const firstFaoDate = regionsByName[landRegion]?.first_fao_date
  const { landId } = query
  const isHomePage = query?.tab === 'home' || query?.tab === 'grouping'
  const location = useLocation()

  const canAnalyse = useMemo(() => {
    return (
      user?.can_exceed_max_analysis ||
      Number(user?.analysis_counter) < Number(user?.analysis_nb) ||
      (user?.listsAnalysis && user?.listsAnalysis?.includes(landId))
    )
  }, [user])

  const menus = getLandDetailsMenuData(
    t,
    firstTransactionDate,
    firstFaoDate,
    user?.company_id === '1' ||
      user?.company_id === '167' /* POPETY company id */,
    canAnalyse,
    isHomePage,
  )

  const data = menus.map(({ ...menu }, i: number) => ({
    ...menu,
    active: !query.tab
      ? i === 0
      : isHomePage
        ? query.tab === menu.key && location.pathname === menu?.path
        : query.tab === menu.key,
    onClick: () => {
      updateQuery({
        tab: menu.key,
        subTab: null,
        fs: null,
        transactionCenter: null,
        title: null,
        faoCenter: null,
        transactionOpened: null,
        faoOpened: null,
        listingOpened: null,
        listingCenter: null,
        listingId: null,
        transactionId: null,
        faoId: null,
      })
      if (menu?.subMenus?.length) {
        updateQuery({
          subTab: menu.subMenus[0].key,
        })
      }
      onCloseSidebar()
    },
    subMenus: menu.subMenus?.map((subMenu: any) => ({
      ...subMenu,
      active: query?.subTab === subMenu?.key,
      onClick: () => {
        updateQuery({
          tab: menu.key,
          subTab: subMenu.key,
          fs: null,
          transactionCenter: null,
          title: null,
          faoCenter: null,
          transactionOpened: null,
          faoOpened: null,
          listingOpened: null,
          listingCenter: null,
        })
        onCloseSidebar()
      },
    })),
  }))

  return (
    <Box width={107} height="100%">
      <LeftMenu data={data as any} />
    </Box>
  )
}

export default LandDetailsMenu
