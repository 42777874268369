import { AreaChart, Area, ResponsiveContainer } from 'recharts'

const data = [
  {
    name: 'Page A',
    price: 4000,
  },
  {
    name: 'Page B',
    price: 3000,
  },
  {
    name: 'Page C',
    price: 2000,
  },
  {
    name: 'Page D',
    price: 2780,
  },
]

const EventHistoryChart = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#00ade6" stopOpacity={0.2} />
            <stop offset="95%" stopColor="#00ade6" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="price"
          stroke="#00ade6"
          fillOpacity={1}
          fill="url(#colorPrice)"
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default EventHistoryChart
