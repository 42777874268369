import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getCityById = async (cityIds: string[]) => {
  return client.get(`/cities/${cityIds}/geojson`)
}

const getNpaById = async ({ npasIds, locations }: any) => {
  const data = await client.get(`/cities/npa/${npasIds}/geojson`)

  return { data, locations }
}

const getNpaByName = async (npasName: any) =>
  client.get(`/cities/npa/geojson/${npasName}`)

const getRegionById = async ({ regionIds, locations }: any) => {
  const data = await client.get(`/region/${regionIds}/geojson`)

  return { data, locations }
}

const getDistrictById = async ({ districtIds, locations }: any) => {
  const data = await client.get(`/district/${districtIds}/geojson`)

  return { data, locations }
}

const getBuildingFiltersInfo = () => {
  return client.get(`/developerMap/building/additionnalInfo`)
}

const submitForm = ({ type = 'land', ...input }: Record<string, any>) => {
  return client.post(`/developerMap/${type}/search`, input)
}

const getSelectedLands = ({ type = 'land', ...input }: Record<string, any>) => {
  return client.post(`/developerMap/${type}/search`, input)
}

const getSelectedBuildings = ({
  type = 'building',
  ...input
}: Record<string, any>) => {
  return client.post(`/developerMap/${type}/search`, input)
}

const countDeveloperMap = ({
  type = 'land',
  ...input
}: Record<string, any>) => {
  return client.post(`/developerMap/${type}/count`, input)
}

const fetchCompaniesFilters = () => {
  return client.get(`/developerMap/filters/company`)
}

const fetchUsersFilters = async () => {
  return client.get(`/developerMap/filters`)
}

const fetchUsersBuildingFilters = async () => {
  return client.get(`/developerMap/building/filters`)
}

const getFilters = async () => {
  const [user, company, buildingUser] = await Promise.all([
    fetchUsersFilters(),
    fetchCompaniesFilters(),
    fetchUsersBuildingFilters(),
  ])

  return {
    userFilter: user?.data,
    companyFilter: company?.data,
    buildingUserFilter: buildingUser?.data,
  }
}

const saveFilter = async (values: Record<string, any>) => {
  const response = await client.post(`/developerMap/filters`, values)

  if (response.data) {
    response.data = {
      id: response.data,
      ...values,
      create_timestamp: new Date(),
    }
  }

  return response
}

const removeFilter = async (filterId: string) => {
  return client.delete(`/developerMap/filters/${filterId}`)
}

const saveBuildingFilter = async (values: Record<string, any>) => {
  const response = await client.post(`/developerMap/building/filters`, values)

  if (response.data) {
    response.data = {
      id: response.data,
      ...values,
      create_timestamp: new Date(),
    }
  }

  return response
}

const removeBuildingFilter = async (filterId: string) => {
  return client.delete(`/developerMap/building/filters/${filterId}`)
}

const saveUserLogsSearchExplore = async (values: Record<string, any>) => {
  return client.post('/search-explore/add', values)
}

const getMunicipalityById = async ({
  municipalityIds,
  locations,
}: any): Promise<any> => {
  const data = await client.get(`/municipality/${municipalityIds}/geojson`)

  return { data, locations }
}

const getGeojsonByMunicipalityName = async (
  municipalityNames: any,
): Promise<any> => client.get(`/municipality/geojson/${municipalityNames}`)

const formService = {
  getCityById,
  getRegionById,
  getDistrictById,
  getBuildingFiltersInfo,
  submitForm,
  fetchCompaniesFilters,
  fetchUsersFilters,
  saveFilter,
  removeFilter,
  getFilters,
  countDeveloperMap,
  saveBuildingFilter,
  removeBuildingFilter,
  getNpaById,
  getSelectedLands,
  getSelectedBuildings,
  saveUserLogsSearchExplore,
  getMunicipalityById,
  getGeojsonByMunicipalityName,
  getNpaByName,
}

export default formService
