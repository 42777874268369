import { useEffect } from 'react'
import Stack from '@mui/material/Stack'
import { useAsync, useI18n, format } from '@popety_io/popety-io-lib'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'

import Bullet from '../../../pages/ListingHistory/components/ListingAnalyticCard/Bullet'
import { getPriceData, getPriceInfo } from '../../../utils'
import landPreviewService from '../redux/landPreviewService'
import ListingLink from './ListingLink'

export type ListingContentProps = {
  city: string[]
  propertyCategory: string
  children?: React.ReactNode
}

const ListingContent = ({
  city,
  propertyCategory,
  children,
}: ListingContentProps) => {
  const { t } = useI18n()

  const { data, dispatch } = useAsync<any[]>()

  const [rent, buy] = data || []
  const rentAggs = rent?.data
  const buyAggs = buy?.data

  const rentData =
    rentAggs &&
    getPriceData(rentAggs.price_over_time.price_over_time.buckets, 12)

  const buyData =
    buyAggs && getPriceData(buyAggs.price_over_time.price_over_time.buckets)

  useEffect(() => {
    if (!city?.length) return

    const input = { city, propertyCategory: [propertyCategory], size: 1000000 }

    dispatch(() => landPreviewService.getListingRentAndBuyInsights(input))
  }, [city?.[0], propertyCategory])

  if (!buyData || !rentData) return null

  const buyInfo = getPriceInfo(buyData)
  const rentInfo = getPriceInfo(rentData)

  const date = buyInfo.maxDate || rentInfo.maxDate

  const priceDate = date ? `${t('common.ondate')} ${format(date, 'PPP')}` : ''

  const dateLocation = `${t('common.atLocation')} ${
    city?.join(', ') || 'Suisse'
  } ${priceDate}`

  return (
    <Box
      sx={{
        overflow: 'hidden',
      }}
    >
      <Stack
        className="content"
        direction="row"
        mb="12px"
        mt="12px"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        {buyInfo.priceM2 && (
          <div
            style={{
              width: '48%',
            }}
          >
            <div className="gray">{t('common.pricem2')}</div>
            <div className="title">{buyInfo.priceM2}</div>
            <span className="gray">{buyInfo.priceM2Range}</span>
            <Bullet priceData={buyData} dealType="purchase" />
          </div>
        )}
        {rentInfo.priceM2 && (
          <div
            style={{
              width: '48%',
            }}
          >
            <div className="gray">{t('common.rentm2')}</div>
            <div className="title">{rentInfo.priceM2}</div>
            <span className="gray">{rentInfo.priceM2Range}</span>
            <Bullet priceData={rentData} dealType="rent" />
          </div>
        )}
      </Stack>
      {children}

      <Stack
        className="ListingContentFooter"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          marginTop: '10px',
        }}
      >
        <Typography
          color="textSecondary"
          sx={{
            fontSize: '1rem',
          }}
        >
          {dateLocation}
        </Typography>
        <ListingLink city={city} propertyCategory={propertyCategory} />
      </Stack>
    </Box>
  )
}

export default ListingContent
