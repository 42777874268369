/* eslint-disable max-lines */
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { Box, Popover } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format, isFuture } from 'date-fns'
import { Modal, useI18n } from '@popety_io/popety-io-lib'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { remindersSelector } from '../../pages/Dashboard/redux/actionHistory/actionHistorySelector'
import { removeReminder } from '../../pages/Dashboard/redux/actionHistory'

type IProps = {
  setReminderSelected: React.Dispatch<React.SetStateAction<any>>
}
const ReminderList = ({ setReminderSelected }: IProps) => {
  const { t } = useI18n()
  const dispatch = useDispatch<any>()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [reminderFocus, setReminderFocus] = useState<any>(null)
  const reminders = useSelector(remindersSelector)

  // modal confirm delete reminder
  const [openConfirm, setOpenConfirm] = useState(false)

  const handleOpen = () => {
    setOpenConfirm(!openConfirm)
  }

  const handleOk = () => {
    if (reminderFocus?.id) {
      dispatch(removeReminder(reminderFocus.id))
    }
    setOpenConfirm(false)
  }

  const handleClick = (event: any, reminder: any) => {
    setAnchorEl(event.currentTarget)
    setReminderFocus(reminder)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleEditReminder = () => {
    setReminderSelected(reminderFocus)
  }

  const handleRemoveReminder = () => {
    setOpenConfirm(true)
    setAnchorEl(null)
  }

  useEffect(() => {
    if (!open && !openConfirm) {
      setReminderFocus(null)
    }
  }, [open, openConfirm])

  return (
    <>
      {reminders?.length ? (
        <List
          sx={{
            // width: '460px',
            maxWidth: 460,
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '12px 7px',
            maxHeight: '60vh',
            msOverflowY: 'scroll',
          }}
        >
          {reminders?.map((reminder, index) => {
            return (
              <ListItem
                key={index}
                alignItems="flex-start"
                sx={{
                  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 4px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: '12px',
                    width: '100%',
                    color:
                      String(reminderFocus?.id) === String(reminder?.id)
                        ? '#00ADE6'
                        : '#969696',
                    ':hover': {
                      color: '#00ADE6',
                      '.reminder-item-subject': {
                        color: '#041725 !important',
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '6px',
                      color: isFuture(new Date(reminder?.alarm_date))
                        ? '#34ADE6'
                        : '#969696',
                    }}
                  >
                    <DateRangeIcon
                      sx={{
                        width: '16px',
                        height: '16px',
                      }}
                    />
                    <Typography>
                      {reminder?.alarm_date
                        ? format(new Date(reminder?.alarm_date), 'dd/MM/yyyy')
                        : ''}
                    </Typography>
                  </Box>

                  <MoreVertIcon
                    sx={{
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer',
                    }}
                    aria-describedby={id}
                    onClick={(e) => {
                      handleClick(e, reminder)
                    }}
                  />

                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem
                        sx={{ fontSize: '1.1rem' }}
                        onClick={handleEditReminder}
                      >
                        {t('common.edit')}
                      </MenuItem>
                      <MenuItem
                        sx={{ fontSize: '1.1rem', color: 'red' }}
                        onClick={handleRemoveReminder}
                      >
                        {t('common.delete')}
                      </MenuItem>
                    </Menu>
                  </Popover>
                </Box>
                <Typography
                  sx={{
                    display: 'inline',
                    color: isFuture(new Date(reminder?.alarm_date))
                      ? '#041725'
                      : '#969696',
                  }}
                  component="span"
                  variant="body2"
                  fontSize="12px"
                  className="reminder-item-subject"
                >
                  {reminder?.subject || ''}
                </Typography>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="#969696"
                  fontSize="10px"
                >
                  {t('common.createdOn')}{' '}
                  {reminder?.create_timestamp
                    ? format(new Date(reminder?.create_timestamp), 'dd/MM/yyyy')
                    : ''}{' '}
                  {t('common.by')} {reminder?.first_name || ''}{' '}
                  {reminder?.last_name || ''}.
                  {reminder?.update_timestamp
                    ? `${' '} ${t('common.lastModifiedOn')} ${format(new Date(reminder?.update_timestamp), 'dd/MM/yyyy')}${'.'}`
                    : ''}
                </Typography>
              </ListItem>
            )
          })}
          <Modal
            title={t('common.Delete reminder')}
            open={openConfirm}
            okText={t('common.ok')}
            onOk={handleOk}
            onClose={handleOpen}
            onCancel={handleOpen}
            cancelText={t('common.cancel')}
          >
            {t('common.Are you sure you want to delete this reminder ?')}
          </Modal>
        </List>
      ) : (
        <Typography px={3} py={2} width={360}>
          {t('common.noReminder')}
        </Typography>
      )}
    </>
  )
}

export default ReminderList
