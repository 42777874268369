import { useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import CheckIcon from '@mui/icons-material/Check'

const EventHistoryFeatures = ({ event }: { event: any }) => {
  const { t } = useI18n()

  return (
    <>
      {!!event?.features?.length && (
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: '1.1rem',
              fontWeight: 500,
              textTransform: 'uppercase',
              mb: 1,
            }}
          >
            {t('common.CARACTÉRISTIQUES')}
          </Typography>
          {!!event?.features?.length &&
            event?.features.map((feature: any, i: number) => (
              <Chip
                key={i}
                label={t(`listing.features.${feature}`)}
                title={t(`listing.features.${feature}`)}
                icon={
                  <CheckIcon
                    sx={{
                      color: (theme) =>
                        `${theme.palette.primary.main}!important`,
                    }}
                  />
                }
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  marginRight: 1,
                  marginBottom: 1,
                  borderRadius: '6px',
                  fontSize: '0.9rem',
                  fontWeight: 500,
                  backgroundColor: '#F7F7F7',
                }}
              />
            ))}
        </Box>
      )}
    </>
  )
}

export default EventHistoryFeatures
