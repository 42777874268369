/* eslint-disable max-lines */
import { forwardRef } from 'react'
import { useI18n, format, useRouter, Tag } from '@popety_io/popety-io-lib'
import { Box, Grid, Typography } from '@mui/material'

type FaoMapTilePopupProps = {
  feature?: any
  handleClose: () => void
  data: any[]
  loading?: boolean
  onClick?: () => void
}

const FaoMapTilePopup = ({ data, onClick }: FaoMapTilePopupProps, ref: any) => {
  const { query, updateQuery } = useRouter()
  const { t } = useI18n()

  const handleClick = (fao: any) => {
    updateQuery({
      faoId: fao?._id || fao?.id || null,
    })
    if (onClick) {
      onClick()
    }
  }

  return (
    <div
      ref={ref}
      style={{
        background: '#ffffff',
        backgroundColor: '#ffffff',
        padding: '9px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      {data?.map((fao: any, index: number) => {
        return (
          <Grid
            key={index}
            sx={{
              cursor: 'pointer',
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 4px',
              padding: '5px 10px',
              gap: '4px',
              backgroundColor: `${String(query?.faoId) === String(fao?._id || fao?.id) ? '#00ade6' : ''}`,
            }}
            onClick={() => {
              handleClick(fao)
            }}
            justifyContent="space-between"
            container
          >
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
              }}
              item
              xs={7}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                <Typography
                  color={`${String(query?.faoId) === String(fao?._id) ? '#000000' : 'rgb(141, 155, 163)'}`}
                  sx={{
                    // display: 'flex',
                    // whiteSpace: 'nowrap',
                    fontSize: '10px',
                    // height: '100%',
                  }}
                >
                  {t('common.publishedOn')}
                  {' : '}
                </Typography>
                <span
                  style={{
                    color: '#000000',
                    marginLeft: 2,
                    fontSize: '10px',
                  }}
                >
                  {format(fao?.inquiry_start_date, 'P')}
                </span>
              </Box>
              {fao.classification === 'development' && (
                <Tag
                  color="default"
                  style={{
                    backgroundColor: '#daf0f7',
                    fontWeight: 500,
                    fontSize: '8px',
                    height: '18px',
                  }}
                >
                  {t('land.fao.developmentProject')}
                </Tag>
              )}
            </Grid>
            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="left"
              item
              xs={4}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'space-around',
                }}
              >
                <Typography
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontWeight="500"
                  fontSize="10px"
                  color="#000000"
                >
                  {fao?.work_type || ''}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )
      })}
    </div>
  )
}

export default forwardRef(FaoMapTilePopup)
