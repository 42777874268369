import { ApiClient } from '../../../../services'

const client = new ApiClient()

export type GetBuildingOpts = {
  buildingId?: string | number | string[] | number[]
  addressId?: string | number | string[] | number[]
}

const getBuildingSummary = ({ buildingId, addressId }: GetBuildingOpts) => {
  if (addressId) {
    const query = `addressId:${addressId}`

    return client.get(`/building/${query}`)
  }

  return client.get(`/building/${buildingId}`)
}

const getBuildingAnalysisHistory = () => {
  return client.get(`/building/analysis/history`)
}

const summaryService = {
  getBuildingSummary,
  getBuildingAnalysisHistory,
}

export default summaryService
